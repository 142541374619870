@media only screen and (max-width: 767px) and (min-width: 320px)  {
  .as-hero-wrapper .col-md-10_5 {
      width: 100% !important;
  }
  .as-hero-wrapper .hero-slider-2 .hero-title {
      font-size: 22px !important;
  }
  .hero-style1 {
    max-width: 100%;
  }
  .heroslider-style {
    height: 400px;
}
.heroslider-style img {
  object-fit: cover;
  height: 100%;
}
.hero-slider-2 .hero-text {
  line-height: 26px;
  font-size: 14px;
}
.counter-card {
  padding: 20px 0 0px !important;
}
.title-area .sec-title {
  font-size: 38px;
}
.counter-card img {
  box-shadow: 0px 0px 4px 0px rgb(16 34 57);
}
.Affilitions_home_page_style {
  padding-bottom: 0;
  background: linear-gradient(180deg, rgba(15,34,57,1) 70%, rgba(255,255,255,1) 30%);
  overflow: hidden;
}
.Testimonial_center_left {
  align-items: center;
}
.Testimonial_bg_img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0;
  overflow: hidden;
}
.form_wrapper {
  max-width: 350px;
}
.Corporate-homepage {
overflow: hidden;
}
.carousel-caption {
  right: 0% !important;
  left: 0 !important;
}
.as-mobile-menu .sub-menu li {
  padding-left: 0;
}
.as-mobile-menu .sub-menu li a {
  font-size: 14px;
  justify-content: flex-start;
}
.menu-item-has-children a svg {
  margin-right: 15px;
  font-size: 22px;
}
.header-links {
  text-align: center;
}
.header-links li:not(:last-child) {
  padding: 0;
  margin:10px;
  margin-bottom: 10px;
}
.header-links li:not(:last-child):before {
  display: none;
}
.breadcumb-wrapper {
  height: 100%;
}
.form_wrapper .col_half {
  width: 100%;
  float: left;
}
.online-form-bottom {
  margin-top: 20px;
}
.about-us-page-bottom .contact-info{
  flex-direction: unset;
  margin: 25px;
}
.main-content-abot-page {
  padding: 0;
}
.about-us-page-top {
  margin-top: 40px !important;
}
.about_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap,
.Recruiters_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap,
.course_page_main_top_hight,
.Faculty_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap,
.breadcumb-wrapper.Event_page_bg,
.blog_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap,
.gallery_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap,
.Testimonialspage_bg.breadcumb-wrapper.background-image.shape-mockup-wrap {
  height: 200px !important;
  padding: 0;
}
.course-img img {
  width: 97%;
}
.blog_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap .breadcumb-title {
  font-size: 22px;
}
.social_button_share button svg {
  width: 40px;
  height: 40px;
}
.social_button_share {
  bottom: 10px;
  right: auto;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: center;
}
/* .course-single-top {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
} */
.course-description  table {
  width: unset;
}
.event-card {
  justify-content: center;
}
.event-card:before {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.event-card_img {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
}
.slick-arrow {
  display: none !important;
}
.courses-singal-page-bg {
  background-image: url(/static/media/course_bg_2.67bb4444.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.courses-singal-page-bg .course-single-bottom ul li a {
  background-color: #013767 !important;
  color: #fff !important;
}
.courses-singal-page-bg .course-description {
  box-shadow: rgb(1 55 103) 0px 30px 60px -12px inset, rgb(1 55 103) 0px 18px 36px -18px inset;
}
tbody, td, tfoot, th, thead, tr {
  border-width: 1px !important;
}
.courses-singal-page-bg .course-description p {
  color: #000;
}
.courses-singal-page-bg .widget {
  box-shadow: rgb(1 55 103) 0px 30px 60px -12px inset, rgb(1 55 103) 0px 18px 36px -18px inset;
}
.courses-singal-page-bg .course-single-top {
  box-shadow: rgb(1 55 103) 0px 30px 60px -12px inset, rgb(1 55 103) 0px 18px 36px -18px inset;
}
