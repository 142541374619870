@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --theme-color: #003768;
  --theme-color-2: #0364a9;
  --theme-color2: #f20f10;
  --title-color: #0f2239;
  --body-color: #4d5765;
  --smoke-color: #f3f7fb;
  --black-color: #000000;
  --white-color: #ffffff;
  --light-color: #72849b;
  --yellow-color: #ffb539;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #ecf1f9;
  --title-font: "Jost", sans-serif;
  --body-font: "Jost", sans-serif;
  --icon-font: "Font Awesome 6 Pro";
  /*--main-container: 1380px;*/
  --main-container: 1920px;
  --container-gutters: 24px;
  --section-space: 60px;
  --section-space-mobile: 80px;
  --section-title-space: 70px;
  --ripple-ani-duration: 5s;
}
.marquee-main {
  --space: 1rem;

  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--space);
  width: 100%;
  font-family: "Corben", system-ui, sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
}

.marquee {
  --duration: 60s;
  --gap: var(--space);

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  transform: skewX(-3deg);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group img {
  max-width: clamp(10rem, 1rem + 28vmin, 20rem);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
}

.marquee__group p {
  background-image: linear-gradient(
    75deg,
    hsl(240deg 70% 49%) 0%,
    hsl(253deg 70% 49%) 11%,
    hsl(267deg 70% 49%) 22%,
    hsl(280deg 71% 48%) 33%,
    hsl(293deg 71% 48%) 44%,
    hsl(307deg 71% 48%) 56%,
    hsl(320deg 71% 48%) 67%,
    hsl(333deg 72% 48%) 78%,
    hsl(347deg 72% 48%) 89%,
    hsl(0deg 73% 47%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.marquee--borders {
  border-block: 3px solid dodgerblue;
  padding-block: 0.75rem;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: calc(var(--duration) / -2);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  z-index: 100;
  display: none;
}

.cnt223 a {
  text-decoration: none;
}

.popup-onload {
  width: 100%;
  margin: 0 auto;
  display: none;
  position: fixed;
  z-index: 101;
  top: 0;
  height: 100%;
  background: #000000ad;
}

.popup-onload-center {
  height: 100%;
  display: flex !important;
  align-items: center;
  align-content: center;
}

.cnt223 {
  min-width: 00px;
  width: 100%;
  min-height: 150px;
  margin: auto;
  position: relative;
  z-index: 103;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px #000;
  max-width: 420px;
}

.landing-page-top-hero-section {
  align-items: center;
}

.landing-page-top-hero-section #model_close_button {
  display: none;
}

.cfp_landing_page_planner {
  margin-top: 60px !important;
  max-width: 900px;
  margin: auto;
  width: 100%;
}

.mt-60 {
  margin-top: 60px;
}

.moto-box-img-landing {
  padding: 20px;
  height: 100%;
  min-height: 462px;
  box-shadow: 1px 0px 6px rgb(131 139 154 / 100%);
  margin-bottom: 20px;
  background: #0e253e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.moto-box-img-landing p,
.moto-box-img-landing h4,
.moto-box-img-landing ul li,
.moto-box-img-landing ul li:before {
  color: #fff;
}

.moto-box_img h4 {
  font-size: 22px;
}

.CERTIFICATION_img img {
  box-shadow: 1px 0px 28px rgb(131 139 154 / 100%);
  border-radius: 10px;
}

.CERTIFICATION {
  align-items: center;
  justify-content: center;
}

.content_CERTIFICATION p {
  color: #0e2239;
  font-size: 20px;
}

.CERTIFICATION .moto-box.moto-box-img-landing {
  min-height: 100%;
}

.img-box-bg {
  background: #ededed;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.header-bold {
  text-align: center;
  margin: auto;
  margin-bottom: 3rem;
}

.pt-60 {
  padding-top: 60px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-60 {
  padding-bottom: 60px;
}

.color_black {
  color: #000 !important;
}

.box-ic.mmargin,
.box-ic {
  display: flex;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 8px 8px rgb(131 139 154 / 100%);
  border-radius: 10px;
  background-color: var(--white-color);
}

.why_study img {
  height: 217px;
  width: 100%;
  object-fit: cover;
}

.bg-blue p {
  color: #b5b3b3;
}

.blue1 p,
.blue1 h5,
.blue2 p,
.blue2 h5 {
  color: #fff;
}

.mb-60 {
  margin-bottom: 60px;
}

p {
  line-break: auto !important;
}

.width_from {
  max-width: 620px;
}

.width_Courses {
  max-width: 350px;
  width: 100%;
}

.width_Courses p {
  color: #fff;
}

.event-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}

.event-grid:nth-of-type(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.hero-slider-top .hero-style1 {
  margin-right: 0;
  max-width: 767px !important;
}
.hero-slider-2 .hero-title {
  font-size: 38px !important;
}
#from_top_move .title_container {
  padding-bottom: 0;
}

.event-grid .event-img {
  width: 41.6666666667%;
  position: relative;
  height: 240px;
  border-radius: 10px;
}

.event-grid .event-img img {
  width: -webkit-fill-available;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 1299px) {
  .event-grid:nth-of-type(odd) .event-content {
    padding: 50px 30px 50px 50px;
  }

  .event-grid .event-content {
    width: 66%;
    padding: 50px 50px 50px 30px;
  }
}

.event-grid:nth-of-type(odd) .event-content .event-bg-shape {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  left: auto;
  right: -40px;
}

.event-grid .event-content .event-bg-shape {
  position: absolute;
  left: -40px;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--white-color);
  z-index: -1;
  -webkit-mask-size: cover;
  -webkit-mask-position: left;
}

.event-grid:nth-of-type(odd) .event-content {
  right: unset;
  left: 0;
  margin-left: 0;
  margin-right: 40px;
  padding: 60px 40px 60px 60px;
}

.event-grid .event-content {
  background-color: #fff;
  width: 63%;
  box-shadow: 0px 4px 29px rgba(152, 170, 204, 0.15);
  border-radius: 10px;
  padding: 60px 60px 60px 40px;
  position: absolute;
  bottom: 50%;
  right: 0;
  z-index: 3;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.become-instructor-wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.07);
  padding: 30px;
}

.become-instructor-wrap h4 {
  font-size: 18px;
}

.become-instructor-wrap p {
  font-size: 14px;
  font-weight: 500;
}

.justify-content {
  justify-content: center;
}

.cta-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.cta-bg-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: #00112b;
  opacity: 0.87;
}

.sub-title {
  font-size: 16px;
  font-weight: 500;
  /*color: #fff;*/
  font-family: var(--title-font);
  text-transform: uppercase;
  margin-top: -0.41em;
  margin-bottom: 22px;
  display: block;
}

.white {
  color: #fff;
}

.hero-style1 {
  padding: 80px 0 0px 0 !important;
}

.cfp_logo_top {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 40px;
}

.box-center {
  align-items: center;
  justify-content: center;
}
.button_width_auto {
  width: auto !important;
  background: #fff;
  color: #013767;
}
.button_width_auto:before {
  background-color: var(--title-color);
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}
.as-btn {
  padding: 14.5px 29px !important;
}
.counter-area-1 {
  border-radius: 20px;
  -webkit-transform: translate(0px, 111px);
  -ms-transform: translate(0px, 111px);
  transform: translate(0px, 111px);
  z-index: 3;
  position: relative;
  margin-top: -111px;
}
.counter-card {
  text-align: center;
  padding: 75px 0 68px;
}
.max-width_1366 {
  max-width: 1266px;
  width: 100%;
  margin: auto;
}
.counter-card-wrap.counter_box_color {
  background: #fff;
  border-color: #fff !important;
}
.counter-card-wrap.counter_box_color .counter-card {
  text-align: center;
  padding: 35px 0 35px;
}
.counter {
  padding: 31px;
  background: rgb(10, 60, 150);
  background: linear-gradient(180deg, #013767 65%, rgba(255, 255, 255, 0) 50%);
}
.counter-card-wrap.counter_box_color .counter-card .counter-card_number {
  color: #000;
  font-size: 34px;
}
.counter-card-wrap.counter_box_color .counter-card .counter-card_text {
  color: #000;
  font-size: 18px;
}
.counter_top_content_h2 p {
  color: #fff;
  font-size: 22px;
  text-align: center;
}
.counter_top_content_h2 p strong {
  font-size: 32px;
  margin-right: 5px;
}
.counter_top_content {
  margin-bottom: 10px;
}
/*.moto-box.moto-box-img-landing .as-btn.Submit-button.button_width_auto {*/
/*    display: block;*/
/*    margin-top: 40px;*/
/*}*/
.img-box1 {
  padding-bottom: 87px;
}
.about-grid {
  position: absolute;
  top: 0px;
  right: 0;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 30px 0px rgba(19, 61, 117, 0.12);
  z-index: 2;
  overflow: hidden;
  padding: 40px 0px 42px;
}
.about-grid_year {
  font-size: 44px;
  font-weight: 700;
  color: var(--white-color);
  margin-bottom: 6px;
}
.about-grid_text {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  max-width: 121px;
  margin: 0 17px;
}
.img-box1 .img1 img {
  border-radius: 10px;
}
.img-box1 .img2 {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: 1;
}
.img-box1 .img2 img {
  border-radius: 10px;
}
.img-box1 .about-shape1 {
  z-index: 1;
}
.jump {
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}

.ripple-animation,
.play-btn:after,
.play-btn:before {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
  animation-name: ripple;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.movingX {
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
}

@-webkit-keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.jump {
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}

@-webkit-keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.jump-reverse {
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}

@-webkit-keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.spin {
  -webkit-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.img-box1 .about-shape1:after,
.img-box1 .about-shape1:before {
  content: "";
  position: absolute;
  left: 98px;
  bottom: 57px;
  border: 10px solid var(--theme-color2);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.img-box1 .about-shape1:after {
  width: 122px;
  height: 122px;
  border: 5px solid var(--theme-color);
  left: 6px;
  bottom: 26px;
  z-index: -1;
}
@media (max-width: 1024px) {
  .about-shape1 {
    left: 0px !important;
  }
}
.img-box1 .img2:after {
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  background: var(--white-color);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: -1;
}
.about-grid:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(
    top,
    rgba(13, 94, 244, 0) 0%,
    rgba(13, 94, 244, 0.6) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(13, 94, 244, 0) 0%,
    rgba(13, 94, 244, 0.6) 100%
  );
  z-index: -1;
  background: linear-gradient(
      180deg,
      rgba(13, 94, 244, 0) 0%,
      rgba(13, 94, 244, 0.6) 100%
    ),
    url(../assets/img/landing/about_1_3.png);
}
.width_45_box {
  width: 45%;
}
/*.cta-wrap2 .checklist {*/
/*    max-width: 767px;*/
/*    width: 100%;*/
/*    margin: auto;*/
/*}*/
.cta-wrap2 .checklist li {
  color: #fff;
  margin-bottom: 0;
  margin: 0 10px;
}
.checklist li:before {
  color: #fff;
  background-image: url(../assets/img/landing/checked.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 4px;
}
.cta-wrap2 .checklist ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
#team-sec .openPopup {
  width: auto;
  text-align: center;
}
.category-card {
  text-align: center;
  border-radius: 10px;
  border: 1px solid var(--f-2-f-2-f-2, #f2f2f2);
  background: var(--white-color, #fff);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
}
.category-card_icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  min-width: 60px;
  background-color: var(--smoke-color);
  border-radius: 999px;
  text-align: center;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  margin: auto;
}
.category-card_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.category_box_6 {
  gap: 15px;
  padding: 30px;
  flex-direction: column;
  margin: 12px 0;
  min-height: 266px;
}
.category_box_6 .category-card_title {
  font-size: 22px;
}
/*  */

#about {
  margin-top: 5rem;
}
.ingrp input {
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding-left: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 10px;
  margin-bottom: 15px;
}
.ingrp input:focus {
  border-color: #285ebf !important;
  outline: none;
  box-shadow: none;
}
.ingrp input:focus ~ span,
.ingrp input:valid ~ span {
  top: -6px !important;
  background: #fff;
  font-size: 11px;
  color: #285ebf;
}
.ingrp input:active ~ span {
  top: -6px !important;
  background: #fff;
  font-size: 11px;
  color: #285ebf;
}
.ingrp {
  position: relative;
}
.ingrp span {
  position: absolute;
  top: 12px;
  font-size: 14px;
  left: 10px;
  color: #999;
  pointer-events: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
input:-internal-autofill-selected {
  background-color: #fff !important;
}
.ingrp input:valid {
  background: #fff !important;
}
.cfp-enq-form button {
  display: block;
  width: 100%;
  height: 50px;
  /* background: linear-gradient(251deg, #e43973 2%, #ff5722 98%); */
  background: #fc3;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  color: #000;
}
.cfp-enq-form h5 {
  padding: 8px 12px 8px 12px;
  text-align: center;
  background: linear-gradient(251deg, #2d74f7 2%, #1f407d 98%);
  border-radius: 100px;
  position: absolute;
  left: 50px;
  right: 50px;
  top: -20px;
  color: #fff;
  font-size: 14px;
}
.cfp-enq-form {
  border: 2px solid #1f407d;
  background: #fff;
  position: relative;
}
section h2 {
  font-weight: 600;
}
.cfp-enq-form form {
  padding: 25px 15px 15px 15px;
}
.cfp-enq-form h4 {
  font-weight: 700;
  color: #031f3f;
}
main {
  /* background-image: url("../assets/img/log/bg.jpeg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100% !important;
}
.main_con h1 {
  color: #031f3f;
  font-size: 3.125rem;
  font-weight: 900;
}
.main_con h1 span {
  color: #fc3;
}
.main_con .highlights p {
  font-size: 30px;
  font-weight: 600;
}

.main_con .highlights p.lead2 {
  font-size: 17px;
  margin-top: 20px;
  font-weight: 600;
}

.main_con .highlights hr {
  width: 40px;
  height: 4px;
}
.main_con .highlights span {
  font-size: 20px;
}
.cfpcta {
  /* background: url("../cfp-course/cfp-girl-bg.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.gred {
  font-size: 14px;
}
p {
  line-height: normal;
  font-size: 15px;
}
.cfpcta {
  color: #fff;
}
.cfptxt {
  color: #000;
  font-size: 20px;
  background: #fff;
  width: fit-content;
  padding: 15px;
  border-radius: 11px;
  margin: auto;
  border: 2px solid #2d73f3;
  font-weight: 600;
}
.cfpcta button {
  width: fit-content;
  height: 50px;
  width: 320px !important;
  background: linear-gradient(251deg, #e43973 2%, #ff5722 98%);
  outline: none;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
}
.cfpcta p {
  font-size: 20px;
}
.fpsb h5 {
  font-size: 32px;
  font-weight: 800;
}
.fpsb hr {
  width: 30px;
  margin: auto;
  height: 3px;
}
.callnowbtn {
  color: #000 !important;
  font-size: 22px;
  font-weight: 600;
}
.callnowbtn a {
  color: #000 !important;
}
.cfplevel {
  padding: 25px;
  background: #fff;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%);
  min-height: 390px;
}
.min-h-d {
  min-height: 280px;
}
.grid-img {
  color: #333;
}
.bg-blue {
  background: #031f3f;
  color: #fff !important;
  height: 217px;
  padding: 15px;
}
.bg-blue h5 {
  color: #fff !important;
}
.bg-yel {
  background: #fc3;
  color: #031f3f !important;
  height: 217px;
  padding: 15px;
}
.bg-yel h5 {
  color: #031f3f !important;
}
.bg-grey {
  background: #ededed;
  color: #031f3f !important;
  height: 217px;
  padding: 15px;
}
.bg-grey h5 {
  color: #031f3f !important;
}
.bg-img {
  height: 217px;
}
.bg-img img {
  height: 100%;
  width: 100%;
}

.grid-img h5 {
  font-size: 43px;
  color: #031f3f;
  text-transform: uppercase;
  font-weight: 900;
}
.grid-l {
  padding-right: 20px;
}
.cfplevel:hover {
  transform: scale(1.05);
  z-index: 2;
  transition: 0.25s ease-in-out;
}
.arrowstyl ul {
  margin: 0 !important;
  padding: 0px 0px 0px 20px;
}
.arrowstyl ul li {
  list-style-type: none;
  position: relative;
}
.arrowstyl-s ul {
  margin: 0 !important;
  padding: 0px 0px 0px 20px;
}
.arrowstyl-s ul li {
  list-style-type: none;
  position: relative;
}
.arrowstyl-s ul li::before {
  content: "\25BA \0020";
  font-size: 12px;
  position: absolute;
  top: 0;
  left: -20px;
}
.yellow {
  background: #fc3;
  color: #020f1b;
}
.right-sd {
  color: #333;
  position: relative;
  z-index: 2;
  padding: 35px 35px;
  background-color: #fff;
}
.right-sd h2 {
  color: #031f3f;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
}
.right-sd h2 span {
  color: #fc3;
}
.right-sd::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 40%;
  max-width: 310px;
  height: 155px;
  border-top: 12px solid #fc3;
  border-left: 12px solid #fc3;
}
.grey-text {
  color: #333;
}
.right-sd::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 40%;
  max-width: 310px;
  height: 155px;
  border-bottom: 12px solid #fc3;
  border-right: 12px solid #fc3;
}
.box-ic {
  display: flex;
  /*height: 260px;*/
}
.img-1 {
  /* background-image: url("../cfp-course/study.jpg"); */
  width: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: all 0.5s;
  order: 2;
}
.img-2 {
  /* background-image: url("../cfp-course/exam.jpg"); */
  width: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: 0.6s ease-in-out;
  order: 2;
}
.img-3 {
  /* background-image: url("../cfp-course/weekdays.jpg"); */
  width: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: 0.6s ease-in-out;
  order: 2;
}
.img-4 {
  /* background-image: url("../cfp-course/weekend.jpg"); */
  width: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: 0.6s ease-in-out;
  order: 2;
}
.cta-cfp {
  background: #fc3;
  color: #333;
  padding: 50px;
}
.cta-cfp h5 {
  text-transform: uppercase;
  color: #031f3f;
  font-size: 3.125rem;
  font-weight: 900;
}
.cta-cfp p {
  max-width: 700px;
  margin: auto;
}
.cta-cfp button {
  display: block;
  height: 50px;
  background: #333;
  outline: none;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0.25rem;
  color: #fff;
}
.text-1 {
  order: 1;
  background: #fff;
  padding: 30px;
}
.text-1 h5::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  display: inline-block;
  width: 9px;
  background: #003767;
  transition: -7.65s ease-in-out;
}
.text-1 h5 {
  font-size: 30px;
  color: #031f3f !important;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
}
.img-box-bg {
  background: #ededed;
}
.img-box-bg a {
  text-decoration: none !important;
  color: #333;
}
.header-bold {
  text-align: center;
  margin: auto;
  margin-bottom: 3rem;
}
.header-bold h2 {
  text-transform: uppercase;
  color: #031f3f;
  font-size: 3.125rem;
  font-weight: 900;
}
.header-bold p {
  color: #333;
}
.blue1 {
  background: #03172d;
  color: #fff;
}
.blue3 {
  background: #04182d;
  color: #fff;
}
.blue2 {
  background: #05274a;
  color: #fff;
}
.learn-medium {
  display: flex;
  align-items: center;
}
.learn-medium p {
  margin-bottom: 0px !important;
  color: #7d7d7d;
  font-size: 15px;
}
.learn-medium img {
  height: 120px;
}
.learn-why {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  background: #fff;
  color: #2965d5 !important;
  padding: 12px;
  border-radius: 10px;
  height: 120px;
  width: 100%;
}
.whyicofp {
  /* background: url("../cfp-course/cfp-girl-bg.jpg"); */
  color: #fff;
}
.whytext p {
  color: #000 !important;
}
.whytext {
  margin-left: 15px;
}
.learn-why p {
  margin-bottom: 0px !important;
  color: #7d7d7d;
  font-size: 15px;
}
.learn-why img {
  height: 57px;
}
.lmtext {
  border-left: 2px solid #2965d5;
  margin-left: 10px;
  padding-left: 15px;
}
.lmtext h5 {
  color: #2965d5;
}
.stdyftrs label {
  font-size: 14px;
  background: #2965d5;
  width: 100%;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.stdyftrs p {
  margin-bottom: 5px;
}
.boxc {
  border: 2px solid #2965d5;
  border-radius: 8px;
  padding: 15px 15px 21px 15px;
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  height: 120px;
}
footer h5 {
  color: #fff;
}
footer h5 span {
  font-size: 16px;
  font-weight: 200;
}
footer hr {
  width: 50%;
  margin: 0 auto;
  color: #fff;
}
.tbl1 {
  background: #fff5e8;
  border-left: 3px solid #de9533;
}
.tbl2 {
  background: #e6ffdf;
  border-left: 3px solid #3e7c2d;
}
.tbl3 {
  background: #ffd6d9;
  border-left: 3px solid #9b282f;
}
.tbl4 {
  background: #e4f1ff;
  border-left: 3px solid #0b4a8e;
}
.tblhd {
  vertical-align: middle;
}
.lvltble {
  font-size: 14px;
}
.tbl1 span {
  font-size: 30px;
  color: #de9533;
  font-weight: 800;
}
.tbl2 span {
  font-size: 30px;
  font-weight: 800;
  color: #3e7c2d;
}
.tbl3 span {
  font-weight: 800;
  font-size: 30px;
  color: #9b282f;
}
.tbl4 span {
  font-weight: 800;
  font-size: 30px;
  color: #0b4a8e;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (max-width: 768px) {
  main {
    height: fit-content !important;
  }
  .cfplevel {
    min-height: fit-content !important;
  }
  .xxxdd {
    padding-top: 50px;
  }
  .header-bold h2 {
    font-size: 35px;
  }
  .grid-img h5 {
    font-size: 35px;
  }
  .cta-cfp h5 {
    font-size: 35px;
  }
  /*.nav-item span {*/
  /*  display: none;*/
  /*}*/
  .cta-card.background-image {
    position: relative;
  }
  .cta-card.background-image:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0e1d34cc;
    top: 0;
    left: 0;
  }
  .cta-card.background-image .sub-title {
    color: #fff;
  }
  .cta-card.background-image h4 {
    color: #fff;
  }
  .cta-card.background-image p {
    color: #fff !important;
  }
  .event-grid .event-content {
    padding: 20px 20px 20px 20px !important;
  }
  .cfpheadt h1 {
    font-size: 35px;
  }
  .cfpheadt p {
    font-size: 20px;
  }
  .cfpheadt {
    text-align: center;
  }
  .cfpheadt hr {
    display: none;
  }
  .cfpheadt .cfpimg {
    margin-bottom: 20px !important;
  }
  .mnone {
    display: none;
  }
  .lead {
    font-size: 16px;
  }
  .cfpcta p {
    font-size: 16px;
  }
  footer span {
    font-size: 12px !important;
  }
  #m-btn {
    display: block !important;
  }
}
@media only screen and (max-width: 991px) {
  .mmargin {
    margin-bottom: 25px;
  }
}
#m-btn {
  position: fixed;
  display: none;
  background: #fff;
  bottom: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.1);
}
#m-btn.hide {
  bottom: -50px;
}
#m-btn button {
  width: 100%;
  height: 50px;
  /* background: linear-gradient(
  251deg
  , #e43973 2%, #ff5722 98%);
      outline: none;
      border: none;
      */
  color: #fff;
  background: #59b04d;
  outline: none;
  border: none;
}
.btn-common {
  display: block;
  width: 100%;
  height: 50px;
  /* background: linear-gradient(251deg, #e43973 2%, #ff5722 98%); */
  background: #fc3;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  color: #000;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  height: 80px;
}
.logoicfp {
  height: 75px !important;
}
.nav-link img {
  height: 60px;
}

/*  */
/*  */
/*  */
a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.marquee-main {
  --space: 1rem;

  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--space);
  width: 100%;
  font-family: "Corben", system-ui, sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
}

.marquee {
  --duration: 60s;
  --gap: var(--space);

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  transform: skewX(-3deg);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group img {
  max-width: clamp(10rem, 1rem + 28vmin, 20rem);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
}

.marquee__group p {
  background-image: linear-gradient(
    75deg,
    hsl(240deg 70% 49%) 0%,
    hsl(253deg 70% 49%) 11%,
    hsl(267deg 70% 49%) 22%,
    hsl(280deg 71% 48%) 33%,
    hsl(293deg 71% 48%) 44%,
    hsl(307deg 71% 48%) 56%,
    hsl(320deg 71% 48%) 67%,
    hsl(333deg 72% 48%) 78%,
    hsl(347deg 72% 48%) 89%,
    hsl(0deg 73% 47%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.marquee--borders {
  border-block: 3px solid dodgerblue;
  padding-block: 0.75rem;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: calc(var(--duration) / -2);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  z-index: 100;
  display: none;
}
.cnt223 a {
  text-decoration: none;
}
.popup-onload {
  width: 100%;
  margin: 0 auto;
  display: none;
  position: fixed;
  z-index: 101;
  top: 0;
  height: 100%;
  background: #000000ad;
}
.popup-onload-center {
  height: 100%;
  display: flex !important;
  align-items: center;
  align-content: center;
}
.cnt223 {
  min-width: 00px;
  width: 100%;
  min-height: 150px;
  margin: auto;
  position: relative;
  z-index: 103;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px #000;
  max-width: 420px;
}
.landing-page-top-hero-section {
  align-items: center;
}
.landing-page-top-hero-section #model_close_button {
  display: none;
}

.cfp_landing_page_planner {
  margin-top: 60px !important;
  max-width: 900px;
  margin: auto;
  width: 100%;
}
.mt-60 {
  margin-top: 60px;
}
.moto-box-img-landing-1 {
  padding: 20px;
  height: 100%;
  min-height: 400px;
  box-shadow: 1px 0px 6px rgb(131 139 154 / 100%);
  margin-bottom: 20px;
  background: #0e253e;
}
.moto-box-img-landing-1 p,
.moto-box-img-landing-1 h4,
.moto-box-img-landing-1 ul li,
.moto-box-img-landing-1 ul li:before {
  color: #fff;
}
.moto-box_img h4 {
  font-size: 22px;
}
.CERTIFICATION_img img {
  box-shadow: 1px 0px 28px rgb(131 139 154 / 100%);
  border-radius: 10px;
}
.CERTIFICATION {
  align-items: center;
  justify-content: center;
}
.content_CERTIFICATION p {
  color: #0e2239;
  font-size: 20px;
}
.CERTIFICATION .moto-box.moto-box-img-landing-1 {
  min-height: 100%;
}
.img-box-bg {
  background: #ededed;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.header-bold {
  text-align: center;
  margin: auto;
  margin-bottom: 3rem;
}
.pt-60 {
  padding-top: 60px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-60 {
  padding-bottom: 60px;
}
.color_black {
  color: #000 !important;
}
.box-ic.mmargin,
.box-ic {
  display: flex;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 8px 8px rgb(131 139 154 / 100%);
  border-radius: 10px;
  background-color: var(--white-color);
}
.why_study img {
  height: 217px;
  width: 100%;
  object-fit: cover;
}
.bg-blue p {
  color: #b5b3b3;
}
.blue1 p,
.blue1 h5,
.blue2 p,
.blue2 h5 {
  color: #fff;
}
.mb-60 {
  margin-bottom: 60px;
}
p {
  line-break: auto !important;
}
.width_from {
  max-width: 620px;
}
.width_Courses {
  max-width: 350px;
  width: 100%;
}
.width_Courses p {
  color: #fff;
}

.event-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}
.event-grid:nth-of-type(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.hero-slider-top .hero-style1 {
  margin-right: 0;
}
.event-grid .event-img {
  width: 41.6666666667%;
  position: relative;
  height: 240px;
  border-radius: 10px;
}
.event-grid .event-img img {
  width: -webkit-fill-available;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 1299px) {
  .event-grid:nth-of-type(odd) .event-content {
    padding: 50px 30px 50px 50px;
  }
  .event-grid .event-content {
    width: 66%;
    padding: 50px 50px 50px 30px;
  }
}
.event-grid:nth-of-type(odd) .event-content .event-bg-shape {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  left: auto;
  right: -40px;
}
.event-grid .event-content .event-bg-shape {
  position: absolute;
  left: -40px;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--white-color);
  z-index: -1;
  -webkit-mask-size: cover;
  -webkit-mask-position: left;
}
.event-grid:nth-of-type(odd) .event-content {
  right: unset;
  left: 0;
  margin-left: 0;
  margin-right: 40px;
  padding: 60px 40px 60px 60px;
}
.event-grid .event-content {
  background-color: #fff;
  width: 63%;
  box-shadow: 0px 4px 29px rgba(152, 170, 204, 0.15);
  border-radius: 10px;
  padding: 60px 60px 60px 40px;
  position: absolute;
  bottom: 50%;
  right: 0;
  z-index: 3;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.become-instructor-wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.07);
  padding: 30px;
}
.become-instructor-wrap h4 {
  font-size: 18px;
}
.become-instructor-wrap p {
  font-size: 14px;
  font-weight: 500;
}
.justify-content {
  justify-content: center;
}
.cta-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.cta-bg-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: #00112b;
  opacity: 0.87;
}
.sub-title {
  font-size: 16px;
  font-weight: 500;
  /*color: #fff;*/
  font-family: var(--title-font);
  text-transform: uppercase;
  margin-top: -0.41em;
  margin-bottom: 22px;
  display: block;
}
.white {
  color: #fff;
}
.hero-style1 {
  padding: 80px 0 80px 0 !important;
}
.cfp_logo_top {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 40px;
}
.box-center {
  align-items: center;
  justify-content: center;
}
.landing_bg_1 {
  background-image: url(../assets/img/log/hero_bg_5_1.jpg);
  background-position: center;
}
.landing_bg_2 {
  background-image: url(../assets/img/13.jpg);
  background-position: center;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  height: 80px;
}
.logoicfp {
  height: 75px !important;
}
.nav-link img {
  height: 60px;
}
li.nav-item.callnowbtn a {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 22px;
}
.callnowbtn a {
  color: #000 !important;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.title-area.width_Courses .sec-title {
  font-size: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.callnowbtn a {
  color: #000 !important;
}
.footer_landing_page {
  background-color: #003768 !important;
}
.footer_landing_page h5 {
  color: #fff;
}
#whatsapp_1 {
  bottom: 20px;
  left: 10px;
  box-sizing: border-box;
  position: fixed !important;
  z-index: 16000160 !important;
  direction: ltr;
  text-align: right;
  display: false;
  align-items: false;
  flex-direction: false;
}
.whatsapp_2 {
  display: flex;
  margin: 7px;
  position: relative;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.whatsapp_3{
  color:#4dc247;
  width:50px;
}
.whatsapp_4 {
  flex-shrink: 0;
  opacity: 1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  order: 2;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  box-shadow: rgba(136, 136, 136, 0.4) 0px 1px 7px;
  transition: all 0.5s ease 0s;
  position: relative;
  z-index: 200;
  text-decoration: none !important;
  background-color: rgb(77, 194, 71) !important;
}
.whatsapp_5 {
  width: 100%;
  height: 100%;
  fill: rgb(255, 255, 255);
  stroke: none;
}
.whatsapp_6 {
  
}
.landing_bg_2 {
  background-image: url(../assets/img/log/cta-bg.png);
  background-position: center;
}
.landing_bg_3 {
  background-image: url(../assets/img/log/cta-bg2-shape.png);
  background-position: center;
}
.landing_bg_4 {
  background-image: url(../assets/img/log/course_bg_2.png);
  background-position: center;
}
.landing_bg_5 {
  background-image: url(../assets/img/log/card_bg_v2_1.jpg);
  background-position: center;
}
.landing_bg_6 {
  background-image: url(../assets/img/log/card_bg_v2_2.jpg);
  background-position: center;
}
.landing_bg_7 {
  background-image: url(../assets/img/log/card_bg_v2_3.jpg);
  background-position: center;
}
.landing_bg_8 {
  background-image: url(../assets/img/log/event-bg_2.png);
  background-position: center;
}
.landing_bg_9 {
  -webkit-mask-image: url(../assets/img/log/event_shape2.png);
  background-position: center;
}
.landing_bg_10 {
  background-image: url(../assets/img/log/cta-bg_3_1.png);
  background-position: center;
}
.landing_bg_11 {
  background-image: url(../assets/img/log/cta-bg_3_2.png);
  background-position: center;
}
@media (max-width: 767px) {
  .landing_page_style .sec-title {
    font-size: 32px !important;
  }
  .event-grid {
    margin-bottom: 220px;
  }
  .event-grid_last_box {
    margin-bottom: 50px;
  }
}

.box_counter_box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  overflow: hidden;
}
.moto-box-img-landing ul {
  margin-top: 30px;
}
.bg_img_landing_1 {
  background-image: url("../assets/img/landing/course_bg_2.png");
  background-attachment: fixed;
}
.checklist-black ul li::before {
  background-image: url(../assets/img/landing/checked-black.png);
}
@media only screen and (max-width: 767px) and (min-width: 320px)  {
.landing-page-top-form-bottom-margin {
  margin-bottom: 30px;
}
.width_45_box {
  width: 100%;
}
}
.landing-page-counter-card {
  padding: 35px 0 35px !important;
}
.cfp_logo_top.landing_page_cfp_logo_top {
  width: 200px;
}
.landing-page-top-form-bottom-margin {
  margin: 20px 0;
}
.landing_page_style .sub-title {
  display: flex !important;
  align-items: center;
}
.landing_page_style .sub-title.sub-title-left svg {
  margin-right: 5px;
}
.landing-2-page .moto-box_img.checklist {
  padding-bottom: 60px;
}
.openpopup-width {
  text-align: center;
}
.landing-2-page .moto-box-img-landing {
  min-height: 550px;
}