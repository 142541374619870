
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@use "sass:math";
:root {
    --theme-color: #003768;
    --theme-color-2: #0364a9;
  --theme-color2: #f20f10;
  --title-color: #0f2239;
  --body-color: #4d5765;
  --smoke-color: #f3f7fb;
  --black-color: #000000;
  --white-color: #ffffff;
  --light-color: #72849b;
  --yellow-color: #ffb539;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #ecf1f9;
  --title-font: "Jost", sans-serif;
  --body-font: "Jost", sans-serif !important;
  --icon-font: "Font Awesome 6 Pro";
  /*--main-container: 1380px;*/
  --main-container: 1920px;
  --container-gutters: 24px;
  --section-space: 60px;
  --section-space-mobile: 80px;
  --section-title-space: 70px;
  --ripple-ani-duration: 5s;
}
html,
body {
  scroll-behavior: auto !important;
}
body {
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 32px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}
iframe {
  border: none;
  width: 100%;
}
.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}
img:not([draggable]),
embed,
object,
video {
    height: auto;
    width: 100%;
}
a {
  text-decoration: none !important;
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}
table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--border-color);
}
th {
  font-weight: 700;
  color: var(--title-color);
}
td,
th {
  border: 1px solid var(--border-color);
  padding: 9px 12px;
}
a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
a:hover {
  color: var(--title-color);
}
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}
button {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
img {
  border: none;
  max-width: 100%;
}
img, svg {
  vertical-align: text-top !important;
}
ins {
  text-decoration: none;
}
pre {
  font-family: var(--body-font);
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
span.ajax-loader:empty,
p:empty {
  display: none;
}
p {
  font-family: var(--body-font);
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.75 !important; 
  line-break: anywhere;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 0;
}
.h1,
h1 {
  font-size: 60px;
  line-height: 1.167;
}
.h2,
h2 {
  font-size: 48px;
  line-height: 1.208;
}
.h3,
h3 {
  font-size: 36px;
  line-height: 1.278;
}
.h4,
h4 {
  font-size: 30px;
  line-height: 1.333;
  font-weight: 600;
}
.h5,
h5 {
  font-size: 24px;
  line-height: 1.417;
  font-weight: 600;
}
.h6,
h6 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
}
@media (max-width: 1399px) {
  .h1,
  h1 {
    font-size: 48px;
  }
  .h2,
  h2 {
    font-size: 40px;
  }
}
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 36px;
  }
  .h3,
  h3 {
    font-size: 30px;
  }
  .h4,
  h4 {
    font-size: 24px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 28px;
  }
  .h3,
  h3 {
    font-size: 26px;
  }
  .h4,
  h4 {
    font-size: 22px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .h1,
  h1 {
    font-size: 34px;
    line-height: 1.3;
  }
}
@media (max-width: 375px) {
  .h1,
  h1 {
    font-size: 32px;
  }
}
@media (max-width: 1399px) {
  :root {
    --main-container: 1366px;
  }
}
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  max-width: calc(var(--main-container) + var(--container-gutters)) !important;
}
@media only screen and (min-width: 1300px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}
@media (min-width: 1700px) {
  .as-container {
    --main-container: 1450px;
    -webkit-transform: translateX(-115px);
    -ms-transform: translateX(-115px);
    transform: translateX(-115px);
  }
  .as-container3 {
    --main-container: 1760px;
  }
}
@media (min-width: 1600px) {
  .as-container {
    --main-container: 1350px;
    -webkit-transform: translateX(-65px);
    -ms-transform: translateX(-65px);
    transform: translateX(-65px);
  }
  .as-container2 {
    --main-container: 1680px;
  }
}
@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.slick-track > [class*="col"] {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}
.gy-30 {
  --bs-gutter-y: 30px;
}
.gy-40 {
  --bs-gutter-y: 40px;
}
.gy-50 {
  --bs-gutter-y: 50px;
}
.gx-10 {
  --bs-gutter-x: 10px;
}
@media (min-width: 1500px) {
  .gx-60 {
    --bs-gutter-x: 60px;
  }
}
@media (min-width: 1399px) {
  .gx-30 {
    --bs-gutter-x: 30px;
  }
  .gx-25 {
    --bs-gutter-x: 25px;
  }
  .gx-40 {
    --bs-gutter-x: 40px;
  }
}
@media (max-width: 991px) {
  .gy-50 {
    --bs-gutter-y: 40px;
  }
}
select,
.form-control,
.form-select,
textarea,
input {
  height: 55px;
  padding: 0 25px 0 25px;
  padding-right: 45px;
  border: 1px solid transparent;
  color: var(--body-color);
  background-color: var(--smoke-color);
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  font-family: var(--body-font);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  border-color: var(--theme-color);
  background-color: var(--smoke-color);
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::-ms-input-placeholder {
  color: var(--body-color);
}
select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}
select.style2,
.form-control.style2,
.form-select.style2,
textarea.style2,
input.style2 {
  border: 1px solid transparent;
  padding-left: 52px;
  background-image: none;
}
select.style2 ~ i,
.form-control.style2 ~ i,
.form-select.style2 ~ i,
textarea.style2 ~ i,
input.style2 ~ svg {
  color: var(--theme-color);
  font-weight: 400;
  left: 25px;
  max-width: 16px;
}
select.style3,
.form-control.style3,
.form-select.style3,
textarea.style3,
input.style3 {
  background-color: var(--white-color);
  border: none;
}
.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-radius: 5px;
}
.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}
.form-group > svg {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 19px;
  font-size: 16px;
  color: #93a5be;
}
.form-group > i.fa-envelope {
  padding-top: 1px;
}
.form-group > i.fa-comment {
  margin-top: -2px;
}
.form-group > i.fa-chevron-down {
  width: 17px;
  background-color: var(--white-color);
}
.form-group.has-label > svg {
  top: 50px;
}
[class*="col-"].form-group > svg {
  right: calc((var(--bs-gutter-x) / 2) + 25px);
}
[class*="col-"].form-group .style2 ~ svg {
  left: calc((var(--bs-gutter-x) / 2) + 25px);
}
option:checked,
option:focus,
option:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="checkbox"] {
  /* visibility: hidden; */
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  /* display: none; */
}
input[type="checkbox"]:checked ~ label:before {
  /* content: "\f00c"; */
  color: var(--white-color);
  /* background-color: var(--theme-color); */
  background-image: url(./assets/img/checkmark.png);
  border-color: var(--theme-color);
  background-size: contain;
}
input[type="checkbox"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
input[type="checkbox"] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}
input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type="radio"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
input[type="radio"] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
input[type="radio"]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}
label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}
textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}
textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}
textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right
    calc(0.375em + 0.8875rem);
}
.row.no-gutters > .form-group {
  margin-bottom: 0;
}
.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: var(--success-color);
  display: block;
}
.form-messages.error {
  color: var(--error-color);
  display: block;
}
.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}
.slick-track > [class*="col"] {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}
.slick-track {
  min-width: 100%;
}
.slick-slide img {
  display: inline-block;
}
.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 60px 0 0 0;
  line-height: 0;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 20px;
  height: 20px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  background-color: transparent;
  border: 2px solid #cedce9;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  position: relative;
}
.slick-dots button:hover {
  border-color: var(--theme-color);
}
.slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  visibility: hidden;
  opacity: 1;
}
.slick-dots .slick-active button {
  border-color: var(--theme-color);
}
.slick-dots .slick-active button::before {
  background-color: var(--theme-color);
  visibility: visible;
  opacity: 1;
}
.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.05);
  left: var(--pos-x, -100px);
  width: var(--icon-size, 60px);
  height: var(--icon-size, 60px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 60px) / -2);
  z-index: 2;
  border-radius: 999px;
}
.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}
.slick-arrow.slick-next {
  right: var(--pos-x, -100px);
  left: auto;
}
.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}
.arrow-margin .slick-arrow {
  top: calc(50% - 30px);
}
.arrow-wrap .slick-arrow {
  opacity: 0;
  visibility: hidden;
}
.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1500px) {
  .slick-arrow {
    --arrow-horizontal: -20px;
    --pos-x: -70px;
  }
}
@media (max-width: 1399px) {
  .slick-arrow {
    --arrow-horizontal: 40px;
    --pos-x: -17px;
  }
}
@media (max-width: 991px) {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }
  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  }
  .slick-dots {
    margin: 40px 0 0 0;
  }
}
.as-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}
.as-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: rgba(28, 185, 200, 0.1);
}
.as-menu-wrapper .mobile-logo a img {
  width: 150px;
}
.as-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}
.as-menu-wrapper .as-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: -16.5px;
  top: 25px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 35px;
  font-size: 18px;
  z-index: 1;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 50%;
}
.as-menu-wrapper .as-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.as-menu-wrapper .as-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  z-index: 1;
}
.as-menu-wrapper.as-body-visible {
  opacity: 1;
  visibility: visible;
}
.as-menu-wrapper.as-body-visible .as-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.as-mobile-menu {
    overflow-y: scroll;
    max-height: calc(100vh - 240px);
    padding-bottom: 40px;
    margin-top: 5px;
    text-align: left;
}
.as-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}
.as-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}
.as-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}
.as-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1.4;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
  padding-left: 18px;
}
.menu-item-has-children a {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.menu-item-has-children a svg {
  margin-right: 5px;
}
.mobile_view_menu a {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
/* .as-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 12px;
  margin-right: 10px;
  display: inline-block;
} */
.as-mobile-menu ul li.as-active > a {
  color: var(--theme-color);
}
.as-mobile-menu ul li.as-active > a:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.as-mobile-menu ul li ul li {
  padding-left: 20px;
}
.as-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}
.as-mobile-menu ul .as-item-has-children > a .as-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}
.as-mobile-menu ul .as-item-has-children > a .as-mean-expand:before {
  content: "\f067";
  font-family: var(--icon-font);
}
.as-mobile-menu ul .as-item-has-children > a:after {
  content: "\f067";
  font-family: var(--icon-font);
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  border-radius: 50px;
  background-color: var(--smoke-color);
  float: right;
  margin-top: 1px;
}
.as-mobile-menu ul .as-item-has-children.as-active > a .as-mean-expand:before {
  content: "\f068";
}
.as-mobile-menu ul .as-item-has-children.as-active > a:after {
  content: "\f068";
}
.as-mobile-menu > ul {
  padding: 0 40px;
}
.as-mobile-menu > ul > li:last-child {
  border-bottom: none;
}
.as-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 20px;
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 5px;
}
.as-menu-toggle:hover {
  background-color: var(--title-color);
}
.as-menu-toggle.style-text,
.as-menu-toggle.style-text-white {
  width: auto;
  height: auto;
  background-color: transparent;
  color: var(--title-color);
  font-size: 20px;
}
.as-menu-toggle.style-text i,
.as-menu-toggle.style-text-white svg {
  margin-right: 10px;
}
.as-menu-toggle.style-text-white {
  color: var(--white-color);
}
@media (max-width: 400px) {
  .as-menu-wrapper .as-menu-area {
    width: 100%;
    max-width: 270px;
  }
  .as-mobile-menu > ul {
    padding: 0 20px;
  }
}
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--title-color);
}
.preloader .as-btn {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 14px;
}
.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1;
}
.preloader-inner img {
  display: block;
  margin: 0 auto 10px auto;
}
.loader {
  width: 48px;
  height: 48px;
  background: var(--theme-color);
  display: block;
  margin: 20px auto;
  position: relative;
  box-sizing: border-box;
  animation: rotationBack 1s ease-in-out infinite reverse;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  left: 0;
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  width: 48px;
  height: 48px;
  background: var(--theme-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.loader::after {
  content: "";
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
@-webkit-keyframes rotationBack {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotationBack {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.as-btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 20.5px 29px;
  box-shadow: 0px 6px 16px rgba(13, 94, 244, 0.24);
  border-radius: 5px;
}
.as-btn:before {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 600%;
  background-color: var(--title-color);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(60deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(60deg);
  transform: translateX(-50%) translateY(-50%) rotate(60deg);
  z-index: -1;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}
.as-btn:hover,
.as-btn.active {
  color: var(--white-color);
}
.as-btn:hover::before,
.as-btn.active::before {
  height: 560%;
}
.as-btn.style2 {
  background-color: transparent;
  color: var(--white-color);
  border: 1px solid var(--white-color);
  padding: 19.5px 27px;
  box-shadow: none;
}
.as-btn.style2:hover {
  color: var(--title-color);
}
.as-btn.style2:hover:before {
  background-color: var(--white-color);
}
.as-btn.style3 {
  box-shadow: none;
}
.hero-style1 .as-btn.style3 {
  display: flex;
  align-items: center;
}
.hero-style1 .as-btn.style3 svg {
  margin-left: 8px;
}
.as-btn.style3:hover {
  color: var(--title-color);
}
.as-btn.style3:before {
  background-color: var(--white-color);
}
.as-btn.style4 {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 19.5px 27px;
  box-shadow: none;
}
.as-btn.style4:before {
  background-color: var(--white-color);
}
.as-btn.style4:hover {
  color: var(--white-color);
}
.as-btn.style4:hover:before {
  background-color: var(--theme-color);
}
.as-btn.style5 {
  background-color: var(--title-color);
}
.as-btn.style5:before {
  background-color: var(--theme-color);
}
.as-btn.style5:hover:before {
  background-color: var(--theme-color);
}
.as-btn.style5 {
  background-color: var(--smoke-color);
  color: var(--theme-color);
  box-shadow: none;
}
.as-btn.style5:before {
  background-color: var(--smoke-color);
}
.as-btn.style5:hover {
  color: var(--white-color);
}
.as-btn.style5:hover:before {
  background-color: var(--theme-color);
}
.icon-btn {
  display: inline-block;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  font-size: var(--btn-font-size, 16px);
  background-color: var(--icon-bg, #fff);
  color: var(--title-color);
  text-align: center;
  border-radius: 50%;
  border: none;
}
.icon-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.play-btn > svg {
  display: inline-block;
  width: var(--icon-size, 100px);
  height: var(--icon-size, 100px);
  line-height: var(--icon-size, 100px);
  text-align: center;
  background-color: var(--white-color);
  color: var(--theme-color);
  font-size: var(--icon-font-size, 1.2em);
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.play-btn:after,
.play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--title-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.play-btn:after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.play-btn:hover:after,
.play-btn:hover::before,
.play-btn:hover svg {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.link-btn {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
  color: var(--theme-color);
}
.link-btn svg {
  margin-left: 5px;
  font-size: 0.9rem;
}
.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.link-btn:hover {
  color: var(--theme-color);
}
.link-btn:hover::before {
  width: 100%;
}
.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 50%;
}
.scroll-btn svg {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size, 55px);
  height: var(--btn-size, 55px);
  line-height: var(--btn-size, 55px);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}
.scroll-btn:before {
  content: "";
  position: absolute;
  left: var(--extra-shape, -10px);
  top: var(--extra-shape, -10px);
  right: var(--extra-shape, -10px);
  bottom: var(--extra-shape, -10px);
  background-color: #fff;
  border-radius: inherit;
  z-index: 1;
}
.scroll-btn:focus i,
.scroll-btn:hover svg {
  background-color: var(--title-color);
  color: var(--white-color);
}
.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .scroll-btn {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;
  }
  .scroll-btn.show {
    bottom: 15px;
  }
}
.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  z-index: 96;
}
.scrollToTop.show {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .play-btn {
    --icon-size: 60px;
  }
  .scrollToTop {
    right: 20px;
  }
  .scrollToTop.show {
    bottom: 20px;
  }
}
.sec-title {
  margin-bottom: calc(var(--section-title-space) - 12px);
  margin-top: -0.23em;
}
.sub-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color);
  font-family: var(--title-font);
  text-transform: uppercase;
  margin-top: -0.41em;
  margin-bottom: 22px;
  display: block;
}
.title-area {
  margin-bottom: calc(var(--section-title-space) - 12px);
  position: relative;
  z-index: 2;
}
.title-area .sec-title {
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: 700 !important;
}
.title-area.mb-0 .sec-title {
  margin-bottom: -0.41em;
}
.sec-text {
  max-width: 670px;
}
.title-area.text-center .sec-text {
  margin-left: auto;
  margin-right: auto;
}
.sec-btn {
  margin-bottom: var(--section-title-space);
}
@media (max-width: 1199px) {
  .sub-title {
    margin-bottom: 18px;
  }
  .shadow-title {
    font-size: 96px;
  }
  .title-area,
  .sec-title {
    --section-title-space: 60px;
  }
  .title-area.mb-30,
  .sec-title.mb-30 {
    margin-bottom: 25px;
  }
  .title-area.mb-40,
  .sec-title.mb-40 {
    margin-bottom: 30px;
  }
  .title-area.mb-45,
  .sec-title.mb-45 {
    margin-bottom: 32px;
  }
  .title-area.mb-50,
  .sec-title.mb-50 {
    margin-bottom: 35px;
  }
  .title-area.mb-25,
  .sec-title.mb-25 {
    margin-bottom: 15px;
  }
  .title-area.mb-35,
  .sec-title.mb-35 {
    margin-bottom: 25px;
  }
  .sec-btn {
    --section-title-space: 65px;
  }
}
@media (max-width: 991px) {
  .shadow-title {
    font-size: 88px;
  }
  .sub-title {
    margin-bottom: 12px;
  }
  .title-area,
  .sec-title {
    --section-title-space: 45px;
  }
  .title-area.mb-30,
  .sec-title.mb-30 {
    margin-bottom: 20px;
  }
  .title-area.mb-40,
  .sec-title.mb-40 {
    margin-bottom: 25px;
  }
  .title-area.mb-45,
  .sec-title.mb-45 {
    margin-bottom: 27px;
  }
  .title-area.mb-50,
  .sec-title.mb-50 {
    margin-bottom: 28px;
  }
  .sec-btn {
    --section-title-space: 45px;
  }
}
@media (max-width: 767px) {
  .shadow-title {
    font-size: 60px;
  }
}
@media (max-width: 375px) {
  .shadow-title {
    font-size: 50px;
    top: 55%;
  }
}
.image-scale-hover {
  overflow: hidden;
}
.image-scale-hover img {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1.001);
  -ms-transform: scale(1.001);
  transform: scale(1.001);
}
.image-scale-hover:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}
.z-index-common {
  position: relative;
  z-index: 3;
}
.z-index-n1 {
  z-index: -1;
}
.media-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.35em 0.55em;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  font-weight: 400;
}
.as-social a {
  display: inline-block;
  width: var(--icon-size, 45px);
  height: var(--icon-size, 45px);
  line-height: var(--icon-size, 45px);
  background-color: var(--white-color);
  color: var(--theme-color);
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
  border-radius: 50%;
}
.as-social a:last-child {
  margin-right: 0;
}
.as-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.slider-shadow .slick-list {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: -30px;
  margin-top: -30px;
}
.btn-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}
.video-box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .video-box {
    min-height: 400px;
  }
  .video-box img {
    height: 400px;
    object-fit: cover;
  }
}
.video-box .overlay {
  width: 100%;
  height: 100%;
  background-color: var(--black-color);
  opacity: 0.6;
  position: absolute;
  inset: 0;
}
.video-box .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}
.video-box .video-content {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  padding: 30px 15px;
}
.video-box .video-title {
  color: var(--white-color);
  margin: 0 auto 40px auto;
  font-weight: 500;
  max-width: 760px;
}
@media (max-width: 1199px) {
  .video-box .video-title {
    margin: 0 auto 30px auto;
  }
}
.video-box .video-text {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white-color);
  display: block;
  margin-top: 20px;
}
.video-box .icon-btn {
  background-color: transparent;
  border: 1px solid;
  color: var(--white-color);
  --btn-size: 80px;
  font-size: 20px;
}
.video-box .icon-btn:hover {
  color: var(--theme-color);
}
.shape-mockup-wrap {
  z-index: 2;
  position: relative;
}
.shape-mockup {
  position: absolute;
  z-index: -1;
}
.shape-mockup.z-index3 {
  z-index: 3;
}
.font-icon {
  font-family: var(--icon-font);
}
.font-title {
  font-family: var(--title-font);
}
.font-body {
  font-family: var(--body-font);
}
.fw-extralight {
  font-weight: 100;
}
.fw-light {
  font-weight: 300;
}
.fw-normal {
  font-weight: 400;
}
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fw-extrabold {
  font-weight: 800;
}
.fs-md {
  font-size: 18px;
}
.fs-xs {
  font-size: 14px;
}
.fs-40 {
  font-size: 40px;
}
@media (max-width: 1199px) {
  .fs-40 {
    font-size: 34px;
  }
}
@media (max-width: 991px) {
  .fs-40 {
    font-size: 30px;
  }
  .fs-40.mt-n3 {
    margin-top: -0.6rem;
  }
  .fs-40.mt-n2 {
    margin-top: -0.2rem;
  }
}
.bg-theme {
  background-color: var(--theme-color) !important;
}
.bg-theme2 {
  background-color: var(--theme-color2) !important;
}
.bg-smoke {
  background-color: var(--smoke-color) !important;
}
.bg-white {
  background-color: var(--white-color) !important;
}
.bg-black {
  background-color: var(--black-color) !important;
}
.bg-title {
  background-color: var(--title-color) !important;
}
.gr-bg1 {
  background-image: -webkit-linear-gradient(
    194.6deg,
    rgba(146, 184, 253, 0.15) 5.09%,
    var(--smoke-color) 63%
  );
  background-image: linear-gradient(
    255.4deg,
    rgba(146, 184, 253, 0.15) 5.09%,
    var(--smoke-color) 63%
  );
}
.gr-bg2 {
  background-image: -webkit-linear-gradient(right, #edf2fb 0%, #fff 100%);
  background-image: linear-gradient(270deg, #edf2fb 0%, #fff 100%);
}
.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.bg-auto {
  background-size: auto auto;
}
.bg-bottom-right {
  background-size: auto;
  background-position: bottom right;
}
.bg-top-center {
  background-size: 100% 67%;
  background-position: top center;
}
.text-theme {
  color: var(--theme-color) !important;
}
.text-theme2 {
  color: var(--theme-color2) !important;
}
.text-title {
  color: var(--title-color) !important;
}
.text-body {
  color: var(--body-color) !important;
}
.text-white {
  color: var(--white-color) !important;
}
.text-light {
  color: var(--light-color) !important;
}
.text-yellow {
  color: var(--yellow-color) !important;
}
.text-success {
  color: var(--success-color) !important;
}
.text-error {
  color: var(--error-color) !important;
}
.text-inherit {
  color: inherit;
}
.text-inherit:hover {
  color: var(--theme-color);
}
a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
[data-overlay] {
  position: relative;
  z-index: 2;
}
[data-overlay] [class^="col-"],
[data-overlay] [class*="col-"] {
  z-index: 1;
}
[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
[data-overlay="theme"]:before {
  background-color: var(--theme-color);
}
[data-overlay="title"]:before {
  background-color: var(--title-color);
}
[data-overlay="white"]:before {
  background-color: var(--white-color);
}
[data-overlay="black"]:before {
  background-color: var(--black-color);
}
[data-overlay="overlay1"]:before {
  background-color: #041122;
}
[data-opacity="1"]:before {
  opacity: 0.1;
}
[data-opacity="2"]:before {
  opacity: 0.2;
}
[data-opacity="3"]:before {
  opacity: 0.3;
}
[data-opacity="4"]:before {
  opacity: 0.4;
}
[data-opacity="5"]:before {
  opacity: 0.5;
}
[data-opacity="6"]:before {
  opacity: 0.6;
}
[data-opacity="7"]:before {
  opacity: 0.7;
}
[data-opacity="8"]:before {
  opacity: 0.8;
}
[data-opacity="9"]:before {
  opacity: 0.9;
}
[data-opacity="10"]:before {
  opacity: 1;
}
.ripple-animation,
.play-btn:after,
.play-btn:before {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
  animation-name: ripple;
}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
.movingX {
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
}
@-webkit-keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.jump {
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
@-webkit-keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.jump-reverse {
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}
@-webkit-keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.spin {
  -webkit-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.scalein.as-animated {
  --animation-name: scalein;
}
.slidetopleft.as-animated {
  --animation-name: slidetopleft;
}
.slidebottomright.as-animated {
  --animation-name: slidebottomright;
}
.slideinleft.as-animated {
  --animation-name: slideinleft;
}
.slideinright.as-animated {
  --animation-name: slideinright;
}
.slideinup.as-animated {
  --animation-name: slideinup;
}
.slideindown.as-animated {
  --animation-name: slideindown;
}
.rollinleft.as-animated {
  --animation-name: rollinleft;
}
.rollinright.as-animated {
  --animation-name: rollinright;
}
.scalein,
.slidetopleft,
.slidebottomright,
.slideinleft,
.slideinright,
.slideindown,
.slideinup,
.rollinleft,
.rollinright {
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-name: var(--animation-name);
  animation-name: var(--animation-name);
}
.as-animated {
  opacity: 1;
}
@-webkit-keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(70px);
    transform: translateX(70px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(70px);
    transform: translateX(70px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-70px);
    transform: translateX(-70px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-70px);
    transform: translateX(-70px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px) translateY(100px);
    transform: translateX(100px) translateY(100px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px) translateY(100px);
    transform: translateX(100px) translateY(100px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@-webkit-keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
    transform: translateX(-100px) translateY(-100px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
    transform: translateX(-100px) translateY(-100px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@-webkit-keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes rollinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}
@keyframes rollinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}
@-webkit-keyframes rollinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}
@keyframes rollinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
  to {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0 0;
}
.widget_nav_menu .menu,
.widget_nav_menu > ul,
.widget_meta .menu,
.widget_meta > ul,
.widget_pages .menu,
.widget_pages > ul,
.widget_archive .menu,
.widget_archive > ul,
.widget_categories .menu,
.widget_categories > ul {
  margin: 0 0 -20px 0;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  margin: 0 0 20px 0;
  padding: 0 35px 0 29px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.18;
  color: var(--body-color);
}
/* .widget_nav_menu a::before,
.widget_meta a::before,
.widget_pages a::before,
.widget_archive a::before,
.widget_categories a::before {
  content: "\f00c";
  width: 18px;
  height: 18px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  line-height: 18px;
  font-size: 0.8em;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--white-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
} */
.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--theme-color);
}
.widget_nav_menu a:hover ~ span,
.widget_meta a:hover ~ span,
.widget_pages a:hover ~ span,
.widget_archive a:hover ~ span,
.widget_categories a:hover ~ span {
  color: var(--theme-color);
}
.widget_nav_menu a:hover:before,
.widget_meta a:hover:before,
.widget_pages a:hover:before,
.widget_archive a:hover:before,
.widget_categories a:hover:before {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}
.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
  border-bottom: none;
}
.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span {
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  line-height: 1.18;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  color: var(--body-color);
  font-weight: 400;
}
.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}
.widget_nav_menu .sub-menu {
  margin-left: 10px;
}
.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.wp-block-archives a:not(:hover) {
  color: inherit;
}
.as-blog ul.wp-block-archives li {
  margin: 5px 0;
}
.widget {
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
  margin-bottom: 40px;
  position: relative;
}
.widget select,
.widget input {
  height: 55px;
  border-radius: 8px;
}
.wp-block-search__label,
.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  font-family: var(--title-font);
  line-height: 1em;
  margin: -0.1em 0 29px 0;
  padding-bottom: 16px;
  border-bottom: 1px dashed #d3dbeb;
}
.wp-block-search__label a,
.widget_title a {
  color: inherit;
}
.widget .search-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.widget .search-form input {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 8px;
  border-radius: 8px 0 0 8px;
}
.widget .search-form button {
  border: none;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 0 8px 8px 0;
}
.widget .search-form button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  border: none;
  line-height: 1;
  padding: 9.5px 16px;
  margin-right: 5px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--body-color);
  background-color: var(--smoke-color);
  border-radius: 5px;
}
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color) !important;
}
.tagcloud {
  margin-right: -10px;
  margin-bottom: -10px;
}
.recent-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
  line-height: 20px;
}
.recent-post:last-child {
  margin-bottom: 0;
}
.recent-post .media-img {
  margin-right: 20px;
  width: 80px;
  border-radius: 5px;
  overflow: hidden;
}
.recent-post .media-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.recent-post .post-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 -0.1em 0;
  font-family: var(--title-font);
}
.recent-post-meta a {
  font-size: 12px;
}
.recent-post .recent-post-meta {
  margin-bottom: 5px;
}
.recent-post .recent-post-meta a {
  text-transform: capitalize;
  color: var(--body-color);
}
.recent-post .recent-post-meta a:hover {
  color: var(--theme-color);
}
.recent-post .recent-post-meta a svg {
  margin-right: 8px;
  color: var(--theme-color);
}
.recent-post:hover .media-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  border-radius: 6px;
}
.sidebar-gallery .gallery-thumb img {
  width: 100%;
}
.vs-video-widget .video-thumb {
  position: relative;
}
.vs-video-widget .play-btn {
  --icon-size: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.vs-video-widget .play-btn svg {
  font-size: 16px;
}
.vs-video-widget .video-thumb-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: -0.3em;
}
.widget_recent_entries ul {
  margin: -0.3em 0 0 0;
  padding: 0;
  list-style: none;
}
.widget_recent_entries ul li > a {
  color: var(--body-color);
  font-weight: 500;
  display: inline-block;
}
.widget_recent_entries ul li > a:hover {
  color: var(--theme-color);
}
.widget_recent_entries ul li span.post-date {
  font-size: 14px;
}
.widget_recent_entries ul li:not(:last-child) {
  border-bottom: 1px solid #dadada;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.widget_recent_comments ul,
.wp-block-latest-comments ul {
  list-style-type: none;
  padding-left: 0;
}
ul.widget_recent_comments,
ol.widget_recent_comments,
.wp-block-latest-comments {
  margin-top: -0.11em;
  padding-left: 0;
}
.widget_recent_comments ol,
.widget_recent_comments ul,
.wp-block-latest-comments ol,
.wp-block-latest-comments ul {
  margin-bottom: 0;
}
.widget_recent_comments li,
.wp-block-latest-comments li {
  margin-bottom: 0;
  color: var(--body-color);
  padding-left: 30px;
  position: relative;
}
.widget_recent_comments li:before,
.wp-block-latest-comments li:before {
  content: "\f086";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--theme-color);
  font-family: var(--icon-font);
}
.widget_recent_comments.has-avatars li,
.wp-block-latest-comments.has-avatars li {
  padding-left: 0;
  padding-bottom: 0 !important;
}
.widget_recent_comments.has-avatars li:before,
.wp-block-latest-comments.has-avatars li:before {
  display: none;
}
.widget_recent_comments .avatar,
.wp-block-latest-comments .avatar {
  margin-top: 0.4em;
}
.widget_recent_comments li:not(:last-child),
.wp-block-latest-comments li:not(:last-child) {
  padding-bottom: 12px;
}
.widget_recent_comments article,
.wp-block-latest-comments article {
  line-height: 1.5;
}
.widget_recent_comments a,
.wp-block-latest-comments a {
  color: inherit;
}
.widget_recent_comments a:hover,
.wp-block-latest-comments a:hover {
  color: var(--theme-color);
}
.wp-block-latest-comments__comment {
  line-height: 1.6;
}
.wp-block-latest-comments__comment a {
  color: var(--body-color);
}
.wp-block-latest-comments__comment a:hover {
  color: var(--theme-color);
}
.wp-block-latest-comments__comment:last-child {
  margin-bottom: 0;
}
.wp-block-calendar tbody td,
.wp-block-calendar th {
  padding: 10px;
}
.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;
}
.wp-block-calendar span[class*="wp-calendar-nav"],
.calendar_wrap span[class*="wp-calendar-nav"] {
  position: absolute;
  top: 9px;
  left: 20px;
  font-size: 14px;
  color: var(--white-color);
  font-weight: 400;
  z-index: 1;
  line-height: 1.7;
}
.wp-block-calendar span[class*="wp-calendar-nav"] a,
.calendar_wrap span[class*="wp-calendar-nav"] a {
  color: inherit;
}
.wp-block-calendar span.wp-calendar-nav-next,
.calendar_wrap span.wp-calendar-nav-next {
  left: auto;
  right: 20px;
}
.wp-block-calendar caption,
.calendar_wrap caption {
  caption-side: top;
  text-align: center;
  color: var(--white-color);
  background-color: var(--theme-color);
}
.wp-block-calendar th,
.calendar_wrap th {
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-right: 1px solid #fff;
  color: var(--title-color);
  font-weight: 500;
}
.wp-block-calendar th:first-child,
.calendar_wrap th:first-child {
  border-left: 1px solid #eee;
}
.wp-block-calendar th:last-child,
.calendar_wrap th:last-child {
  border-right: 1px solid #eee;
}
.wp-block-calendar table th,
.calendar_wrap table th {
  font-weight: 500;
}
.wp-block-calendar td,
.calendar_wrap td {
  font-size: 14px;
  padding: 5px 5px;
  color: #01133c;
  border: 1px solid #eee;
  text-align: center;
  background-color: transparent;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.wp-block-calendar #today,
.calendar_wrap #today {
  color: var(--theme-color);
  background-color: var(--white-color);
  border-color: #ededed;
}
.wp-block-calendar thead,
.calendar_wrap thead {
  background-color: #eee;
}
.wp-block-calendar .wp-calendar-table,
.calendar_wrap .wp-calendar-table {
  margin-bottom: 0;
}
.wp-block-calendar .wp-calendar-nav .pad,
.calendar_wrap .wp-calendar-nav .pad {
  display: none;
}
.wp-block-calendar a,
.calendar_wrap a {
  color: inherit;
  text-decoration: none;
}
.wp-block-calendar a:hover,
.calendar_wrap a:hover {
  color: var(--title-color);
}
.wp-block-calendar {
  margin-bottom: 30px;
  border: none;
  padding-bottom: 0;
}
.wp-block-calendar table caption {
  color: var(--white-color);
}
ul.widget_rss,
ul.wp-block-rss,
ol.widget_rss,
ol.wp-block-rss {
  padding-left: 0;
}
.widget_rss,
.wp-block-rss {
  list-style-type: none;
}
.widget_rss ul,
.wp-block-rss ul {
  margin: -0.2em 0 -0.5em 0;
  padding: 0;
  list-style: none;
}
.widget_rss ul .rsswidget,
.wp-block-rss ul .rsswidget {
  color: var(--title-color);
  font-family: var(--theme-font);
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}
.widget_rss ul .rssSummary,
.wp-block-rss ul .rssSummary {
  font-size: 14px;
  margin-bottom: 7px;
  line-height: 1.5;
}
.widget_rss ul a,
.wp-block-rss ul a {
  display: block;
  font-weight: 600;
  color: inherit;
}
.widget_rss ul a:hover,
.wp-block-rss ul a:hover {
  color: var(--theme-color);
}
.widget_rss ul .rss-date,
.wp-block-rss ul .rss-date {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--title-color);
}
.widget_rss ul .rss-date:before,
.wp-block-rss ul .rss-date:before {
  content: "\f073";
  font-family: var(--icon-font);
  margin-right: 10px;
  font-weight: 300;
  color: var(--theme-color);
}
.widget_rss ul cite,
.wp-block-rss ul cite {
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 14px;
}
.widget_rss ul cite:before,
.wp-block-rss ul cite:before {
  content: "";
  position: relative;
  top: -2px;
  left: 0;
  width: 20px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  background-color: var(--theme-color);
}
.widget_rss li:not(:last-child),
.wp-block-rss li:not(:last-child) {
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 16px;
}
.widget_rss a:hover,
.wp-block-rss a:hover {
  color: var(--theme-color);
}
.textwidget {
  margin-top: -0.1em;
}
.widget_shopping_cart .widget_title {
  margin-bottom: 30px;
  border-bottom: none;
}
.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
}
.widget_shopping_cart ul li {
  list-style-type: none;
}
.widget_shopping_cart .mini_cart_item {
  position: relative;
  padding: 30px 10px 30px 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  text-align: left;
}
.widget_shopping_cart .mini_cart_item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.widget_shopping_cart .cart_list a:not(.remove) {
  display: block;
  color: var(--body-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--title-font);
  font-weight: 600;
  color: var(--title-color);
}
.widget_shopping_cart .cart_list a:not(.remove):hover {
  color: var(--theme-color);
}
.widget_shopping_cart .cart_list a.remove {
  position: absolute;
  top: 50%;
  left: 95%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--body-color);
}
.widget_shopping_cart .cart_list a.remove:hover {
  color: var(--theme-color);
}
.widget_shopping_cart .cart_list img {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 0;
  top: 20px;
  border: 1px solid var(--border-color);
}
.widget_shopping_cart .quantity {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: top;
  margin-right: 20px;
  font-size: 14px;
  color: var(--theme-color);
}
.widget_shopping_cart .total {
  margin-top: 20px;
  font-size: 18px;
  color: var(--title-color);
  font-family: var(--body-font);
}
.widget_shopping_cart .amount {
  padding-left: 5px;
}
.widget_shopping_cart .as-btn {
  margin-right: 15px;
}
.widget_shopping_cart .as-btn:last-child {
  margin-right: 0;
}
.sidebar-area .widget_shopping_cart .as-btn {
  margin-right: 10px;
  padding: 8px 22px;
  font-size: 14px;
}
.sidebar-area {
  margin-bottom: -10px;
}
.sidebar-area .as-video {
  padding: 0;
  box-shadow: none;
  border-radius: 5px;
}
.sidebar-area .as-video .play-btn {
  --icon-size: 50px;
  font-size: 15px;
}
.sidebar-area .as-video .play-btn > svg {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.sidebar-area .as-video .play-btn:after,
.sidebar-area .as-video .play-btn:before {
  background-color: var(--white-color);
}
.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}
.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}
.sidebar-area .wp-block-tag-cloud a,
.sidebar-area .tagcloud a {
  border: none;
}
.sidebar-area .newsletter-form button {
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  margin-top: 10px;
}
.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}
.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}
.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}
.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.recent-product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.recent-product .product-title {
  font-size: 16px;
}
.widget_info .widget_title {
  margin: 29px 0 0 0;
}
.widget_info .as-btn {
  width: 100%;
  margin-bottom: 10px;
  display: block;
}
.widget_info .as-video {
  margin-bottom: 20px;
}
.widget_info .course-price {
  display: block;
  font-weight: bold;
  margin-bottom: 19px;
}
.widget_info .course-price .tag {
  display: inline-block;
  background-color: var(--theme-color2);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white-color);
  border-radius: 99px;
  padding: 2px 13px;
  vertical-align: middle;
  margin-bottom: 5px;
}
.info-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.info-list svg {
  color: var(--theme-color);
  width: 16px;
  margin-right: 2px;
  font-size: 16px;
}
.info-list strong {
  font-weight: 500;
  color: var(--title-color);
}
.info-list li {
  border-bottom: 1px dashed #d3dbeb;
  padding: 12px 0;
}
.info-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: -0.45em;
}
.widget_banner {
  padding: 0 !important;
  border: none;
}
.widget-map {
  line-height: 1px;
  border-radius: 10px;
}
.widget-map iframe {
  border-radius: 10px;
  height: 327px;
}
@media (max-width: 1199px) {
  .recent-post .post-title {
    font-size: 18px;
    line-height: 24px;
  }
  .widget_offer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .col-lg-4 .sidebar-area .widget {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }
}
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 30px;
  }
  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
  .col-lg-4 .sidebar-area .widget {
    --blog-space-y: 40px;
    --blog-space-x: 40px;
  }
}
@media (max-width: 767px) {
  .widget {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }
  .col-lg-4 .sidebar-area .widget {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }
}
.footer-widget {
  margin-bottom: 40px;
}
.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
  box-shadow: none;
}
.footer-widget input,
.footer-widget select {
  height: 55px;
  background-color: transparent;
  border: 1px solid #283752;
}
.footer-widget input:focus,
.footer-widget select:focus {
  border-color: var(--theme-color);
  background-color: transparent;
}
.footer-widget .form-group > svg {
  color: var(--theme-color);
  top: 18px;
}
.footer-widget .widget_title {
  position: relative;
  border: none;
  font-family: var(--title-font);
  color: var(--white-color);
  line-height: 1;
  border-bottom: 0;
  padding: 0 0 18px 0;
  margin: -0.1em 0 35px 0;
  max-width: 275px;
}
.footer-widget .widget_title:before,
.footer-widget .widget_title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 51px;
  height: 3px;
  background-color: var(--theme-color);
}
.footer-widget .widget_title:after {
  width: 10px;
  left: 56px;
  border-right: 10px solid var(--theme-color);
  box-shadow: 15px 0 0 0 var(--theme-color);
}
.footer-widget.widget_meta,
.footer-widget.widget_pages,
.footer-widget.widget_archive,
.footer-widget.widget_categories,
.footer-widget.widget_nav_menu {
  margin-bottom: 40px;
}
.footer-widget.widget_meta ul,
.footer-widget.widget_pages ul,
.footer-widget.widget_archive ul,
.footer-widget.widget_categories ul,
.footer-widget.widget_nav_menu ul {
  margin-top: -2px;
}
.footer-widget.widget_meta .menu,
.footer-widget.widget_meta > ul,
.footer-widget.widget_pages .menu,
.footer-widget.widget_pages > ul,
.footer-widget.widget_archive .menu,
.footer-widget.widget_archive > ul,
.footer-widget.widget_categories .menu,
.footer-widget.widget_categories > ul,
.footer-widget.widget_nav_menu .menu,
.footer-widget.widget_nav_menu > ul {
  margin-bottom: -5px;
}
.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 0 5px;
  margin-bottom: 20px;
  font-family: var(--body-font);
  display: flex;
  max-width: 100%;
  width: max-content;
  padding-right: 0;
  background-color: transparent;
  position: relative;
  align-items: center;
}
.footer-widget.widget_nav_menu a svg {
  margin-right: 5px;
  font-size: 12px;
}
.as-social a {
  margin: 0px 5px;
}
/* .footer-widget.widget_meta a:before,
.footer-widget.widget_pages a:before,
.footer-widget.widget_archive a:before,
.footer-widget.widget_categories a:before,
.footer-widget.widget_nav_menu a:before {
  content: "\e122";
  font-weight: 900;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: inherit;
  width: unset;
  background-color: transparent;
  border: none;
  font-size: 0.5em;
  color: var(--body-color);
  line-height: 0;
  height: 5px;
} */
.footer-widget.widget_meta a:hover,
.footer-widget.widget_pages a:hover,
.footer-widget.widget_archive a:hover,
.footer-widget.widget_categories a:hover,
.footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--white-color);
}
.footer-widget.widget_meta a:hover:before,
.footer-widget.widget_pages a:hover:before,
.footer-widget.widget_archive a:hover:before,
.footer-widget.widget_categories a:hover:before,
.footer-widget.widget_nav_menu a:hover:before {
  color: var(--white-color);
}
.footer-widget.widget_meta li > span,
.footer-widget.widget_pages li > span,
.footer-widget.widget_archive li > span,
.footer-widget.widget_categories li > span,
.footer-widget.widget_nav_menu li > span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}
.footer-widget.widget_meta li:last-child a,
.footer-widget.widget_pages li:last-child a,
.footer-widget.widget_archive li:last-child a,
.footer-widget.widget_categories li:last-child a,
.footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}
.footer-widget .recent-post {
  max-width: 310px;
  margin-bottom: 15px;
  padding-bottom: 0;
  border-bottom: none;
}
.footer-widget .recent-post .media-img {
  margin-right: 18px;
  width: 110px;
}
.footer-widget .recent-post .post-title {
  color: var(--white-color);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}
.footer-widget .recent-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.footer-widget .recent-post .recent-post-meta svg {
  color: var(--white-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget .recent-post .recent-post-meta a:hover svg {
  color: var(--theme-color);
}
.footer-widget .footer-logo {
  margin-bottom: 15px;
}
.as-widget-about .about-logo {
  margin-bottom: 30px;
  margin-top: -5px;
}
.as-widget-about .about-text {
  margin-bottom: 25px;
  margin-top: -0.46em;
}
.footer-text {
  margin-top: -0.46em;
  margin-bottom: 25px;
}
.newsletter-widget .form-group {
  margin-bottom: 20px;
}
.as-widget-schedule {
  margin-top: -0.25em;
}
.as-widget-schedule .footer-info {
  padding-left: 0;
}
.footer-table {
  margin-top: -15px;
  border: none;
  margin-bottom: 0;
}
.footer-table th,
.footer-table td {
  border: none;
  padding: 8px 0;
  font-size: 16px;
}
.footer-table td {
  padding-left: 50px;
}
.footer-table th {
  padding-right: 13px;
  font-weight: 500;
  color: var(--white-color);
}
.footer-info-title {
  font-size: 20px;
  font-family: var(--body-font);
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 15px;
  margin-top: -0.27em;
}
.footer-info {
  position: relative;
  margin: 0 0 25px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 0 0 35px;
  max-width: 300px;
}
.footer-info:last-child {
  margin-bottom: 0;
}
.footer-info svg {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  position: absolute;
  left: 0;
  font-size: 10px;
}
.footer-info:nth-child(2) svg {
  background-color: var(--theme-color);
}
.footer-info:nth-child(3) svg {
  background-color: var(--title-color);
}
@media (max-width: 1199px) {
  .footer-widget .widget_title {
    margin: -0.1em 0 30px 0;
  }
  .footer-widget.widget_meta a,
  .footer-widget.widget_pages a,
  .footer-widget.widget_archive a,
  .footer-widget.widget_categories a,
  .footer-widget.widget_nav_menu a {
    margin-bottom: 16px;
  }
}
.footer-widget.style2 .widget_title {
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 0 20px 0;
  font-weight: 500;
}
.footer-widget.style2 .widget_title:before {
  height: 2px;
  width: 60px;
  background-color: var(--white-color);
}
.footer-widget.style2 .widget_title:after {
  display: none;
}
.footer-widget.style2.widget_meta a,
.footer-widget.style2.widget_pages a,
.footer-widget.style2.widget_archive a,
.footer-widget.style2.widget_categories a,
.footer-widget.style2.widget_nav_menu a {
  padding: 0 0 0 25px;
  margin-bottom: 23px;
}
.footer-widget.style2.widget_meta a:before,
.footer-widget.style2.widget_pages a:before,
.footer-widget.style2.widget_archive a:before,
.footer-widget.style2.widget_categories a:before,
.footer-widget.style2.widget_nav_menu a:before {
  content: "\f061";
  font-weight: 500;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: unset;
  font-size: 1em;
  height: unset;
}
@media (max-width: 767px) {
  .footer-widget.style2 .widget_title {
    padding: 0 0 16px 0;
  }
  .footer-widget.style2.widget_meta a,
  .footer-widget.style2.widget_pages a,
  .footer-widget.style2.widget_archive a,
  .footer-widget.style2.widget_categories a,
  .footer-widget.style2.widget_nav_menu a {
    margin-bottom: 18px;
  }
}
@media (max-width: 350px) {
  .footer-widget .recent-post .media-img {
    margin-right: 15px;
    width: 100px;
  }
}
.as-header {
  position: relative;
  z-index: 41;
}
.as-header .icon-btn {
  --btn-size: 45px;
  border: 1px solid #d0dbe9;
}
.as-header .icon-btn:hover {
  border-color: var(--theme-color);
}
.as-header .as-btn {
  padding: 19px 29px;
}
.will-sticky .sticky-active {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
}
.will-sticky .sticky-active.active {
  top: 0;
}
.main-menu a {
  display: block;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  color: var(--title-color);
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Jost", sans-serif;
}
.main-menu a:hover {
  color: var(--theme-color);
}
.main-menu > ul > li {
  margin: 0 13px;
}
.main-menu > ul > li > a {
  padding: 41.5px 0;
  display: flex;
  align-items: center;
}
.main-menu > ul > li > a svg {
  margin-left: 5px;
}
.main-menu ul {
  margin: 0;
  padding: 0;
}
.main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}
/* .main-menu ul li.menu-item-has-children > a:after {
  content: "\f107";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 5px;
  top: 1px;
} */
.main-menu ul li:last-child {
  margin-right: 0 !important;
}
.main-menu ul li:first-child {
  margin-left: 0 !important;
}
.main-menu ul li:hover > ul.sub-menu,
.main-menu ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}
.main-menu ul.sub-menu,
.main-menu ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 7px;
  left: -14px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: margin-top 0.4s ease-in-out 0s,
    visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s,
    opacity 0.4s ease-in-out 0s, z-index 0s;
}
.main-menu ul.sub-menu a,
.main-menu ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
}
.main-menu ul.sub-menu {
  padding: 18px 20px;
  left: -27px;
}
.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 0px 9px;
}
.main-menu ul.sub-menu li.menu-item-has-children > a:after {
  content: "\f105";
  float: right;
  top: 1px;
}
.main-menu ul.sub-menu li a {
  position: relative;
  text-transform: capitalize;
}
/* .main-menu ul.sub-menu li a:before {
  content: "\f02d";
  position: absolute;
  top: 8px;
  left: 0;
  font-family: var(--icon-font);
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1;
  color: var(--theme-color);
  font-weight: 700;
} */
.main-menu ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}
.main-menu ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}
.main-menu .mega-menu-wrap {
  position: static;
}
.main-menu ul.mega-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.main-menu ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}
.main-menu ul.mega-menu li li {
  padding: 2px 0;
}
.main-menu ul.mega-menu li a {
  display: inline-block;
}
.main-menu ul.mega-menu > li > a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after,
.main-menu ul.mega-menu > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after {
  width: calc(100% - 20px);
  left: 20px;
}
.main-menu ul.mega-menu > li > a:hover {
  padding-left: 0;
}
.category-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 25px 30px;
  left: 0;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: margin-top 0.4s ease-in-out 0s,
    visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s,
    opacity 0.4s ease-in-out 0s, z-index 0s;
}
.category-menu ul {
  padding: 0;
  margin-bottom: 0;
}
.category-menu li {
  list-style: none;
  margin-bottom: 6px;
}
.category-menu li:last-child {
  margin-bottom: 0;
}
.category-menu a {
  font-weight: 500;
  text-transform: capitalize;
  color: var(--title-color);
  position: relative;
  padding-left: 23px;
}
.category-menu a:before {
  content: "\f07c";
  position: absolute;
  top: 4px;
  left: 0;
  font-family: var(--icon-font);
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1;
  color: var(--theme-color);
  font-weight: 400;
}
.category-menu a:hover {
  color: var(--theme-color);
}
.category-menu-wrap {
  position: relative;
  height: 100%;
  padding: 42px 0;
}
.category-menu-wrap:hover .category-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}
.menu-expand {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--theme-color);
  background-color: #eef3f9;
  padding: 12.5px 24px;
  border-radius: 5px;
}
.menu-expand svg {
  margin-right: 10px;
}
.simple-icon {
  border: none;
  background-color: transparent;
  padding: 0;
  color: var(--body-color);
}
.simple-icon:hover {
  color: var(--theme-color);
}
.header-button {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  margin-left: 15px;
}
.header-button .icon-btn {
  position: relative;
}
.header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-links li {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 400;
}
.header-links li:not(:last-child) {
  padding: 0 20px 0 0;
  margin: 0 17px 0 0;
}
.header-links li:not(:last-child):before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 16px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header-links li > svg {
  margin-right: 10px;
}
.header-links li,
.header-links span,
.header-links p,
.header-links a {
  font-family: var(--body-font);
  color: var(--body-color);
}
.header-links svg {
  color: var(--body-color);
}
.header-links b,
.header-links strong {
  font-weight: 600;
}
.header-social .social-title {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
}
.header-social a {
  font-size: 14px;
  display: inline-block;
  color: var(--body-color);
  margin: 0 15px 0 0;
}
.header-social a:last-child {
  margin-right: 0;
}
.header-social a:hover {
  color: var(--theme-color);
}
.header-logo {
  padding-top: 10px;
  padding-bottom: 10px;
}
.header-notice {
  margin-bottom: 0;
  display: inline-block;
}
.counter-list {
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 18px;
  background-color: var(--white-color);
  padding: 2px 15px;
  border-radius: 999px;
  margin-left: 15px;
}
.counter-list li {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 4px;
  color: #00306e;
  position: relative;
}
.counter-list li:after {
  content: ":";
  position: absolute;
  top: 50%;
  right: -11.5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #00306e;
  font-weight: 600;
}
.counter-list li:last-child::after {
  display: none;
}
.counter-list .count-number,
.counter-list .count-name {
  color: #00306e;
  font-weight: 500;
}
.header-layout1 .header-top {
  --body-color: #fff;
  background-color: var(--theme-color);
  padding: 9px 0;
}
.header-layout1 .header-top a:hover {
  color: #d5d5d5;
}
.header-layout1 .menu-area {
  background-color: var(--white-color);
}
@media (max-width: 767px) {
  .header-search {
    margin: 0 0 20px 0;
  }
}
.header-layout2 .header-top {
  background-color: #00306e;
  padding: 11.5px 0;
  --body-color: #fff;
}
.header-layout2 .header-top a,
.header-layout2 .header-top li,
.header-layout2 .header-top p {
  color: var(--body-color);
}
.header-layout2 .header-top a:hover {
  color: var(--theme-color);
}
.header-layout2 .header-top li:before {
  background-color: #3569ac;
}
.header-layout2 .header-button {
  padding-left: 30px;
  margin-left: 6px;
  position: relative;
}
.header-layout2 .header-button:before {
  content: "";
  height: 30px;
  width: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #d9d9d9;
}
.header-layout2 .icon-btn {
  --icon-bg: #f5f5f5;
}
.header-layout2 .as-menu-toggle {
  margin: 20px 0;
}
@media (max-width: 575px) {
  .header-layout2 .as-menu-toggle,
  .header-layout3 .as-menu-toggle {
    margin: 15px 0;
  }
}
@media (max-width: 375px) {
  .header-layout2 .header-social a,
  .header-layout3 .header-social a {
    margin: 0 10px 0 0;
  }
  .header-layout2 .header-links li,
  .header-layout3 .header-links li {
    font-size: 14px;
  }
}
.header-layout3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-layout3 .header-top {
  background-color: #00306e;
  padding: 9px 0;
  --body-color: #fff;
}
.header-layout3 .header-top a,
.header-layout3 .header-top li,
.header-layout3 .header-top p,
.header-layout3 .header-top span,
.header-layout3 .header-top div {
  color: var(--body-color);
  font-family: var(--title-font);
}
.header-layout3 .header-top .count-number,
.header-layout3 .header-top .count-name {
  font-size: 15px;
  color: #00306e;
}
.header-layout3 .header-top a:hover {
  color: var(--theme-color);
}
.header-layout3 .header-top li:before {
  background-color: #3569ac;
}
.header-layout3 .as-menu-toggle {
  margin: 20px 0;
}
.header-layout3 .menu-area {
  background-color: var(--white-color);
  padding-left: 40px;
  border-radius: 0 0 0 10px;
}
.header-layout3 .sticky-wrapper {
  position: relative;
}
.header-layout3 .sticky-wrapper:after {
  content: "";
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--white-color);
  z-index: -1;
}
@media (max-width: 1199px) {
  .header-layout3 .header-notice {
    font-size: 14px;
  }
  .header-layout3 .menu-area {
    padding-left: 0;
  }
  .header-layout3 .sticky-wrapper {
    background-color: var(--white-color);
  }
  .header-layout3 .sticky-wrapper::after {
    display: none;
  }
}
@media (min-width: 1700px) {
  .header-layout4 {
    --main-container: 1620px;
  }
}
@media (max-width: 1699px) {
  .header-layout4 .category-menu-wrap {
    display: none;
  }
}
.footer-wrapper {
  position: relative;
  z-index: 2;
}
.footer-wrapper .newsletter-title {
  color: var(--white-color);
}
.footer-wrapper .as-social a {
    background-color: #1e2c46;
    color: var(--white-color) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}
.footer-wrapper .as-social a:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.widget-area {
  padding-top: var(--section-space);
  padding-bottom: 0px;
}
.copyright-wrap {
  padding: 29px 0;
}
.copyright-text {
  margin: 0;
}
.copyright-text a {
  color: inherit;
}
.copyright-text a:hover {
  color: var(--white-color);
}
@media (max-width: 1399px) {
  .as-social a {
    margin-right: 3px;
  }
  .as-social a:last-child {
    margin-right: 0;
  }
}
.footer-links ul {
  padding: 0;
  margin: 0;
}
.footer-links li {
  font-family: var(--body-font);
  display: inline-block;
  margin-right: 20px;
}
.footer-links li:last-child {
  margin-right: 0;
}
.footer-links a {
  font-family: inherit;
  color: var(--body-color);
}
.footer-links a:hover {
  color: var(--white-color);
}
.footer-layout1 {
  background-color: #0f1b31;
  --body-color: #b3c1d3;
  overflow: hidden;
}
.footer-layout1 .copyright-wrap {
  padding: 22px 0;
  border-top: 1px solid #283752;
}
@media (max-width: 1199px) {
  .footer-layout1 .copyright-wrap {
    padding: 25px 0;
  }
}
@media (max-width: 991px) {
  .footer-layout1 .widget-area {
    padding-top: var(--section-space-mobile);
    padding-bottom: 38px;
  }
  .copyright-text {
    text-align: center;
  }
}
.footer-layout2 {
  background-color: #0f1b31;
  --body-color: #b3c1d3;
  overflow-x: hidden;
}
.footer-layout2 .widget-area {
  padding-top: 100px;
  padding-bottom: 55px;
}
.footer-layout2 .copyright-wrap {
  padding: 26px 0;
  background-color: var(--theme-color);
}
.footer-layout2 .copyright-text {
  text-align: center;
  color: var(--white-color);
}
.footer-layout2 .footer-links a {
  color: var(--white-color);
}
.footer-layout2 .as-widget-about .about-logo {
  margin-top: 0;
}
.footer-layout2 .scroll-btn {
  position: absolute;
  right: 50%;
  bottom: 53px;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
.footer-layout2 .scroll-btn:before {
  background-color: #0f1b31;
}
.footer-layout2 .scroll-btn:hover svg {
  background-color: var(--white-color);
  color: var(--theme-color);
}
@media (max-width: 991px) {
  .footer-layout2 .widget-area {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .footer-layout2 .scroll-btn {
    bottom: 62px;
  }
}
@media (max-width: 575px) {
  .footer-layout2 .scroll-btn {
    bottom: 87px;
  }
}
.footer-layout3 {
  --body-color: #fff;
  --border-color: #224d9c;
}
.footer-layout3 .widget-area {
  padding-top: 100px;
  padding-bottom: 55px;
  padding-left: 66px;
}
.footer-layout3 .footer-top {
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid var(--border-color);
}
.footer-layout3 .copyright-wrap {
  padding: 22px 0;
  border-top: 1px solid var(--border-color);
}
.footer-layout3 .copyright-text {
  text-align: center;
  color: var(--white-color);
}
.footer-layout3 .footer-links a {
  color: var(--white-color);
}
.footer-layout3 .footer-links a:hover {
  color: var(--theme-color);
}
.footer-layout3 .as-social a {
  --icon-size: 40px;
  font-size: 13px;
  background-color: #193f85;
  border: 1px solid var(--border-color);
  margin-right: 1px;
}
.footer-layout3 .footer-info-title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.footer-layout3 .widget_meta a:hover,
.footer-layout3 .widget_pages a:hover,
.footer-layout3 .widget_archive a:hover,
.footer-layout3 .widget_categories a:hover,
.footer-layout3 .widget_nav_menu a:hover {
  color: var(--theme-color);
}
.footer-layout3 .widget_meta a:hover:before,
.footer-layout3 .widget_pages a:hover:before,
.footer-layout3 .widget_archive a:hover:before,
.footer-layout3 .widget_categories a:hover:before,
.footer-layout3 .widget_nav_menu a:hover:before {
  color: var(--theme-color);
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}
.about-area {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-right: 90px;
  border-right: 1px solid var(--border-color);
}
.info-box {
  display: inline-block;
}
.info-box-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 45px;
}
.info-box_text {
  display: block;
  color: var(--white-color);
  margin-top: -0.45em;
  margin-bottom: 5px;
}
.info-box_link {
  font-size: 22px;
  font-weight: 600;
  color: var(--white-color);
}
.info-box_link svg {
  margin-right: 8px;
}
.info-box_link:hover {
  color: var(--theme-color);
}
.download-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  background-color: #193f85;
  border: 1px solid var(--border-color);
  padding: 13px 33px;
  border-radius: 999px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.download-btn svg {
  font-size: 30px;
  color: var(--white-color);
}
.download-btn .text {
  color: #85a4dd;
  font-size: 12px;
  line-height: 1;
  display: block;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.download-btn .store-name {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 500;
  display: block;
  margin-bottom: -0.3em;
}
.download-btn:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.download-btn:hover .text {
  color: var(--white-color);
}
.newsletter-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 10px 20px;
}
.newsletter-wrap .newsletter-title {
  margin-bottom: 0;
  max-width: 285px;
}
.newsletter-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 680px;
}
.newsletter-form .form-group {
  margin-bottom: 0;
  width: 100%;
}
.newsletter-form .form-group > svg {
  top: 19px;
  color: var(--white-color);
}
.newsletter-form input {
  background-color: #052a6f;
  border: 1px solid #2a4f93;
  margin-bottom: 0;
  height: 55px;
  color: var(--white-color);
  border-radius: 999px;
}
.newsletter-form input::-moz-placeholder {
  color: var(--white-color);
}
.newsletter-form input::-webkit-input-placeholder {
  color: var(--white-color);
}
.newsletter-form input:-ms-input-placeholder {
  color: var(--white-color);
}
.newsletter-form input::-ms-input-placeholder {
  color: var(--white-color);
}
.newsletter-form input::placeholder {
  color: var(--white-color);
}
.newsletter-form input:focus {
  border-color: var(--theme-color);
  background-color: #052a6f;
}
.newsletter-form .as-btn {
  min-width: 200px;
  border-radius: 999px;
  box-shadow: none;
  margin-left: -50px;
}
@media (max-width: 1399px) {
  .download-btn {
    padding: 10px 26px;
  }
}
@media (max-width: 1199px) {
  .footer-top .footer-logo {
    text-align: center;
    margin-bottom: 20px;
  }
  .about-area {
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .info-box-wrap {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 25px 60px;
  }
  .footer-layout3 .widget-area {
    padding-top: 80px;
    padding-bottom: 43px;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .newsletter-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .newsletter-wrap .newsletter-title {
    text-align: center;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .newsletter-form {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
  }
  .newsletter-form .as-btn {
    margin-left: 0;
  }
}
.footer-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-top: 22px;
}
.footer-social a {
  color: var(--white-color);
}
.footer-social a:hover {
  color: var(--theme-color);
}
.footer-layout4 {
  --body-color: #fff;
  --border-color: #254684;
  background-color: #0d5ef4;
}
.footer-layout4 .widget-area {
  padding-top: 120px;
  padding-bottom: 80px;
}
.footer-layout4 .footer-widget.style2 .widget_title {
  font-size: 20px;
}
.footer-layout4 .copyright-wrap {
  border-top: 1px solid var(--border-color);
}
.footer-layout4 .copyright-text {
  color: var(--white-color);
}
.footer-layout4 .widget_meta a:hover,
.footer-layout4 .widget_pages a:hover,
.footer-layout4 .widget_archive a:hover,
.footer-layout4 .widget_categories a:hover,
.footer-layout4 .widget_nav_menu a:hover {
  color: var(--theme-color);
}
.footer-layout4 .widget_meta a:hover:before,
.footer-layout4 .widget_pages a:hover:before,
.footer-layout4 .widget_archive a:hover:before,
.footer-layout4 .widget_categories a:hover:before,
.footer-layout4 .widget_nav_menu a:hover:before {
  color: var(--theme-color);
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}
@media (max-width: 991px) {
  .footer-layout4 .widget-area {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.breadcumb-menu {
  max-width: 100%;
  padding: 0;
  margin: 23px 0 -0.5em 0;
  list-style-type: none;
  position: relative;
  text-shadow: #000000 2px 2px 4px;
}
.breadcumb-menu li {
  display: inline-block;
  margin-right: 3px;
  padding-right: 5px;
  list-style: none;
  position: relative;
}
/* .breadcumb-menu li:after {
  content: "\f054";
  position: relative;
  margin-left: 10px;
  font-weight: 600;
  font-size: 15px;
  color: var(--white-color);
  font-family: var(--icon-font);
} */
.breadcumb-menu li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.breadcumb-menu li:last-child:after {
  display: none;
}
.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  color: inherit;
  word-break: break-word;
  font-weight: 400;
  font-size: 18px;
  color: var(--white-color);
}
.breadcumb-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.18;
  text-transform: uppercase;
  color: var(--white-color);
  margin: -0.2em 0 -0.2em 0;
  text-shadow: #000000 2px 2px 4px;
}
.breadcumb-wrapper {
    background-color: var(--title-color);
    padding: 10px 0;
    text-align: center;
    overflow: hidden;
    height: 100vh;
    display: flex;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
}
@media (max-width: 1199px) {
  .breadcumb-title {
    font-size: 42px;
  }
  .breadcumb-wrapper {
    padding: 120px 0;
  }
}
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding: 100px 0;
  }
  .breadcumb-menu {
    margin: 16px 0 -0.5em 0;
  }
  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 16px;
  }
  .breadcumb-title {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .course_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap {
    height: 900px !important;
  }
  .breadcumb-content.text-center {
    margin-bottom: 50px;
}
  .breadcumb-wrapper {
    padding: 0px 0;
  }
  .breadcumb-title {
    font-size: 26px;
  }
}
.as-pagination {
  margin-bottom: 30px;
}
.as-pagination ul {
  margin: 0;
  padding: 0;
}
.as-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}
.as-pagination li:last-child {
  margin-right: 0;
}
.as-pagination li:first-child {
  margin-left: 0;
}
.as-pagination span,
.as-pagination a {
  display: inline-block;
  text-align: center;
  position: relative;
  border: none;
  color: var(--title-color);
  background-color: var(--smoke-color);
  width: 55px;
  height: 55px;
  line-height: 55px;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}
.as-pagination span.active,
.as-pagination span:hover,
.as-pagination a.active,
.as-pagination a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  box-shadow: none;
}
@media (max-width: 767px) {
  .as-pagination span,
  .as-pagination a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
blockquote,
.wp-block-quote {
  font-size: 20px;
  line-height: 1.56;
  padding: 40px 40px 40px 40px;
  font-weight: 400;
  display: block;
  position: relative;
  background-color: var(--smoke-color);
  overflow: hidden;
  margin: 35px 0;
  color: var(--title-color);
  font-style: italic;
  border-left: 3px solid var(--theme-color);
  border-radius: 10px;
}
blockquote p,
.wp-block-quote p {
  font-size: inherit;
  font-family: inherit;
  margin-top: -0.3em;
  margin-bottom: 16px;
  line-height: inherit;
  color: inherit;
  width: 100%;
  position: relative;
  z-index: 3;
}
blockquote p a,
.wp-block-quote p a {
  color: inherit;
}
blockquote:before,
.wp-block-quote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  right: 40px;
  bottom: 40px;
  font-size: 6rem;
  font-weight: 600;
  opacity: 1;
  line-height: 3.8rem;
  color: rgba(13, 94, 244, 0.1);
  font-style: normal;
}
blockquote cite,
.wp-block-quote cite {
  display: block;
  font-size: 22px;
  position: relative;
  border-color: inherit;
  line-height: 1;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: -0.1em;
  font-style: normal;
  font-family: var(--title-font);
  padding-left: 50px;
  margin-left: 10px;
}
blockquote cite:before,
.wp-block-quote cite:before {
  content: "";
  height: 2px;
  width: 40px;
  background-color: var(--theme-color);
  position: absolute;
  bottom: 9px;
  left: -10px;
}
blockquote.style-left-icon,
blockquote.is-large,
blockquote.is-style-large,
blockquote.has-text-align-right,
.wp-block-quote.style-left-icon,
.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
.wp-block-quote.has-text-align-right {
  padding: 40px;
}
blockquote.style-left-icon,
.wp-block-quote.style-left-icon {
  font-size: 18px;
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.556;
  background-color: var(--smoke-color);
  padding-left: 160px;
}
blockquote.style-left-icon:before,
.wp-block-quote.style-left-icon:before {
  right: unset;
  left: 56px;
  top: 60px;
  font-size: 6rem;
  font-weight: 400;
  line-height: 4rem;
  color: var(--theme-color);
  text-shadow: none;
}
blockquote.style-left-icon cite,
.wp-block-quote.style-left-icon cite {
  color: var(--title-color);
}
blockquote.style-left-icon cite:before,
.wp-block-quote.style-left-icon cite:before {
  background-color: var(--title-color);
  top: 8px;
}
blockquote.is-large cite:before,
blockquote.is-style-large cite:before,
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before {
  top: unset;
  bottom: 13px;
}
blockquote.has-text-align-right:before,
.wp-block-quote.has-text-align-right:before {
  content: "\f10d";
  right: unset;
  left: 60px;
}
.wp-block-pullquote {
  color: var(--white-color);
  padding: 0;
}
blockquote.has-very-dark-gray-color {
  color: var(--white-color) !important;
}
.wp-block-column blockquote,
.wp-block-column .wp-block-quote {
  padding: 100px 15px 30px 15px;
}
.wp-block-column blockquote:before,
.wp-block-column .wp-block-quote:before {
  width: 100%;
  height: 60px;
  font-size: 30px;
}
.wp-block-column blockquote.style-left-icon,
.wp-block-column blockquote.is-large:not(.is-style-plain),
.wp-block-column blockquote.is-style-large:not(.is-style-plain),
.wp-block-column blockquote.has-text-align-right,
.wp-block-column .wp-block-quote.style-left-icon,
.wp-block-column .wp-block-quote.is-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.has-text-align-right {
  padding: 100px 15px 30px 15px;
}
.blog-meta span,
.blog-meta a {
  display: inline-block;
  margin-right: 15px;
  padding-right: 20px;
  font-size: 16px;
  color: var(--body-color);
  position: relative;
}
.blog-meta span:after,
.blog-meta a:after {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #d3dbeb;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
  padding-right: 0;
}
.blog-meta span:last-child:after,
.blog-meta a:last-child:after {
  display: none;
}
.blog-meta span i,
.blog-meta a svg {
  margin-right: 10px;
  color: var(--theme-color);
}
.blog-meta span img,
.blog-meta a img {
  margin-right: 10px;
}
.blog-meta a:hover {
  color: var(--theme-color);
}
.blog-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px dashed #d3dbeb;
  padding-top: 26px;
  margin-bottom: -0.4em;
}
.blog-bottom span:not(.link-btn),
.blog-bottom a:not(.link-btn) {
  color: var(--body-color);
}
.blog-bottom span:not(.link-btn) i,
.blog-bottom a:not(.link-btn) svg {
  margin-right: 8px;
  color: var(--theme-color);
}
.blog-bottom a:hover {
  color: var(--theme-color);
}
.blog-audio img,
.blog-img img,
.blog-video img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-title a {
  color: inherit;
}
.blog-title a:hover {
  color: var(--theme-color);
}
.as-blog {
  margin-bottom: 30px;
}
.blog-inner-title {
  font-size: 36px;
  margin-top: -0.3em;
  margin-bottom: 30px;
}
.blog-author,
.course-author-box {
  margin-top: 40px;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--smoke-color);
  border-radius: 8px;
  overflow: hidden;
}
.blog-author .auhtor-img,
.course-author-box .auhtor-img {
  min-height: 100%;
}
.blog-author .auhtor-img img,
.course-author-box .auhtor-img img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.blog-author .author-name,
.course-author-box .author-name {
  font-size: 24px;
  margin-top: -0.2em;
  margin-bottom: 10px;
}
.blog-author .author-text,
.course-author-box .author-text {
  margin-bottom: 15px;
}
.blog-author .media-body,
.course-author-box .media-body {
  padding: 35px 40px 30px 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.blog-author .as-social a,
.course-author-box .as-social a {
  border-radius: 6px;
  --icon-size: 36px;
  font-size: 13px;
}
.blog-single {
  position: relative;
  margin-bottom: var(--blog-space-y, 40px);
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
}
.blog-single:not(.has-post-thumbnail) {
  background-color: var(--smoke-color);
}
/* .blog-single .blog-title {
  margin-bottom: 13px;
  font-size: 36px;
  font-weight: 600;
} */
.blog-single .blog-title {
  margin-bottom: 13px;
  font-size: 22px;
  font-weight: 600;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blog-single .blog-text {
  margin-bottom: 29px;
}
.blog-single .social-links {
  margin: 0;
  padding: 5px 20px;
  list-style-type: none;
  display: inline-block;
  background-color: var(--smoke-color);
  border-radius: 999px;
}
.blog-single .social-links li {
  display: inline-block;
  margin-right: 13px;
}
.blog-single .social-links li:last-child {
  margin-right: 0;
}
.blog-single .social-links a {
  line-height: 1;
  font-size: 16px;
  color: var(--title-color);
  text-align: center;
  display: block;
}
.blog-single .social-links a:hover {
  color: var(--theme-color);
}
.blog-single .blog-meta {
  margin: 0 0 18px 0;
}
.blog-content .blog-meta svg {
  margin-right: 8px;
  font-size: 18px;
}
.blog-single .blog-content {
  margin: 0 0 0 0;
  padding: 0;
  position: relative;
}
.blog-single .blog-audio {
  line-height: 1;
}
.blog-single .blog-audio,
.blog-single .blog-img,
.blog-single .blog-video {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: var(--blog-space-y, 40px);
  background-color: var(--smoke-color);
}
.blog-single .blog-img .slick-arrow {
  --pos-x: 50px;
  border: none;
  background-color: var(--white-color);
  color: var(--theme-color);
}
.blog-single .blog-img .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.blog-single .blog-img .play-btn {
  --icon-size: 60px;
  --icon-font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}
.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}
.share-links-title {
  font-size: 20px;
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 700;
  margin: 0 15px 0 0;
  display: inline-block;
}
.share-links {
  margin: 20px 0 40px 0;
  border-top: 1px dashed #d3dbeb;
  border-bottom: 1px dashed #d3dbeb;
  padding: 30px 0;
}
.share-links > .row {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  --bs-gutter-y: 20px;
}
.share-links .tagcloud {
  display: inline-block;
}
.blog-details .blog-single {
  background-color: transparent;
      position: relative;
}
@media (max-width: 1399px) {
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 40px;
  }
  .blog-single .blog-audio,
  .blog-single .blog-img {
    margin: 0 0 30px 0;
  }
  .blog-single .blog-title {
    font-size: 30px;
  }
}
@media (max-width: 1199px) {
  blockquote,
  .wp-block-quote {
    font-size: 18px;
    padding: 40px 20px 40px 20px;
  }
  blockquote:before,
  .wp-block-quote:before {
    font-size: 4rem;
    line-height: 2.5rem;
  }
  blockquote.style-left-icon,
  blockquote.is-large:not(.is-style-plain),
  blockquote.is-style-large:not(.is-style-plain),
  blockquote.has-text-align-right,
  .wp-block-quote.style-left-icon,
  .wp-block-quote.is-large:not(.is-style-plain),
  .wp-block-quote.is-style-large:not(.is-style-plain),
  .wp-block-quote.has-text-align-right {
    padding: 40px 20px 40px 20px;
  }
  .blog-details .blog-single {
    --blog-space-x: 20px;
    --blog-space-y: 20px;
  }
}
@media (max-width: 991px) {
  .blog-details .blog-single {
    --blog-space-x: 40px;
    --blog-space-y: 40px;
  }
}
@media (max-width: 767px) {
  .wp-block-pullquote.is-style-solid-color blockquote {
    max-width: 90%;
  }
  .blog-inner-title {
    font-size: 26px;
  }
  .blog-author,
  .course-author-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .blog-author .auhtor-img img,
  .course-author-box .auhtor-img img {
    width: 100%;
  }
  .blog-details .blog-single {
    --blog-space-x: 20px;
    --blog-space-y: 20px;
  }
  .blog-single {
    --blog-space-y: 20px;
    --blog-space-x: 20px;
  }
  .blog-single .blog-title {
    font-size: 24px;
    line-height: 1.3;
  }
  .blog-single .blog-text {
    margin-bottom: 15px;
  }
  .blog-single .blog-bottom {
    padding-top: 15px;
  }
  .blog-single .share-links-title {
    font-size: 18px;
    display: block;
    margin: 0 0 10px 0;
  }
}
@media (max-width: 575px) {
  .blog-author .media-body,
  .course-author-box .media-body {
    padding: 35px 20px 30px 20px;
  }
  .blog-meta a,
  .blog-meta span {
    padding-right: 0;
  }
  .blog-meta a::after,
  .blog-meta span::after {
    display: none;
  }
}
.as-comment-form {
  margin-top: var(--blog-space-y, 60px);
  margin-bottom: 30px;
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
  position: relative;
}
.as-comment-form .row {
  --bs-gutter-x: 20px;
}
.as-comment-form .blog-inner-title {
  margin-bottom: 0px;
}
.as-comment-form .form-title {
  margin-top: -0.35em;
}
.as-comment-form .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}
.as-comment-form .form-text {
  margin-bottom: 25px;
}
.blog-comment-area {
  margin-bottom: 25px;
}
.as-comments-wrap {
  margin-top: var(--blog-space-y, 50px);
  margin-bottom: -30px;
}
.as-comments-wrap .description p:last-child {
  margin-bottom: -0.5em;
}
.as-comments-wrap .comment-respond {
  margin: 30px 0;
}
.as-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.as-comments-wrap li {
  margin: 0;
}
.as-comments-wrap .as-post-comment {
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  border-bottom: 1px dashed #d3dbeb;
}
.as-comments-wrap .as-post-comment ol,
.as-comments-wrap .as-post-comment ul,
.as-comments-wrap .as-post-comment dl {
  margin-bottom: 1rem;
}
.as-comments-wrap .as-post-comment ol ol,
.as-comments-wrap .as-post-comment ol ul,
.as-comments-wrap .as-post-comment ul ol,
.as-comments-wrap .as-post-comment ul ul {
  margin-bottom: 0;
}
.as-comments-wrap ul.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.as-comments-wrap ul.comment-list ul ul,
.as-comments-wrap ul.comment-list ul ol,
.as-comments-wrap ul.comment-list ol ul,
.as-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}
.as-comments-wrap .comment-avater {
  width: 110px;
  height: 110px;
  margin-right: 25px;
  overflow: hidden;
  border-radius: 5px;
}
.as-comments-wrap .comment-avater img {
  width: 100%;
}
.as-comments-wrap .comment-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: -6px;
  position: relative;
}
.as-comments-wrap .commented-on {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 400;
  color: var(--body-color);
}
.as-comments-wrap .commented-on svg {
  margin-right: 7px;
  font-size: 0.9rem;
}
.as-comments-wrap .name {
  margin-bottom: 6px;
  font-size: 20px;
}
.as-comments-wrap .comment-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.as-comments-wrap .text {
  margin-bottom: -0.5em;
}
.as-comments-wrap .text:last-child {
  margin-bottom: 0;
}
.as-comments-wrap .comment-content p:last-of-type {
  margin-bottom: -0.5em;
}
.as-comments-wrap .children {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 80px;
}
.as-comments-wrap .reply_and_edit {
  position: absolute;
  top: 0;
  right: 0;
}
.as-comments-wrap .reply_and_edit a {
  margin-right: 10px;
}
.as-comments-wrap .reply_and_edit a:last-child {
  margin-right: 0;
}
.as-comments-wrap .reply-btn {
  font-weight: 600;
  font-size: 16px;
  color: var(--theme-color);
  display: inline-block;
}
.as-comments-wrap .reply-btn svg {
  margin-right: 7px;
}
.as-comments-wrap .reply-btn:hover {
  color: var(--title-color);
}
.as-comments-wrap .star-rating {
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  top: 5px;
  right: 0;
  width: 80px;
}
ul.comment-list .as-comment-item:last-child > .as-post-comment {
  border-bottom: none;
  padding-bottom: 0;
}
ul.comment-list .as-comment-item:first-child > .as-post-comment {
  padding-bottom: 30px;
  border-bottom: 1px dashed #d3dbeb;
}
.as-comments-wrap.as-comment-form {
  margin: 0;
}
@media (max-width: 1199px) {
  .as-comments-wrap .children {
    margin-left: 40px;
  }
}
@media (max-width: 767px) {
  .as-comments-wrap .as-post-comment {
    display: block;
  }
  .as-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }
  .as-comments-wrap .comment-top {
    display: block;
  }
  .as-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .as-comments-wrap .children {
    margin-left: 40px;
  }
  .as-comments-wrap .children {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .as-comment-form {
    --blog-space-x: 20px;
  }
}
.as-hero-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.as-hero-wrapper .checklist ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 30px;
}
.as-hero-wrapper .checklist li {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-size: 18px;
  font-weight: 500;
  color: var(--title-color);
}
.hero-shape {
  position: absolute;
}
@media (max-width: 767px) {
  .as-hero-wrapper .checklist ul {
    gap: 10px 25px;
  }
  .as-hero-wrapper .checklist li {
    font-size: 16px;
  }
}
.ripple-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.ripple-1,
.ripple-2,
.ripple-3,
.ripple-4,
.ripple-5,
.ripple-6 {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  border: 2px solid var(--white-color);
  border-radius: 50%;
  -webkit-transform: translate3d(-25%, 25%, 0);
  transform: translate3d(-25%, 25%, 0);
  -webkit-animation: heroripple 8s ease-out infinite;
  animation: heroripple 8s ease-out infinite;
}
.ripple-1 {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.ripple-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.ripple-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.ripple-4 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.ripple-4 {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.ripple-5 {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.ripple-6 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-webkit-keyframes heroripple {
  0% {
    -webkit-transform: translate3d(-25%, 25%, 0);
    transform: translate3d(-25%, 25%, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translate3d(-25%, 25%, 0);
    transform: translate3d(-25%, 25%, 0);
    opacity: 0;
    width: 310px;
    height: 310px;
  }
}
@keyframes heroripple {
  0% {
    -webkit-transform: translate3d(-25%, 25%, 0);
    transform: translate3d(-25%, 25%, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translate3d(-25%, 25%, 0);
    transform: translate3d(-25%, 25%, 0);
    opacity: 0;
    width: 310px;
    height: 310px;
  }
}
.as-hero-bg {
  position: absolute;
  inset: 0;
}
.as-hero-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hero-subtitle {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.hero-text {
  font-size: 18px;
  margin-bottom: 39px;
}
.hero-1 .shape1 {
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  max-width: 60%;
}
.hero-1 .shape1 img {
  height: 100%;
  opacity: 0.5;
}
.hero-1 .shape2 {
  right: 0;
  top: 22%;
  z-index: 2;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
.hero-1 .shape3 {
  top: -420px;
  left: -330px;
  width: 619.52px;
  height: 619.52px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 100px;
  -webkit-transform: rotate(25.36deg);
  -ms-transform: rotate(25.36deg);
  transform: rotate(25.36deg);
  z-index: 2;
  -webkit-animation: spin 40s linear infinite;
  animation: spin 40s linear infinite;
}
.hero-1 .as-social {
  position: absolute;
  z-index: 3;
  top: 0%;
  height: 100%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero-1 .as-social a {
  background-color: var(--title-color);
  color: #b3c1d3;
}
.hero-1 .as-social a:hover {
  color: var(--white-color);
}
.hero-style1 {
  position: relative;
  z-index: 6;
  /* padding: 210px 0 210px 0; */
  max-width: 620px;
  display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.hero-style1 .hero-title,
.hero-style1 .hero-text {
  color: var(--theme-color);
}
.hero-style1 .hero-subtitle {
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 5px 17px;
  margin-bottom: 30px;
}
.hero-style1 .hero-title {
  margin-bottom: 27px;
  font-weight: 600;
}
.hero-style1 .hero-title:first-of-type {
  margin-bottom: 20px !important;
}
.hero-style1 .hero-title:nth-of-type(2) {
  font-weight: 200;
}
.hero-1 .hero-img1 {
  position: absolute;
  bottom: 0;
  right: -25px;
}
.hero-1 .hero-img1 img {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.hero-1 .hero-img1:before {
  content: "";
  box-sizing: border-box;
  width: 385px;
  height: 385px;
  background: rgba(255, 255, 255, 0.34);
  border: 12px solid var(--theme-color);
  box-shadow: 0px 4px 30px rgba(29, 94, 172, 0.2);
  border-radius: 50%;
  position: absolute;
  top: -200px;
  left: 30%;
  z-index: -1;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
@media (max-width: 1399px) {
  .hero-style1 {
    /* padding: 160px 0; */
  }
  .hero-1 .hero-img1 {
    max-width: 55%;
  }
  .hero-1 .hero-img1:before {
    top: -100px;
    width: 200px;
    height: 200px;
  }
  .hero-1 .hero-img1 img {
    max-width: 80%;
  }
}
@media (max-width: 1199px) {
  .hero-style1 {
    /* padding: 100px 0 50px; */
  }
  .hero-style1 .hero-subtitle {
    margin-bottom: 22px;
  }
  .hero-style1 .hero-title {
    margin-bottom: 20px;
  }
  .hero-text {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .hero-style1 {
    /* max-width: 450px; */
  }
  .hero-1 .shape2 {
    max-width: 20%;
  }
  .hero-1 .shape1 {
    max-width: 40%;
  }
  .hero-1 .shape3 {
    top: -200px;
    left: -200px;
    width: 300px;
    height: 300px;
    border-radius: 30px;
    -webkit-animation: spin 25s linear infinite;
    animation: spin 25s linear infinite;
  }
  .hero-1 .as-social {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-1 .hero-img1 {
    display: none;
  }
  .hero-1 .shape1,
  .hero-1 .shape2 {
    display: none;
  }
}
@media (max-width: 375px) {
  .hero-subtitle {
    font-size: 12px;
    font-weight: 500;
  }
}
.hero-2 {
  background-color: #eaf2fd;
  overflow: hidden;
}
.hero-2 .big-circle {
  position: absolute;
  width: 1380px;
  height: 1380px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: -webkit-radial-gradient(
    50% 50%,
    50% 50%,
    rgba(255, 255, 255, 0.04) 0%,
    #d6e6fc 100%
  );
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.04) 0%,
    #d6e6fc 100%
  );
}
.hero-2 .shape1 {
  top: 75px;
  left: -55px;
  -webkit-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}
.hero-2 .shape2 {
  top: 15%;
  left: 10%;
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
}
.hero-2 .shape3 {
  top: 0%;
  right: 30%;
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.hero-2 .shape4 {
  bottom: 0%;
  left: 24%;
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.hero-2 .hero-img1 {
  position: absolute;
  bottom: 0;
  left: 5%;
  z-index: 2;
}
.hero-2 .hero-img1::before,
.hero-2 .hero-img1:after {
  content: "";
  position: absolute;
  z-index: -1;
}
.hero-2 .hero-img1:before {
  height: 520px;
  width: 320px;
  background-color: var(--white-color);
  bottom: 0;
  left: -60px;
  border-radius: 999px 999px 0 0;
  -webkit-animation: movingX 7s linear infinite;
  animation: movingX 7s linear infinite;
}
.hero-2 .hero-img1:after {
  height: 237px;
  width: 237px;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
  top: 21%;
  right: -35px;
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}
.hero-2 .hero-img2 {
  position: absolute;
  bottom: 0;
  right: 4%;
  z-index: 2;
}
.hero-2 .hero-img2 .shape {
  position: absolute;
  top: 30%;
  right: 10px;
  z-index: -1;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
.hero-style2 {
  padding: 243px 0;
  text-align: center;
}
.hero-style2 .checklist {
  margin-bottom: 50px;
}
.hero-style2 .checklist ul {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.hero-style2 .hero-title {
  font-weight: 600;
  margin-bottom: 27px;
}
.hero-style2 .big-title {
  font-size: 80px;
  color: var(--theme-color);
  margin-top: -0.2em;
  margin-bottom: 14px;
}
.hero-style2 .btn-group {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 1500px) {
  .hero-2 .hero-img2 {
    max-width: 480px;
  }
  .hero-2 .hero-img1 {
    max-width: 358px;
  }
}
@media (max-width: 1399px) {
  .hero-2 .hero-img1 {
    max-width: 310px;
    left: 1%;
  }
  .hero-2 .hero-img1:before {
    height: 90%;
    width: 80%;
  }
  .hero-2 .hero-img1:after {
    height: 160px;
    width: 160px;
  }
  .hero-2 .hero-img2 {
    max-width: 390px;
    right: 0%;
  }
  .hero-2 .big-circle {
    width: 996px;
    height: 996px;
  }
  .hero-style2 {
    padding: 180px 0 220px 0;
    text-align: center;
  }
  .hero-style2 .big-title {
    font-size: 70px;
  }
}
@media (max-width: 991px) {
  .hero-2 .hero-img1 {
    max-width: 200px;
  }
  .hero-2 .hero-img2 {
    max-width: 250px;
  }
  .hero-2 .hero-img2 .shape {
    max-width: 100px;
  }
  .hero-2 .shape2 {
    top: 9%;
    max-width: 100px;
  }
  .hero-2 .big-circle {
    width: 750px;
    height: 750px;
  }
  .hero-style2 .hero-title {
    margin-bottom: 20px;
  }
  .hero-style2 .big-title {
    font-size: 54px;
    margin-bottom: 10px;
  }
  .hero-style2 .checklist {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .hero-2 .hero-img1 {
    max-width: 110px;
  }
  .hero-2 .hero-img2 {
    max-width: 100px;
  }
  .hero-2 .big-circle {
    width: 110%;
    height: 110%;
  }
  .hero-2 .shape1 {
    top: 40px;
    left: -40px;
    max-width: 100px;
  }
  .hero-2 .shape2 {
    top: 5%;
    max-width: 60px;
  }
  .hero-2 .shape3 {
    max-width: 150px;
  }
  .hero-2 .shape4 {
    left: 8%;
    max-width: 270px;
  }
  .hero-style2 {
    padding: 100px 0;
  }
  .hero-style2 .big-title {
    font-size: 44px;
  }
}
@media (max-width: 575px) {
  .hero-style2 .hero-title {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-style2 .big-title {
    font-size: 40px;
  }
}
.hero-3 {
  background-color: #f3f7fb;
  overflow: hidden;
}
.hero-3 .hero-img1 {
  position: absolute;
  top: 155px;
  right: 0;
  height: calc(100% - 155px);
  width: 47%;
}
.hero-3 .hero-img1 img {
  height: 100%;
  width: 100%;
}
.hero-3 .shape1 {
  top: 48px;
  left: 0;
}
.hero-3 .shape2 {
  bottom: 0;
  left: 0;
}
.hero-3 .shape3 {
  top: 115px;
  left: 31%;
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
}
.hero-3 .shape4 {
  bottom: 136px;
  right: 42%;
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.hero-3 .shape5 {
  bottom: 10%;
  right: 5%;
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}
.hero-3 .shape6 {
  top: 105px;
  left: 0;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
.hero-style3 {
  padding: 350px 0 200px 0;
  max-width: 640px;
}
.hero-style3 .hero-title {
  margin-bottom: 27px;
}
.hero-style3 .hero-text {
  margin-bottom: 34px;
}
.hero-style3 .search-form {
  background: var(--white-color);
  box-shadow: 0px 7px 35px #e8f2fd;
  padding: 10px;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.hero-style3 .search-form .form-group {
  width: 100%;
}
.hero-style3 .search-form .form-group > svg {
  top: 18px;
  left: 25px;
  pointer-events: none;
}
.hero-style3 .search-form .form-group input {
  padding-left: 48px;
  padding-right: 10px;
  border: none;
  background-color: var(--white-color);
  z-index: 2;
}
.hero-style3 .search-form .as-btn {
  min-width: 170px;
  padding: 15px;
  box-shadow: none;
}
.hero-counter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px 30px;
}
.hero-counter-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px 50px;
  margin-top: 50px;
}
.hero-counter_icon {
  width: 65px;
  height: 65px;
  line-height: 65px;
  background-color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  position: relative;
}
.hero-counter_icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--theme-color);
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: inherit;
  z-index: -1;
}
.hero-counter_number {
  font-size: 36px;
  margin-bottom: 0px;
}
.hero-counter_text {
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  margin-bottom: -10px;
}
@media (max-width: 1399px) {
  .hero-3 .shape1 {
    max-width: 600px;
  }
  .hero-3 .shape2 {
    max-width: 80px;
  }
  .hero-3 .shape4 {
    bottom: 30px;
    max-width: 50px;
  }
  .hero-3 .shape5 {
    max-width: 80px;
  }
  .hero-style3 {
    padding: 300px 0 150px 0;
    max-width: 520px;
  }
  .hero-counter-wrap {
    gap: 30px 30px;
    margin-top: 40px;
  }
}
@media (max-width: 1199px) {
  .hero-3 .shape6 {
    top: 150px;
    max-width: 60px;
  }
  .hero-3 .shape3 {
    top: 130px;
    max-width: 80px;
  }
  .hero-style3 {
    padding: 260px 0 110px 0;
  }
  .hero-style3 .hero-title {
    margin-bottom: 20px;
  }
  .hero-style3 .hero-text {
    margin-bottom: 26px;
  }
}
@media (max-width: 991px) {
  .hero-3 .hero-img1 {
    display: none;
  }
  .hero-3 .shape4 {
    bottom: 30%;
    right: 7%;
  }
  .hero-3 .shape3 {
    top: 110px;
    max-width: 80px;
  }
  .hero-style3 {
    padding: 224px 0 100px 0;
  }
}
@media (max-width: 767px) {
  .hero-3 .shape1 {
    max-width: 100%;
  }
  .hero-style3 .search-form .as-btn {
    min-width: 50px;
    font-size: 0;
  }
  .hero-style3 .search-form .as-btn svg {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .hero-counter_number {
    font-size: 28px;
    margin-bottom: 2px;
  }
}
@media (max-width: 375px) {
  .hero-style3 .search-form {
    padding: 4px;
  }
  .hero-style3 .search-form .form-group input {
    padding-left: 30px;
    font-size: 14px;
    padding-right: 0px;
  }
  .hero-style3 .search-form .form-group > svg {
    left: 10px;
    font-size: 14px;
  }
}
.hero-4 {
  overflow: hidden;
}
.hero-4 .shape1 {
  top: 0;
  left: 0;
  z-index: -1;
}
.hero-4 .shape2 {
  top: 21%;
  right: 4.5%;
  -webkit-animation: jumpAni 6s linear infinite;
  animation: jumpAni 6s linear infinite;
}
.hero-style4 {
  padding: 190px 0;
}
.hero-style4 .hero-subtitle {
  color: var(--theme-color);
  margin-bottom: 24px;
}
.hero-style4 .hero-title {
  margin-bottom: 37px;
}
.hero-style4 .checklist {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .hero-4 .shape2 {
    max-width: 90px;
  }
  .hero-style4 {
    padding: 120px 0px;
  }
  .hero-style4 .hero-title {
    margin-bottom: 25px;
  }
  .hero-style4 .hero-subtitle {
    margin-bottom: 15px;
  }
  .hero-style4 .checklist {
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .hero-style4 {
    padding: 80px 0px;
  }
}
@media (max-width: 767px) {
  .hero-4 {
    background-position: 26% center;
  }
  .hero-4 .shape2 {
    max-width: 50px;
    top: 11%;
  }
}
.error-content {
  padding: 210px;
  text-align: center;
}
.error-img {
  margin-bottom: -50px;
}
.error-title {
  margin-bottom: 22px;
}
.error-text {
  margin-bottom: 30px;
}
@media (max-width: 1399px) {
  .error-content {
    padding: 160px;
  }
}
@media (max-width: 1199px) {
  .error-content {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .error-title {
    margin-bottom: 15px;
  }
  .error-text {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .error-img {
    margin-bottom: -25px;
  }
}
.popup-search-box {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.popup-search-box button.searchClose {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme-color);
  background-color: transparent;
  font-size: 22px;
  border-radius: 50%;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  color: var(--theme-color);
}
.popup-search-box button.searchClose:hover {
  color: var(--body-color);
  background-color: #fff;
  border-color: transparent;
  border-color: transparent;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.popup-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding-bottom: 40px;
  cursor: auto;
  width: 100%;
  max-width: 700px;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform ease 0.4s;
  transition: -webkit-transform ease 0.4s;
  transition: transform ease 0.4s;
  transition: transform ease 0.4s, -webkit-transform ease 0.4s;
}
@media (max-width: 1199px) {
  .popup-search-box form {
    max-width: 600px;
  }
}
.popup-search-box form input {
  font-size: 18px;
  height: 70px;
  width: 100%;
  border: 2px solid var(--theme-color);
  background-color: transparent;
  padding-left: 30px;
  color: #fff;
  border-radius: 50px;
}
.popup-search-box form input::-moz-placeholder {
  color: #fff;
}
.popup-search-box form input::-webkit-input-placeholder {
  color: #fff;
}
.popup-search-box form input:-ms-input-placeholder {
  color: #fff;
}
.popup-search-box form input::-ms-input-placeholder {
  color: #fff;
}
.popup-search-box form input::placeholder {
  color: #fff;
}
.popup-search-box form button {
  position: absolute;
  top: 0px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  right: 12px;
  color: var(--white-color);
  cursor: pointer;
  width: 70px;
  height: 70px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1.001);
  -ms-transform: scale(1.001);
  transform: scale(1.001);
}
.popup-search-box form button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.popup-search-box.show {
  opacity: 1;
  visibility: visible;
  width: 100.1%;
  height: 100%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-radius: 0;
}
.popup-search-box.show form {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}
.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: 2px solid;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--white-color);
  border-radius: 50%;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.sidemenu-wrapper .closeButton:hover {
  color: var(--theme-color);
  border-color: var(--theme-color);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 80px 30px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transition: right ease 1s;
  transition: right ease 1s;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  background-color: transparent;
}
.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}
.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.woocommerce-message,
.woocommerce-info {
  position: relative;
  padding: 11px 20px 11px 50px;
  background-color: #d3d3d3;
  color: var(--body-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  border-radius: 5px;
}
.woocommerce-message a,
.woocommerce-info a {
  color: var(--theme-color);
  text-decoration: underline;
}
.woocommerce-message a:hover,
.woocommerce-info a:hover {
  color: var(--title-color);
}
.woocommerce-message:before,
.woocommerce-info:before {
  content: "\f06a";
  font-family: var(--icon-font);
  font-weight: 400;
  margin-right: 10px;
  font-size: 18px;
  position: absolute;
  left: 20px;
  top: 11px;
}
.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "\f14a";
  font-weight: 300;
}
.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.woocommerce-form-login-toggle .woocommerce-info a {
  color: inherit;
}
.woocommerce-form-login-toggle .woocommerce-info a:hover {
  color: var(--title-color);
}
.woocommerce-form-register,
.woocommerce-form-coupon,
.woocommerce-form-login {
  margin-bottom: 30px;
  padding: 35px 40px 35px 40px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
}
@media (max-width: 575px) {
  .woocommerce-form-register,
  .woocommerce-form-coupon,
  .woocommerce-form-login {
    padding: 40px 20px;
  }
}
.woocommerce-form-register .form-group,
.woocommerce-form-coupon .form-group,
.woocommerce-form-login .form-group {
  margin-bottom: 20px;
}
.woocommerce-form-register .form-group:last-child,
.woocommerce-form-coupon .form-group:last-child,
.woocommerce-form-login .form-group:last-child {
  margin-bottom: 0;
}
.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}
nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}
nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}
nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}
nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}
.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}
.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}
.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
table.variations,
.woocommerce-grouped-product-list-item {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
}
table.variations td,
.woocommerce-grouped-product-list-item td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}
table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
  padding: 0;
}
table.variations label,
.woocommerce-grouped-product-list-item label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
table.variations label a,
.woocommerce-grouped-product-list-item label a {
  color: var(--title-color);
}
table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
  color: var(--theme-color);
}
table.variations .label,
.woocommerce-grouped-product-list-item .label {
  border: none;
}
table.variations__label,
.woocommerce-grouped-product-list-item__label {
  border: none !important;
  font-weight: 600;
}
table.variations__price,
.woocommerce-grouped-product-list-item__price {
  border: none !important;
}
table.variations__price .price,
table.variations__price .amount,
.woocommerce-grouped-product-list-item__price .price,
.woocommerce-grouped-product-list-item__price .amount {
  font-size: 18px !important;
}
table.variations del,
.woocommerce-grouped-product-list-item del {
  margin-left: 12px;
}
.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
  border: 1px solid var(--border-color);
}
.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
  margin-bottom: 0;
}
.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}
.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}
.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}
.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}
.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}
.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}
.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}
table.variations {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}
table.variations td {
  padding: 0;
}
table.variations td.label {
  padding-right: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
table.variations select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 54px;
  padding-left: 20px;
  height: 50px;
}
table.variations .reset_variations {
  margin-left: 16px;
  display: inline-block;
  position: absolute;
  left: 100%;
  bottom: 25px;
}
.woosq-product .product .woocommerce-grouped-product-list-item__quantity,
.woosq-product .product .woocommerce-grouped-product-list-item__label,
.woosq-product .product .woocommerce-grouped-product-list-item__price {
  width: auto !important;
}
.woocommerce-variation.single_variation {
  margin-bottom: 30px;
}
.woocommerce-variation.single_variation .price {
  color: var(--title-color);
  font-weight: 700;
}
.wooscp-table-items td.woocommerce-product-attributes-item__value {
  padding-left: 15px !important;
}
.wooscp-table-items a.added_to_cart.wc-forward {
  margin-left: 15px;
  text-decoration: underline;
}
.tinvwl_added_to_wishlist.tinv-modal.tinv-modal-open {
  z-index: 1111;
}
table.woocommerce-product-attributes {
  margin-bottom: 30px;
}
#woosq-popup .product_meta {
  margin-top: 20px;
}
#woosq-popup .product_title {
  font-size: 24px;
  margin-bottom: 5px;
}
#woosq-popup .single-product .product .actions {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
#woosq-popup .single-product .product .actions > div {
  height: auto;
  overflow: visible;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
#woosq-popup
  .single-product
  .product
  .actions
  > div
  .quantity.style2.woocommerce-grouped-product-list-item__quantity {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.login-tab {
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.login-tab button.nav-link {
  background-color: var(--smoke-color);
  color: var(--title-color);
  padding: 11px 39px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px â€‹15px 0;
}
.login-tab button.nav-link.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.star-rating {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 300;
  font-size: 14px;
}
.star-rating:before {
  content: "\e28b\e28b\e28b\e28b\e28b";
  color: var(--theme-color);
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 3px;
}
.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}
.star-rating span:before {
  content: "\e28b\e28b\e28b\e28b\e28b";
  top: 0;
  position: absolute;
  left: 0;
  color: var(--theme-color);
  letter-spacing: 3px;
  font-weight: 700;
}
.rating-select label {
  margin: 0;
  margin-right: 10px;
}
.rating-select p.stars {
  margin-bottom: 0;
  line-height: 1;
}
.rating-select p.stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}
.rating-select p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "\f005";
  font-weight: 400;
  text-indent: 0;
  color: var(--yellow-color);
}
.rating-select p.stars a:hover ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select p.stars:hover a::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select p.stars.selected a.active::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select p.stars.selected a.active ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select p.stars.selected a:not(.active)::before {
  content: "\f005";
  font-weight: 700;
}
@media (max-width: 767px) {
  .woocommerce-message,
  .woocommerce-info {
    font-size: 14px;
    line-height: 22px;
    padding: 10px 15px 10px 37px;
  }
  .woocommerce-message:before,
  .woocommerce-info:before {
    font-size: 16px;
    top: 10px;
    left: 15px;
  }
}
.as-sort-bar {
  padding: 0;
  margin: 0 0 34px 0;
}
.as-sort-bar .row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 15px;
}
.as-sort-bar select {
  height: 50px;
  border: 1px solid var(--border-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 250px;
  font-size: 16px;
  margin: 0;
  color: var(--body-color);
}
.as-sort-bar .woocommerce-result-count {
  margin-bottom: 0;
  color: var(--body-color);
}
.as-sort-bar .nav a {
  display: inline-block;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  text-transform: capitalize;
  color: var(--title-color);
  margin: 0 0 0 20px;
  padding-left: 20px;
  border-left: 1px solid #d9d9d9;
}
.as-sort-bar .nav a:first-child {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}
.as-sort-bar .nav a svg {
  margin-right: 3px;
}
.as-sort-bar .nav a.active,
.as-sort-bar .nav a:hover {
  color: var(--theme-color);
}
.quantity {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.quantity > .screen-reader-text {
  display: inline-block;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 10px;
}
.quantity .qty-btn,
.quantity .qty-input {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid var(--border-color);
  border-right: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  text-align: center;
  color: var(--body-color);
  font-size: 18px;
  font-weight: 600;
}
.quantity .qty-btn:last-child,
.quantity .qty-input:last-child {
  border-right: 1px solid var(--border-color);
}
.quantity .qty-btn {
  font-size: 16px;
}
@media (max-width: 1199px) {
  .as-sort-bar select {
    min-width: auto;
  }
}
@media (max-width: 991px) {
  .as-sort-bar .row {
    --bs-gutter-x: 20px;
  }
  .as-sort-bar .nav a:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  .as-sort-bar .nav a:last-child:before {
    display: none;
  }
}
@media (max-width: 767px) {
  .as-sort-bar {
    text-align: center;
  }
  .as-sort-bar .nav {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .as-sort-bar select {
    margin: 0 auto;
  }
}
.woocommerce-cart-form {
  text-align: center;
}
.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;
}
.cart_table thead {
  background-color: #ecf0f1;
}
.cart_table thead th {
  border: none !important;
}
.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  border: none;
  padding: 27px 15px;
}
.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}
.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
}
.cart_table .product-quantity {
  color: var(--title-color);
}
.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}
.cart_table .cart-productname {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.cart_table .cart-productimage {
  display: inline-block;
  border: 0 solid var(--smoke-color);
  border-radius: 5px;
  overflow: hidden;
}
.cart_table .remove {
  color: var(--theme-color);
  font-size: 18px;
}
.cart_table .quantity {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart_table .qty-btn {
  border: 2px solid var(--smoke-color);
  background-color: transparent;
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 16px;
  border-radius: 4px;
}
.cart_table .qty-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.cart_table .qty-input {
  vertical-align: middle;
  border: 2px solid var(--smoke-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: var(--title-color);
  font-weight: 700;
  margin: 0 10px;
  border-radius: 4px;
  padding: 0;
}
.cart_table .qty-input::-moz-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::-webkit-input-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input:-ms-input-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::-ms-input-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::-webkit-outer-spin-button,
.cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart_table .qty-input[type="number"] {
  -moz-appearance: textfield;
}
.cart_table .actions {
  text-align: right;
  vertical-align: middle;
}
.cart_table .actions > .as-btn {
  font-size: 16px;
  padding: 17px 28px;
  margin-right: 15px;
}
.cart_table .actions > .as-btn:last-child {
  margin-right: 0;
}
.cart_table .as-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.cart_table .as-cart-coupon input {
  height: 50px;
  width: calc(100% - 200px);
  margin-right: 10px;
}
.cart_table .as-cart-coupon .as-btn {
  font-size: 16px;
  padding: 17px 22px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.cart_totals {
  border: 1px solid #ecf0f1;
}
.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}
/*.cart_totals th:first-child,*/
/*.cart_totals td:first-child {*/
/*  width: 45%;*/
/*  background-color: #f9fbfb;*/
/*  font-weight: 700;*/
/*  font-size: 14px;*/
/*  color: #333333;*/
/*}*/
.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}
.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}
.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}
.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0;
}
.cart_totals .shipping-calculator-form {
  display: none;
}
.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}
.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}
.cart_totals .shipping-calculator-form .as-btn {
  padding: 5px 30px;
}
.cart_totals .amount {
  font-weight: 700;
}
.cart_totals .order-total .amount {
  color: var(--theme-color);
}
@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .as-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cart_table .actions {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .as-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .as-btn:last-child {
    margin-right: auto;
  }
  .cart_table .as-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
  }
  .cart_table .as-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }
  .cart_totals th,
  .cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
  .cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}
.woocommerce-checkout .form-group,
.woocommerce-checkout .form-row {
  margin-bottom: 0;
}
.woocommerce-checkout .form-select,
.woocommerce-checkout .select2-container,
.woocommerce-checkout .form-control {
  margin-bottom: 0;
}
.woocommerce-checkout .select2-container--open .select2-dropdown--below {
  margin-top: -35px;
}
.woocommerce-checkout .select2-container--open .select2-dropdown--above {
  position: relative;
  bottom: -30px;
}
.woocommerce-checkout .select2-dropdown {
  border: 1px solid #e3e6e9;
  border-top: none;
}
.woocommerce-checkout .select2-container--default .select2-selection--single {
  border-radius: 0;
}
.woocommerce-checkout
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.woocommerce-checkout
  .select2-container--default
  .select2-selection--single
  .form-control:focus {
  color: var(--body-color);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #eee;
  padding: 0;
}
.woocommerce-form-login select,
.woocommerce-form-login .form-select,
.woocommerce-form-login .form-control,
.woocommerce-form-login .select2,
.woocommerce-form-login .select2-container,
.woocommerce-form-coupon select,
.woocommerce-form-coupon .form-select,
.woocommerce-form-coupon .form-control,
.woocommerce-form-coupon .select2,
.woocommerce-form-coupon .select2-container,
.woocommerce-checkout select,
.woocommerce-checkout .form-select,
.woocommerce-checkout .form-control,
.woocommerce-checkout .select2,
.woocommerce-checkout .select2-container {
  margin-bottom: var(--bs-gutter-x);
}
#ship-to-different-address {
  margin-top: 15px;
}
.select2-container--default .select2-selection--single {
  height: 60px;
  border: 1px solid #e3e6e9;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 60px;
  padding-left: 30px;
  padding-right: 25px;
}
.woocommerce-billing-fields .form-row {
  margin-bottom: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b:before {
  content: "\f107";
  font-family: var(--icon-font);
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin: 0;
  border: none;
  top: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 60px;
  line-height: 60px;
  margin-right: 30px;
}
span.select2-selection.select2-selection--single:focus {
  outline: none;
}
.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  border-radius: 5px;
  background-position: right 13px center;
}
.shipping-calculator-form .as-btn {
  font-size: 14px;
  padding: 0 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 40px;
}
.checkout-ordertable th,
.checkout-ordertable td {
  border: none;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 700;
  color: #2c3e50;
}
.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}
.checkout-ordertable .order-total .amount {
  color: var(--theme-color);
}
.checkout-ordertable input[type="hidden"] ~ label {
  color: var(--theme-color);
}
.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}
.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
}
.checkout-ordertable th {
  text-align: left;
}
.woocommerce-checkout-payment {
  text-align: left;
}
.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.woocommerce-checkout-payment ul li {
  padding-top: 10px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 16px;
}
.woocommerce-checkout-payment ul input[type="radio"] ~ label {
  margin-bottom: 14px;
  color: var(--body-color);
}
.woocommerce-checkout-payment ul input[type="radio"] ~ label img {
  margin-bottom: -2px;
  margin-left: 10px;
}
.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}
.woocommerce-checkout-payment .payment_box {
  color: #a1b1bc;
  background-color: #ecf0f1;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}
.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}
.as-checkout-wrapper form.woocommerce-form {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  tfoot.checkout-ordertable th {
    display: none;
  }
  .woocommerce-checkout-payment ul input[type="radio"] ~ label img {
    max-width: 150px;
  }
  .checkout-ordertable th,
  .checkout-ordertable td {
    padding: 5px 20px 5px 60px;
  }
}
.tinv-wishlist input[type="checkbox"] {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  vertical-align: middle;
  width: auto;
  height: auto;
}
.tinv-wishlist .tinv-header {
  margin-top: -0.8rem;
}
.tinv-wishlist .cart-empty {
  padding: 12px 25px;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}
.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  margin-top: 10px;
  font-weight: 700;
}
.tinv-wishlist p.return-to-shop .button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.tinv-wishlist table {
  border: none;
}
.tinv-wishlist table th {
  color: var(--title-color);
}
.tinv-wishlist table td,
.tinv-wishlist table th {
  padding: 15.3px 10px;
  border-bottom: 1px solid var(--border-color);
  text-align: center;
}
.tinv-wishlist table thead {
  background-color: var(--smoke-color);
}
.tinv-wishlist .product-cb,
.tinv-wishlist .product-remove {
  width: 40px;
  text-align: center;
}
.tinv-wishlist .product-thumbnail {
  width: 110px;
}
.tinv-wishlist .product-thumbnail img {
  border-radius: 5px;
}
.tinv-wishlist .stock.in-stock {
  margin-bottom: 0;
}
.tinv-wishlist ins {
  text-decoration: none;
}
.tinv-wishlist .product-remove button {
  border: none;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  border-radius: 4px;
  padding-top: 0;
}
.tinv-wishlist .product-remove button svg {
  line-height: 22px;
  font-size: 14px;
}
.tinv-wishlist .tinvwl-mobile {
  display: none;
}
.tinv-wishlist .social-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 295px;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tinv-wishlist .social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
}
.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 16px;
}
.tinv-wishlist .product-stock .stock {
  display: block;
}
.tinv-wishlist .product-stock span {
  display: inline;
}
.tinv-wishlist .product-stock svg {
  margin-right: 5px;
}
.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}
.tinv-wishlist button.button {
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--theme-color);
  color: #fff;
  padding: 1px 15px;
  min-width: 140px;
}
.tinv-wishlist button.button.mask-btn {
  padding: 0;
}
.tinv-wishlist button.button .btn-text-mask {
  padding: 0.5px 21px;
}
.tinv-wishlist button.button:hover {
  background-color: var(--title-color);
  color: #fff;
}
.tinv-wishlist button.button svg {
  font-size: 13px !important;
  margin-right: 3px !important;
}
.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--title-font);
}
.tinv-wishlist td.product-name a {
  color: var(--body-color);
}
.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}
.tinv-wishlist td.product-price del {
  margin-left: 8px;
  font-size: 0.9em;
}
.tinv-wishlist .social-buttons > span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}
.tinv-wishlist .social-buttons li {
  display: inline-block;
  margin-right: 0;
}
.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  margin-left: 3px;
}
.tinv-wishlist .social-buttons li a.social:first-child {
  margin-left: 0;
}
.tinv-wishlist .social-buttons li a.social svg {
  line-height: inherit;
}
.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
@media (max-width: 991px) {
  .tinvwl-full {
    display: none;
  }
  .tinv-wishlist .tinvwl-mobile {
    display: block;
  }
  .tinv-wishlist .product-stock .tinvwl-txt {
    display: none;
  }
  .tinv-wishlist .product-action .tinvwl-txt {
    display: none;
  }
  .tinv-wishlist button.button {
    min-width: auto;
  }
  .product-stock {
    width: 40px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .tinv-wishlist table {
    table-layout: fixed;
    border-bottom: 1px solid var(--border-color);
  }
  .tinv-wishlist .product-action .tinvwl-txt {
    display: inline-block;
  }
  .tinv-wishlist button.button {
    min-width: 140px;
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove,
  .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
    display: none;
  }
  .tinv-wishlist table td,
  .tinv-wishlist table th {
    border: 1px solid var(--border-color);
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td {
    display: block;
    width: 100% !important;
    text-align: center;
  }
  .product-name {
    text-align: center;
  }
  .tinv-wishlist table td,
  .tinv-wishlist table th {
    border-bottom: none;
  }
  .tinv-wishlist table tfoot {
    border-bottom: 1px solid var(--border-color);
  }
  .tinv-wishlist .social-buttons {
    max-width: 100%;
    margin-left: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tinv-wishlist .social-buttons ul {
    margin-left: unset;
    margin-top: 5px;
  }
  .tinvwl-txt {
    display: inline-block;
  }
}
.map-sec {
  position: relative;
    height: 600px;
    border-radius: 10px;
    overflow: hidden;
}
.map-sec .map {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}
.map-sec .map iframe {
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}
.map-contact {
  max-width: 590px;
  padding: 60px;
  background-color: var(--white-color);
  border-radius: 10px;
  position: relative;
  z-index: 3;
}
.border-title {
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 25px;
  margin-top: -0.22em;
  font-weight: 600;
}
.border-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 80px;
  background-color: var(--theme-color);
}
.contact-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 340px;
  gap: 25px;
  margin-bottom: 40px;
}
.contact-info:last-of-type {
  margin-bottom: 0;
}
.contact-info_icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: flex;
    font-size: 30px;
    background-color: rgba(13, 94, 244, 0.07);
    text-align: center;
    color: var(--theme-color);
    border-radius: 5px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    align-items: center;
    justify-content: center;
}
.contact-info_title {
  font-size: 20px;
  margin-bottom: 6px;
  margin-top: -0.2em;
}
.contact-info_text {
  margin-bottom: -0.45em;
  line-height: 1.5;
  display: block;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.contact-info_text a {
  color: var(--title-color);
}
.contact-info_text a:hover {
  color: var(--theme-color);
}
.contact-info:hover .contact-info_icon {
  background-color: var(--theme-color);
  color: var(--white-color);
}
@media (max-width: 767px) {
  .map-contact {
    padding: 40px 40px;
  }
  .contact-info {
    margin-bottom: 30px;
  }
  .contact-info:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .map-contact {
    padding: 40px 20px;
  }
}
@media (max-width: 375px) {
  .contact-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 20px;
  }
  .contact-info .media-body {
    width: 100%;
  }
}
.contact-form {
  max-width: 720px;
}
/* .contact-form-wrap {
  background-color: var(--smoke-color);
  padding: 100px;
  border-radius: 10px;
  background-position: left center;
} */
.contact-form-wrap {
  background-color: var(--smoke-color);
  padding: 60px;
  border-radius: 10px;
  background-position: right center;
  margin-right: 50px;
  margin-top: -85px;
  position: relative;
  z-index: 9;
}
@media (max-width: 991px) {
  .contact-form-wrap {
    padding: 50px;
  }
}
@media (max-width: 767px) {
  .contact-form-wrap {
    padding: 50px 20px;
  }
}
.feature-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px 20px;
}
.feature-box-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
  row-gap: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #e3e9f0;
  padding-top: 35px;
  padding-bottom: 35px;
}
.feature-box_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}
.feature-box_text {
  margin-bottom: 0;
}
.feature-box_icon {
  position: relative;
  text-align: center;
  z-index: 2;
}
.img-box1 {
  position: relative;
  padding-right: 56px;
}
.img-box1 .counter-box {
  position: absolute;
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-70%);
  -ms-transform: translateX(-70%);
  transform: translateX(-70%);
}
.counter-box {
  background-color: var(--white-color);
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 30px rgba(19, 61, 117, 0.12);
}
.counter-box .counter-title {
  color: var(--theme-color);
  margin-bottom: 0;
}
.counter-box .counter-text {
  font-size: 14px;
  font-weight: 600;
  color: var(--theme-color);
  text-transform: uppercase;
}
@media (max-width: 1399px) {
  .img-box1 {
    padding-right: 0;
  }
}
@media (max-width: 1199px) {
  .img-box1 {
    margin-bottom: 40px;
  }
}
@media (max-width: 375px) {
  .feature-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.img-box2 {
  position: relative;
  padding-right: 56px;
}
.img-box2 img {
  border-radius: 10px;
}
.img-box2 .student-count {
  position: absolute;
  bottom: 60px;
  left: -70px;
}
.student-count {
  display: inline-block;
  background-color: var(--white-color);
  box-shadow: 0px 4px 18px rgba(158, 165, 177, 0.23);
  border-radius: 10px;
  padding: 30px;
}
.avater-list {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 22px;
}
.avater-list .avater,
.avater-list .count {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--white-color);
  margin-left: -25px;
}
.avater-list .avater img,
.avater-list .count img {
  width: 100%;
  height: 100%;
}
.avater-list .count {
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  text-align: center;
  line-height: 50px;
}
@media (max-width: 1500px) {
  .img-box2 .student-count {
    bottom: 12px;
    left: -12px;
  }
}
@media (max-width: 1399px) {
  .img-box2 {
    padding-right: 0;
  }
}
@media (max-width: 1199px) {
  .img-box2 {
    margin-bottom: 45px;
  }
}
.about-content {
  padding-bottom: 40px;
  padding-left: 56px;
  padding-top: 40px;
}
.img-box3 img {
  border-radius: 10px;
}
@media (max-width: 1399px) {
  .about-content {
    padding-bottom: 0;
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .about-content {
    padding-left: 0px;
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .about-sec.bg-bottom-right {
    background-size: 240% 60%;
  }
}
@media (max-width: 575px) {
  .about-sec.bg-bottom-right {
    background-size: 240% 80%;
  }
}
.img-box5 {
  position: relative;
  margin-bottom: -120px;
}
.img-box5 .img1 {
  text-align: center;
}
.img-box5 .shape {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
@media (max-width: 1199px) {
  .img-box5 {
    position: relative;
    margin-bottom: 40px;
  }
}
.img-box7 {
  position: relative;
  margin-right: 36px;
}
.img-box7 .img1,
.img-box7 .img2 {
  z-index: 2;
  position: relative;
}
.img-box7 .img1 {
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
}
.img-box7 .img2 {
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
  background-color: var(--white-color);
  padding: 10px 0 10px 10px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.img-box7 .img2 img {
  border-radius: inherit;
}
.moto-box {
  box-shadow: 1px 0px 28px rgba(131, 139, 154, 0.19);
  border-radius: 10px;
  background-color: var(--white-color);
  padding: 40px;
}
.moto-box-wrap {
  margin-top: -180px;
  position: relative;
  z-index: 3;
  --bs-gutter-y: 24px;
}
.moto-box_icon {
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
}
.moto-box_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.moto-box_icon::before {
  content: "";
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(13, 94, 244, 0.07);
  z-index: -1;
  border-radius: 999px;
}
.moto-box_title {
  margin-top: -0.28em;
  font-size: 24px;
}
.moto-box_text {
  margin-bottom: -0.48em;
  max-width: 300px;
}
.moto-box:hover .moto-box_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 1199px) {
  .img-box7 {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .img-box7 img {
    width: 100%;
  }
  .img-box7 .img1 {
    width: 70%;
  }
  .img-box7 .img2 {
    width: 40%;
  }
  .moto-box {
    padding: 40px 30px;
  }
  .moto-box-wrap {
    margin-top: 20px;
  }
}
.img-box8 {
  position: relative;
}
.img-box8 .img1 {
  text-align: center;
}
.img-box8 .img1 img {
  border-radius: 10px;
}
.img-box8 .img2 {
  border-radius: 10px;
  background-color: var(--white-color);
  padding-top: 10px;
  padding-left: 10px;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}
.img-box8 .img2 img {
  border-radius: 10px;
}
.img-box8 .img3 {
  position: absolute;
  top: 80px;
  left: 0;
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}
.img-box8 .img3 img {
  border-radius: 6px;
}
.img-box8 .shape {
  position: absolute;
  bottom: 0px;
  left: -100px;
  z-index: -1;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
@media (max-width: 1199px) {
  .img-box8 {
    margin-bottom: 40px;
  }
  .img-box8 .img1 img {
    max-width: 560px;
  }
  .img-box8 .img3 img {
    width: 260px;
  }
}
@media (max-width: 991px) {
  .img-box8 .img1 img {
    max-width: 100%;
  }
  .img-box8 .img3 img {
    max-width: 100%;
    width: auto;
  }
}
.img-box9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
}
.img-box9 .img2 {
  margin-bottom: 30px;
}
.img-box9 img {
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .img-box9 {
    margin-bottom: 25px;
  }
  .img-box9 img {
    width: 100%;
  }
  .img-box9 .img-group {
    width: 100%;
  }
  .img-box9 .img1 {
    width: 100%;
  }
}
.img-box4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 32px;
  padding-left: 56px;
}
.img-box4 .img1,
.img-box4 .img2 {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.img-box4 .img1 img,
.img-box4 .img2 img {
  width: 100%;
}
.img-box4 .img1 {
  margin-top: -50px;
}
.img-box4 .img2 {
  margin-top: 50px;
}
.img-box4 .shape {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 1399px) {
  .big-shape {
    max-width: 60%;
  }
  .img-box4 {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .img-box4 {
    gap: 20px;
  }
}
@media (max-width: 575px) {
  .img-box4 {
    gap: 10px;
  }
}
.team-box {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.team-box .team-img {
  position: relative;
  overflow: hidden;
  border-radius: 11px;
}
.team-box .team-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.team-box .team-content {
  background-color: var(--white-color);
  position: absolute;
  bottom: -150px;
  left: 0;
  width: 100%;
  padding: 30px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.team-box .team-title {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: -0.23em;
  font-weight: 500;
}
.team-box .team-title a {
  color: inherit;
}
.team-box .team-title a:hover {
  color: var(--theme-color);
}
.team-box .team-desig {
  color: var(--theme-color);
  display: block;
  font-family: var(--title-font);
  margin-bottom: -0.45em;
}
.team-box .as-social {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.team-box:hover .team-img img {
  -webkit-filter: grayscale(100) blur(1px) brightness(0.5);
  filter: grayscale(100) blur(1px) brightness(0.5);
}
.team-box:hover .team-content {
  bottom: 0;
  visibility: visible;
  opacity: 1;
}
.team-box:hover .as-social {
  top: 30%;
  visibility: visible;
  opacity: 1;
}
#team-sec {
  background-position: center bottom;
  border-radius: 15px;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1199px) {
  .team-box {
    --space: 20px;
  }
}
.team-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.team-info .line {
  height: 16px;
  width: 1px;
  display: inline-block;
  background-color: #b3c1d3;
}
.team-info span {
  color: var(--title-color);
}
.team-info span svg {
  color: var(--theme-color);
  margin-right: 8px;
}
.team-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--white-color);
  box-shadow: 0px 5px 18px rgba(76, 88, 104, 0.06);
}
.team-card .team-img {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 35px));
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 35px));
}
.team-card .team-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.team-card .team-title {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: -0.23em;
  font-weight: 600;
}
.team-card .team-title a {
  color: inherit;
}
.team-card .team-title a:hover {
  color: var(--theme-color);
}
.team-card .team-desig {
  color: var(--theme-color);
  display: block;
  font-family: var(--title-font);
  margin-bottom: -0.45em;
}
.team-card .team-content {
  background-color: var(--white-color);
  padding: 20px 30px 30px 30px;
}
.team-card .team-info {
  padding: 23px 30px;
  border-top: 1px solid #ecf1f9;
}
.team-card .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  position: relative;
  z-index: 3;
}
.team-card .icon-btn:hover svg {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team-card .icon-btn:hover i:before {
  content: "\f068";
}
.team-card .as-social {
  position: absolute;
  width: 45px;
  bottom: 50px;
  left: 2.5px;
}
.team-card .as-social a {
  margin-right: 0;
  margin-bottom: 5px;
  background-color: var(--white-color);
  color: var(--theme-color);
  box-shadow: 0px 5px 18px rgba(76, 88, 104, 0.06);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.team-card .as-social a:nth-child(1) {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.team-card .as-social a:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.team-card .as-social a:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.team-card .as-social a:nth-child(4) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.team-card .as-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.team-card .team-social {
  position: absolute;
  bottom: 175px;
  left: 30px;
}
.team-card .team-social:hover .as-social a {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.team-card .team-social:hover .as-social a:nth-child(4) {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.team-card .team-social:hover .as-social a:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.team-card .team-social:hover .as-social a:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.team-card .team-social:hover .as-social a:nth-child(1) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.team-card:hover .team-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.team-card.style2 .team-social {
  bottom: 100px;
}
.team-grid {
    height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--white-color);
  border: 1px solid #ecf1f9;
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.team-grid .team-img {
  margin: -1px;
  overflow: hidden;
  position: relative;
}
.team-grid .team-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.team-grid .team-img:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(5, 10, 16, 0.0728) 55.88%,
    rgba(8, 18, 31, 0.91) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(5, 10, 16, 0.0728) 55.88%,
    rgba(8, 18, 31, 0.91) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.team-grid .team-content {
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding: 40px 15px;
  z-index: 3;
}
.team-grid .team-title {
  color: var(--white-color);
  font-size: 24px;
  margin-bottom: 0;
  margin-top: -0.23em;
  font-weight: 600;
}
.team-grid .team-title a {
  color: inherit;
}
.team-grid .team-title a:hover {
  color: var(--theme-color);
}
.team-grid .team-desig {
  color: var(--white-color);
  display: block;
  font-family: var(--title-font);
  margin-bottom: -0.45em;
}
.team-grid .team-info {
  padding: 23px 40px;
}
.team-grid .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  position: relative;
  z-index: 3;
}
.team-grid .icon-btn:hover svg {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team-grid .icon-btn:hover i:before {
  content: "\f068";
}
.team-grid:hover .team-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
@media (max-width: 1399px) {
  .team-grid .team-info {
    padding: 20px 25px;
  }
  .team-grid .team-content {
    padding: 30px 15px;
    bottom: 0px;
  }
}
@media (max-width: 767px) {
  .team-grid .team-info {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
@media (max-width: 430px) {
  .team-grid .team-info {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.team-details-img {
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}
.about-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.about-box .as-social a {
  border: 1px solid #dbe3ee;
  color: var(--body-color);
  border-radius: 3px;
  --icon-size: 34px;
  font-size: 13px;
}
.about-box .as-social a:hover {
  color: var(--white-color);
  border-color: var(--theme-color);
}
.about-info .title {
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: -0.2em;
}
.about-info .desig {
  display: block;
  color: var(--theme-color);
}
.social-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
.social-box .title {
  font-weight: 500;
  color: var(--title-color);
}
.about-quality {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px dashed;
  border-bottom: 1px dashed;
  border-color: #e0e0e0;
  margin-top: 38px;
}
.quality-box .quality-text {
  display: block;
}
.quality-box .quality-text svg {
  color: var(--theme-color);
  margin-right: 10px;
}
.quality-box .quality-text a {
  color: var(--body-color);
}
.quality-box .quality-text a:hover {
  color: var(--theme-color);
}
@media (max-width: 1199px) {
  .team-details-img {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
@media (max-width: 991px) {
  .about-info .title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .about-quality {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
  }
  .quality-box {
    min-width: calc(50% - 8px);
  }
  .about-info .title {
    font-size: 32px;
  }
  .about-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px 5px;
  }
}
@media (max-width: 575px) {
  .about-info .title {
    font-size: 28px;
    margin-bottom: 2px;
  }
}
.testi-card-slide .slick-arrow {
  --pos-x: -164px;
  top: 111%;
  margin-top: 0;
  visibility: visible;
  opacity: 1;
}
.testi-card-tab {
  border-bottom: 2px solid #284a75;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
.testi-card-tab .tab-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 999px;
  overflow: hidden;
}
.testi-card-tab .tab-btn img {
  width: 100%;
}
.testi-card-tab .indicator {
  position: absolute;
  bottom: -2px;
  left: var(--pos-x);
  height: 2px;
  width: var(--width-set);
  background-color: var(--theme-color);
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
}
.testi-card_name {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  color: var(--white-color);
}
.testi-card_desig {
  margin-bottom: -0.491em;
  font-weight: 500;
  display: block;
  color: var(--theme-color);
}
.testi-card_text {
  font-size: 20px;
  margin-top: -0.4em;
  margin-bottom: 25px;
  color: var(--white-color);
}
@media (max-width: 991px) {
  .testi-card {
    text-align: center;
  }
  .testi-card-tab {
    margin-top: 50px;
    gap: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .testi-card_text {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .testi-card-tab {
    gap: 10px;
  }
  .testi-card_text {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .testi-card-tab {
    gap: 5px;
  }
}
.testi-box {
  padding: 50px;
  background-color: var(--smoke-color);
  border-radius: 10px;
  overflow: hidden;
}
.testi-box_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}
.testi-box_img {
  min-width: 120px;
}
.testi-box_img img {
  border-radius: 10px;
}
.testi-box_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.testi-box_name {
  font-size: 24px;
  margin-bottom: 3px;
  margin-top: -0.24em;
  font-weight: 500;
}
.testi-box_desig {
  color: var(--theme-color);
  margin-bottom: -0.45em;
  display: block;
}
.testi-box_text {
  font-size: 20px;
  font-family: var(--title-font);
  line-height: 1.5;
  margin-bottom: 0;
}
.testi-box_bottom {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #dae5f0;
}
.testi-box_review {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 3px;
}
.testi-box_review svg {
  font-size: 14px;
  color: var(--theme-color);
}
@media (max-width: 1199px) {
  .testi-box_img {
    min-width: 90px;
  }
}
@media (max-width: 991px) {
  .testi-box_img {
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  .testi-box {
    padding: 40px 20px;
  }
  .testi-box_content {
    gap: 25px;
  }
  .testi-box_bottom {
    margin-top: 25px;
    padding-top: 30px;
  }
  .testi-box_text {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .testi-box_content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 375px) {
  .testi-box_name {
    font-size: 20px;
    margin-bottom: 0;
  }
  .testi-box_bottom {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.testi-grid {
  background-color: transparent;
  border: 2px solid #0b2950;
  padding: 60px;
  border-radius: 10px;
  background-color: transparent;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
@media (max-width: 991px) {
  .testi-grid {
    padding: 50px 20px;
  }
}
.testi-grid_name {
  font-size: 24px;
  margin-bottom: 3px;
  margin-top: -0.24em;
  font-weight: 500;
  color: var(--white-color);
}
.testi-grid_desig {
  color: var(--theme-color);
  margin-bottom: -0.45em;
  display: block;
}
.testi-grid_text {
  font-size: 20px;
  font-family: var(--title-font);
  line-height: 1.5;
  margin-bottom: -0.4em;
  color: var(--light-color);
}
.testi-grid_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px dashed #29456a;
}
.testi-grid_author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
.testi-grid_avater {
  border-radius: 999px;
  overflow: hidden;
  width: 60px;
}
.testi-grid_review {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 3px;
}
.testi-grid_review svg {
  font-size: 14px;
  color: var(--yellow-color);
}
.slick-center .testi-grid {
  background-color: #092141;
  border-color: #092141;
}
@media (max-width: 767px) {
  .testi-grid_text {
    font-size: 18px;
  }
}
@media (max-width: 400px) {
  .testi-grid_name {
    font-size: 20px;
    margin-bottom: 0;
  }
  .testi-grid_avater {
    width: 50px;
  }
  .testi-grid_bottom {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
  }
}
.testi-grid-style2 {
  background: var(--white-color);
  border: 1px solid #ecf1f9;
  box-shadow: 0px 9px 14px rgba(60, 79, 115, 0.04);
}
.testi-grid-style2 .testi-grid_text {
  color: var(--body-font);
}
.testi-grid-style2 .testi-grid_name {
  color: var(--title-color);
}
.testi-grid-style2 .testi-grid_bottom {
  border-color: #d0d7e3;
}
@media (max-width: 1199px) {
  .testi-grid-style2 {
    padding: 45px 20px;
  }
}
.counter-card {
  text-align: center;
  padding: 5px 0 !important;
}
.counter-card-wrap {
  border-right: 1px solid;
  border-color: #2f78ff !important;
}
.counter-card-wrap:last-child {
  border-right: 0;
}
.counter-card_number {
  color: var(--white-color);
  margin-bottom: 6px;
  margin-top: -0.24em;
  font-weight: 600;
}
.counter-card_text {
  color: var(--white-color);
  display: block;
  margin-bottom: -0.2em;
  font-size: 24px;
}
.counter-card_text strong {
  font-weight: 600;
}
@media (max-width: 1199px) {
  .counter-card {
    padding: 60px 0;
  }
  .counter-card-wrap:nth-child(1),
  .counter-card-wrap:nth-child(2) {
    border-bottom: 1px solid;
  }
  .counter-card-wrap:nth-child(2) {
    border-right: 0;
  }
  .counter-card_text {
    font-size: 20px;
    margin-bottom: -0.3em;
  }
}
@media (max-width: 767px) {
  .counter-card {
    padding: 40px 0;
  }
  .counter-card_number {
    margin-bottom: 0;
  }
  .counter-card_text {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .counter-card-wrap {
    border-right: 0 !important;
  }
  .counter-card-wrap:nth-child(3) {
    border-bottom: 1px solid;
  }
}
.counter-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.counter-grid-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 25px 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.counter-grid_icon {
  min-width: 55px;
}
.counter-grid_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.counter-grid_number {
  color: var(--theme-color);
  margin-bottom: 0;
  margin-top: -0.2em;
}
.counter-grid_text {
  color: var(--title-color);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: -0.45em;
}
.counter-grid:hover .counter-grid_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.blog-meta.style2 span,
.blog-meta.style2 a {
  font-family: var(--body-font);
  margin-right: 25px;
  padding-right: 0;
  color: #6a6e71;
}
.blog-meta.style2 span:after,
.blog-meta.style2 a:after {
  display: none;
}
.blog-meta.style2 span:last-child,
.blog-meta.style2 a:last-child {
  margin-right: 0;
}
.blog-meta a:hover {
  color: var(--theme-color);
}
.blog-grid {
  --space: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid var(--border-color);
  background-color: var(--white-color);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.blog-grid-wrap {
  display: grid;
  grid-template-areas:
    "one one one one one one two two two two two two"
    "one one one one one one three three three three three three";
}
.blog-grid .tag {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--theme-color);
  background-color: #ecf3ff;
  padding: 5px 16px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 15px;
}
.blog-grid .blog-img {
  position: relative;
  min-width: 250px;
  height: 220px;
  overflow: hidden;
  margin: -1px;
}
.blog-grid .blog-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-grid .blog-content {
  padding: 30px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.blog-grid .blog-title {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.417;
  font-weight: 600;
}
.blog-grid .blog-meta {
  margin-bottom: -0.4em;
}
.blog-grid:nth-child(1) {
  grid-area: one;
  margin-right: 12px;
  display: block;
  background-color: transparent;
}
.blog-grid:nth-child(1) .blog-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--space);
  z-index: 2;
}
.blog-grid:nth-child(1) .blog-img {
  min-width: 100%;
  height: 100%;
}
.blog-grid:nth-child(1) .blog-img:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.06) 0%,
    #020f21 100%
  );
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, #020f21 100%);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.blog-grid:nth-child(1) .category {
  top: var(--space);
  left: var(--space);
}
.blog-grid:nth-child(1) .blog-meta a,
.blog-grid:nth-child(1) .blog-meta span {
  color: var(--white-color);
}
.blog-grid:nth-child(1) .blog-meta a i,
.blog-grid:nth-child(1) .blog-meta span svg {
  color: var(--white-color);
}
.blog-grid:nth-child(1) .blog-title {
  color: var(--white-color);
  font-size: 30px;
  line-height: 1.333;
}
.blog-grid:nth-child(1) .link-btn {
  color: var(--white-color);
}
.blog-grid:nth-child(1) .link-btn:before {
  background-color: var(--white-color);
}
.blog-grid:nth-child(2) {
  grid-area: two;
  margin-left: 12px;
  margin-bottom: 24px;
}
.blog-grid:nth-child(3) {
  grid-area: three;
  margin-left: 12px;
}
.blog-grid:hover .blog-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 1399px) {
  .blog-grid {
    --space: 30px;
  }
  .blog-grid .blog-img {
    min-width: 225px;
    height: 225px;
  }
}
@media (max-width: 1299px) {
  .blog-grid .blog-title {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .blog-grid .blog-content {
    padding: 30px 20px;
  }
  .blog-grid:nth-child(1) .blog-title {
    font-size: 26px;
  }
}
@media (max-width: 1199px) {
  .blog-grid {
    --space: 60px;
  }
  .blog-grid-wrap {
    grid-template-areas:
      "one one one"
      "two two two"
      "three three three";
  }
  .blog-grid .blog-img {
    min-width: 400px;
    height: 250px;
  }
  .blog-grid .blog-content {
    padding: 30px;
  }
  .blog-grid:nth-child(1) {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .blog-grid:nth-child(2) {
    margin-left: 0;
  }
  .blog-grid:nth-child(3) {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .blog-grid .blog-img {
    min-width: 250px;
    height: 210px;
  }
}
@media (max-width: 767px) {
  .blog-grid {
    --space: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .blog-grid .blog-img {
    height: 100%;
  }
  .blog-grid .blog-content {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}
@media (max-width: 575px) {
  .blog-grid:nth-child(1) .blog-title {
    font-size: 22px;
  }
  .blog-grid:nth-child(1) .blog-img {
    min-height: 280px;
  }
}
@media (max-width: 375px) {
  .blog-grid .blog-title {
    font-size: 20px;
  }
  .blog-grid:nth-child(1) .blog-content {
    padding: 35px 15px;
  }
  .blog-grid:nth-child(1) .blog-title {
    font-size: 20px;
  }
  .blog-grid .blog-content {
    padding: 30px 15px;
  }
}
.blog-card {
  box-shadow: 0px 3px 0px #dfeaf4;
  border-radius: 10px;
  background-color: var(--white-color);
  overflow: hidden;
}
.blog-card .blog-title {
  font-size: 24px;
  margin-top: -0.23em;
  line-height: 1.417;
  font-weight: 500;
  margin-bottom: 20px;
}
.blog-card .blog-img {
  overflow: hidden;
}
.blog-card .blog-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-card .blog-content {
  padding: 40px;
}
.blog-card .blog-meta {
  margin-top: -0.4em;
  margin-bottom: 20px;
}
.blog-card .link-btn {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -0.2em;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.blog-card:hover .blog-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
@media (max-width: 991px) {
  .blog-card .blog-content {
    padding: 40px 20px;
  }
}
@media (max-width: 767px) {
  .blog-card .blog-content {
    padding: 40px 40px;
  }
}
@media (max-width: 410px) {
  .blog-card .blog-content {
    padding: 40px 20px;
  }
}
@media (max-width: 375px) {
  .blog-card .blog-title {
    font-size: 20px;
  }
  .blog-card .blog-meta {
    margin-bottom: 15px;
  }
  .blog-card .blog-meta a,
  .blog-card .blog-meta span {
    margin-right: 15px;
    font-size: 14px;
  }
  .blog-card .blog-meta a:last-child,
  .blog-card .blog-meta span:last-child {
    margin-right: 0;
  }
  .blog-card .blog-meta a i,
  .blog-card .blog-meta span svg {
    margin-right: 5px;
  }
}
.blog-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: var(--bs-gutter-x);
  margin-bottom: var(--bs-gutter-x);
}
.blog-box .blog-title {
  font-size: 24px;
  margin-top: -0.28em;
  line-height: 1.417;
  font-weight: 600;
  margin-bottom: 15px;
}
.blog-box .blog-img {
  overflow: hidden;
  border-radius: 10px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.blog-box .blog-img img {
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-box .blog-meta {
  margin-top: -0.4em;
  padding-bottom: 19px;
  margin-bottom: 24px;
  border-bottom: 1px dashed #d9d9d9;
}
.blog-box .blog-meta span,
.blog-box .blog-meta a {
  font-family: var(--title-font);
  margin-right: 20px;
}
.blog-box .blog-meta span:last-child,
.blog-box .blog-meta a:last-child {
  margin-right: 0;
}
.blog-box .blog-meta span i,
.blog-box .blog-meta a svg {
  color: var(--body-color);
}
.blog-box .link-btn {
  margin-bottom: -0.2em;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.blog-box .as-btn {
  color: var(--theme-color);
  border-color: var(--theme-color);
}
.blog-box .as-btn::before {
  background-color: var(--white-color);
}
.blog-box .as-btn:hover {
  color: var(--white-color);
}
.blog-box .as-btn:hover::before {
  background-color: var(--theme-color);
}
.blog-box.style-big {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.blog-box.style-big .blog-title {
  font-size: 36px;
  line-height: 1.278;
  margin-bottom: 25px;
  margin-top: 0;
}
.blog-box.style-big .blog-img {
  margin-bottom: 15px;
  min-width: 100%;
}
.blog-box.style-big .blog-img img {
  min-width: 100%;
}
@media (max-width: 767px) {
  .blog-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
  .blog-box .blog-img {
    width: 100%;
    height: 260px;
  }
  .blog-box.style-big .blog-img {
    height: auto;
  }
  .blog-box.style-big .blog-title {
    font-size: 26px;
    line-height: 1.378;
  }
}
@media (max-width: 375px) {
  .blog-box .blog-title {
    font-size: 20px;
  }
}
.blog-block {
  background: var(--white-color);
  box-shadow: 4px 7px 20px rgba(234, 237, 242, 0.7);
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
}
.blog-block .blog-img {
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}
.blog-block .blog-img img {
  width: 100%;
}
.blog-block .blog-title {
  font-size: 24px;
  margin-top: -0.28em;
  line-height: 1.417;
  font-weight: 500;
  margin-bottom: 16px;
}
.blog-block .blog-meta {
  margin-bottom: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
.blog-block .blog-meta:after {
  content: "";
  height: 1px;
  width: calc(100% + 60px);
  position: absolute;
  left: -30px;
  bottom: -1px;
  background-color: var(--border-color);
}
.blog-block .blog-meta a i,
.blog-block .blog-meta span svg {
  color: var(--body-color);
}
.blog-block .blog-meta a:hover i,
.blog-block .blog-meta span:hover svg {
  color: var(--theme-color);
}
.blog-block .link-btn {
  margin-bottom: -0.7em;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.blog-block:hover .blog-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 991px) {
  .blog-block {
    padding: 20px 20px 30px 20px;
  }
}
@media (max-width: 767px) {
  .blog-block {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  .blog-block {
    padding: 20px 20px 30px 20px;
  }
  .blog-block .blog-title {
    font-size: 22px;
  }
}
@media (max-width: 375px) {
  .blog-block .blog-img {
    margin-bottom: 20px;
  }
  .blog-block .blog-title {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .blog-block .blog-meta {
    padding-bottom: 15px;
    margin-bottom: 17px;
  }
  .blog-block .blog-meta a,
  .blog-block .blog-meta span {
    font-size: 14px;
    margin-right: 14px;
  }
  .blog-block .blog-meta a i,
  .blog-block .blog-meta span svg {
    margin-right: 5px;
  }
}
.brand-box {
  text-align: center;
}
.brand-box-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 40px 24px;
}
@media (max-width: 430px) {
  .brand-box-wrap {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.feature-card {
  padding: 45px 30px;
  background-color: var(--white-color);
  box-shadow: 0px 4px 21px rgba(179, 193, 219, 0.15);
  border-radius: 10px;
  text-align: center;
}
.feature-card-wrap:nth-child(even) {
  -webkit-transform: translateY(24px);
  -ms-transform: translateY(24px);
  transform: translateY(24px);
}
.feature-card_icon {
  margin-bottom: 25px;
}
.feature-card_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.feature-card_title {
  font-size: 22px;
  margin-bottom: 12px;
  margin-top: -0.28em;
  font-weight: 600;
}
.feature-card_text {
  margin-bottom: -0.45em;
}
.feature-card:hover .feature-card_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 767px) {
  .feature-card-wrap:nth-child(even) {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.feature-grid {
  padding: 60px 50px;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #0b2950;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.feature-grid_icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 40px auto;
}
.feature-grid_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.feature-grid_title {
  font-size: 24px;
  margin-bottom: 14px;
  margin-top: -0.28em;
  font-weight: 600;
  color: var(--white-color);
}
.feature-grid_text {
  margin-bottom: -0.48em;
  color: var(--light-color);
}
.feature-grid:hover {
  background-color: #092141;
}
.feature-grid:hover .feature-grid_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 1199px) {
  .feature-grid {
    padding: 45px 15px;
  }
  .feature-grid_icon {
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 767px) {
  .feature-grid_text {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.video-btn {
  font-size: 36px;
  color: var(--white-color);
  background-color: var(--theme-color);
  padding: 42px 57px;
}
.video-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
@media (max-width: 767px) {
  .video-btn {
    font-size: 24px;
    padding: 22px 37px;
  }
}
.checklist ul {
  padding-left: 0;
  list-style: none;
  text-align: left;
  margin-bottom: 0;
}
.checklist li {
  color: var(--title-color);
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  padding-left: 28px;
}
/* .checklist li:before {
  content: "\f058";
  font-family: var(--icon-font);
  font-weight: 900;
  color: var(--theme-color);
  position: absolute;
  left: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
} */
.checklist li:first-child {
  margin-top: -0.5em;
}
.checklist li:last-child {
  margin-bottom: -0.5em;
}
.checklist .check-text {
  margin-bottom: 0;
}
.checklist .check-title {
  margin-bottom: 10px;
  font-size: 24px;
}
.checklist.style2 ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 50px;
  -moz-column-gap: 50px;
  column-gap: 50px;
}
@media (max-width: 1399px) {
  .checklist.style2 ul {
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .checklist.style2 ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
.checklist.style2 li {
  color: var(--body-color);
  padding-left: 40px;
  margin-bottom: 0;
}
.checklist.style2 li:before {
  font-size: 24px;
  top: 5px;
}
.checklist.style2 li:first-child {
  margin-top: 0;
}
.checklist.style3 li {
  font-family: var(--body-font);
  font-weight: 400;
}
.checklist.style3 li:before {
  content: '';
  font-size: 22px;
  background-image: url(./assets/img/checkmark.png);
  background-size: contain;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0px;
    top: 8px;
    background-color: #fff;
}
.checklist.style4 li {
  font-family: var(--body-font);
  font-weight: 400;
}
.checklist.style4 li:before {
  content: "\f00c";
  font-size: 18px;
}
@media (max-width: 1199px) {
  .checklist.mb-40 {
    margin-bottom: 32px;
  }
}
@media (max-width: 1199px) {
  .checklist.mb-45 {
    margin-bottom: 35px;
  }
}
.notification-box {
  background-color: var(--white-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 910px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 5px 30px #e7ebf0;
  border-radius: 10px;
  padding: 40px;
  gap: 30px;
  position: relative;
  z-index: 3;
}
.notification-box .as-btn {
  margin-left: auto;
}
.notification-box .notification-title {
  margin-bottom: 6px;
}
.notification-box .notification-text {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .notification-box {
    padding: 25px;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .notification-box {
    padding: 35px 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .notification-box .notification-content {
    text-align: center;
  }
  .notification-box .as-btn {
    margin-left: unset;
  }
}
.course-form input,
.course-form select {
  background-color: transparent;
  border-color: #29375f;
  color: var(--white-color);
  height: 55px;
}
.course-form input::-moz-placeholder,
.course-form select::-moz-placeholder {
  color: var(--white-color);
}
.course-form input::-webkit-input-placeholder,
.course-form select::-webkit-input-placeholder {
  color: var(--white-color);
}
.course-form input:-ms-input-placeholder,
.course-form select:-ms-input-placeholder {
  color: var(--white-color);
}
.course-form input::-webkit-input-placeholder,
.course-form select::-webkit-input-placeholder {
  color: var(--white-color);
}
.course-form input::-moz-placeholder,
.course-form select::-moz-placeholder {
  color: var(--white-color);
}
.course-form input:-ms-input-placeholder,
.course-form select:-ms-input-placeholder {
  color: var(--white-color);
}
.course-form input::-ms-input-placeholder,
.course-form select::-ms-input-placeholder {
  color: var(--white-color);
}
.course-form input::placeholder,
.course-form select::placeholder {
  color: var(--white-color);
}
.course-form input:focus,
.course-form select:focus {
  border-color: var(--theme-color);
}
.bg-img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}
.bg-img img {
  width: 100%;
  height: 100%;
}
.img-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 48%;
}
.img-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top right;
}
@media (max-width: 1199px) {
  .img-left {
    position: relative;
    width: 100%;
  }
}
.insta-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.insta-box .icon-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.insta-box img {
  width: 100%;
}
.insta-box::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #001d52;
  position: absolute;
  inset: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: inherit;
}
.insta-box:hover:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  visibility: visible;
  opacity: 0.6;
}
.insta-box:hover .icon-btn {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.as-box {
  text-align: center;
}
.as-box_img {
  margin-bottom: -110px;
  max-width: 476px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  position: relative;
  padding-top: 30px;
}
@media (max-width: 575px) {
  .as-box_img {
    margin-bottom: -68px;
  }
}
.as-box_img:before,
.as-box_img:after {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--smoke-color);
  z-index: -1;
  border-width: 9px 15px 0 15px;
  border-style: solid;
  border-color: var(--theme-color);
  border-radius: 999px;
}
.as-box_img::after {
  border: none;
  top: 9px;
  left: 13px;
  width: calc(100% - 26px);
}
.as-box_content {
  padding: 140px 60px 60px 60px;
  background-color: var(--smoke-color);
  border-radius: 15px;
}
@media (max-width: 575px) {
  .as-box_content {
    padding: 100px 20px 45px 20px;
  }
}
.as-box_text {
  max-width: 450px;
  margin: 0 auto 23px auto;
}
.as-box.theme2 {
  --theme-color: #f20f10;
  --smoke-color: #fff3f3;
}
.as-box.theme2 .as-btn {
  box-shadow: 0px 6px 16px rgba(242, 15, 16, 0.24);
}
.as-video {
  position: relative;
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0px 4px 16px #ebeef3;
  padding: 20px;
}
.as-video img {
  border-radius: inherit;
  width: 100%;
}
.as-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.as-video .play-btn > svg {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.as-video .play-btn:after,
.as-video .play-btn:before {
  background-color: var(--theme-color);
}
@media (max-width: 1199px) {
  p.mb-30 {
    margin-bottom: 25px;
  }
  p.mb-35 {
    margin-bottom: 28px;
  }
  p.mb-40 {
    margin-bottom: 32px;
  }
  p.mb-45 {
    margin-bottom: 35px;
  }
}
.img-box6 {
  position: relative;
  padding-right: 56px;
}
.img-box6 .img1,
.img-box6 .img2 {
  z-index: 2;
  position: relative;
}
.img-box6 .img1 {
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
}
.img-box6 .img2 {
  z-index: 4;
  margin-top: -27%;
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
  background-color: var(--smoke-color);
  padding-left: 10px;
  padding-top: 10px;
}
.img-box6 .img2 img {
  border-radius: inherit;
}
.img-box6 .shape1,
.img-box6 .shape2 {
  position: absolute;
}
.img-box6 .shape1 {
  bottom: 70px;
  left: 20%;
  z-index: 3;
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
.img-box6 .shape2 {
  top: 70px;
  left: -70px;
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}
.check-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  background-color: var(--white-color);
  padding: 30px;
  border-radius: 10px;
  max-width: 560px;
  margin-bottom: 24px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.check-box:nth-of-type(even) {
  margin-left: auto;
}
.check-box:last-of-type {
  margin-bottom: 0;
}
.check-box .icon svg {
  font-size: 22px;
  color: var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.check-box .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: -0.28em;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.check-box .text {
  margin-bottom: -0.48em;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.check-box:hover {
  background-color: var(--theme-color);
}
.check-box:hover .title,
.check-box:hover .text {
  color: var(--white-color);
}
.check-box:hover .icon svg {
  color: var(--white-color);
}
@media (max-width: 1399px) {
  .img-box6 {
    padding-right: 0;
  }
}
@media (max-width: 1199px) {
  .img-box6 {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .img-box6 {
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .check-box {
    padding: 30px 15px;
  }
  .check-box .title {
    font-size: 20px;
    margin-top: -0.2em;
  }
}
.tab-menu1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}
.tab-menu1 button {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  background-color: var(--white-color);
  padding: 9.5px 30px;
  box-shadow: 0px 4px 10px rgba(107, 131, 174, 0.04);
  border-radius: 999px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tab-menu1 button:hover {
  color: var(--theme-color);
}
.tab-menu1 button.active {
  color: var(--white-color);
  background-color: var(--theme-color);
}
@media (max-width: 1399px) {
  .tab-menu1 {
    gap: 10px;
  }
  .tab-menu1 button {
    padding: 9.5px 25px;
  }
}
@media (max-width: 1199px) {
  .tab-menu1 {
    margin-bottom: 30px;
  }
  .tab-menu1 button {
    font-size: 13px;
    padding: 7px 17px;
  }
}
.tab-menu2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 40px;
  position: relative;
  margin-bottom: -0.4em;
}
.tab-menu2 button {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  line-height: 1.556;
  text-transform: uppercase;
  border: none;
  padding: 0;
  background-color: transparent;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
}
.tab-menu2 button:before {
  content: "New";
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
  padding: 0px 7px;
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tab-menu2 button:after {
  content: "";
  border-style: solid;
  border-width: 9px 9px 0 0;
  border-color: var(--theme-color) transparent transparent transparent;
  position: absolute;
  bottom: 100%;
  right: 20px;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tab-menu2 button:hover {
  color: var(--theme-color);
}
.tab-menu2 button.active {
  color: var(--theme-color);
}
.tab-menu2 button.active:before,
.tab-menu2 button.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  visibility: visible;
}
.tab-menu2 button.active:before {
  bottom: calc(100% + 9px);
}
.tab-menu2 button.active:after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
@media (max-width: 767px) {
  .tab-menu2 {
    gap: 26px 20px;
  }
}
.gallery-img {
  position: relative;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
}
.gallery-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.gallery-img:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0a2548;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: 1;
}
.gallery-btn {
  background-color: var(--white-color);
  color: var(--theme-color);
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}
.gallery-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.gallery-card {
  position: relative;
}
.gallery-card .gallery-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 15px;
  text-align: center;
  z-index: 3;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  visibility: visible;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.gallery-card .gallery-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 0;
}
.gallery-card .gallery-tag {
  color: var(--white-color);
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}
.gallery-card:hover .gallery-img:before {
  visibility: visible;
  opacity: 0.8;
}
.gallery-card:hover .gallery-img img {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}
.gallery-card:hover .gallery-btn {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
}
.gallery-card:hover .gallery-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
@media (max-width: 1199px) {
  .gallery-card .gallery-tag {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .gallery-img img {
    min-height: 320px;
    object-fit: cover;
  }
}
.accordion-card {
  margin-bottom: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.accordion-card .accordion-button {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--title-font);
  border: 0;
  color: var(--title-color);
  background-color: var(--white-color);
  box-shadow: 0px 5px 15px rgba(42, 77, 113, 0.04);
  border-radius: 5px;
  padding: 15px 60px 15px 25px;
  min-height: 50px;
  gap: 10px;
  margin-bottom: 0;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}
.accordion-card .accordion-button:after {
  content: "\f067";
  width: 55px;
  height: 55px;
  height: 100%;
  line-height: 1;
  background-image: none;
  font-family: var(--icon-font);
  color: var(--title-color);
  font-weight: 900;
  font-size: 1em;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.accordion-card .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion-card .accordion-button:not(.collapsed) {
  color: var(--white-color);
  background-color: var(--theme-color);
  box-shadow: none;
  border-radius: 5px 5px 0 0;
}
.accordion-card .accordion-button:not(.collapsed):after {
  content: "\f068";
  color: var(--white-color);
}
.accordion-card .accordion-collapse {
  border: none;
}
.accordion-card .accordion-body {
  box-shadow: 0px 5px 15px rgba(42, 77, 113, 0.04);
  border-radius: 0 0 5px 5px;
  background-color: var(--white-color);
  padding: 23px 25px 30px 25px;
}
.accordion-card .faq-text {
  margin-bottom: -0.48em;
}
.accordion-card .faq-img {
  height: 100%;
}
.accordion-card .faq-img img {
  height: 100%;
  object-fit: cover;
}
.accordion-card:last-child {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .accordion-card .accordion-button {
    font-size: 16px;
  }
}
.cta-content {
  padding: 80px 0 80px 80px;
}
.cta-wrap {
  background-color: var(--white-color);
  position: relative;
  z-index: 3;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 40px rgba(15, 27, 49, 0.12);
}
.cta-img {
  height: 100%;
}
.cta-img img {
  height: 100%;
  width: 100%;
}
@media (max-width: 1199px) {
  .cta-content {
    padding: 40px 0 40px 20px;
  }
}
.cta-card {
  padding: 60px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 575px) {
  .cta-card {
    padding: 45px 15px;
  }
  .course-img img {
    width: 99%;
    margin: 2px 2px 55px 0px !important;
    border-radius: 10px;
    /* padding: 50px; */
    max-width: 350px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    text-align: center;
}
}
.course-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.417;
}
.course-title a {
  color: inherit;
}
.course-title a:hover {
  color: var(--theme-color);
}
.course-img {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.course-img img {
  width: 99%;
  transition: 0.4s ease-in-out;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  max-height: 600px;
  object-fit: cover;
  min-height: 275px;
}
.course-img .tag {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--theme-color2);
  color: var(--white-color);
  font-weight: bold;
  font-size: 14px;
  font-family: var(--body-font);
  padding: 0 17px;
  border-radius: 999px;
}
.course-author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.author-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.author-info img {
  border-radius: 999px;
}
.author-info .author-name {
  font-size: 18px;
  color: var(--body-color);
  display: block;
}
.author-info .author-name:hover {
  color: var(--theme-color) !important;
}
.author-info .desig {
  display: block;
  font-size: 13px;
  color: var(--theme-color);
  line-height: 1;
}
.author-info .info .author-name {
  color: var(--title-color);
  font-weight: 500;
  margin-top: -0.2em;
}
.course-rating {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  font-family: var(--body-font);
  gap: 3px;
}
.course-rating .star-rating {
  width: 75px;
  font-size: 11px;
}
.course-meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.course-meta a,
.course-meta span {
  font-size: 12px;
}
.course-meta a i,
.course-meta span svg {
  margin-right: 5px;
}
.course-box {
  border-radius: 10px;
  background-color: var(--white-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  padding: var(--box-space-x, 30px) var(--box-space-x, 30px) 0
    var(--box-space-x, 30px);
  overflow: hidden;
}
.course-box .course-img {
  margin-bottom: 20px;
}
.course-box .course-title {
  margin-bottom: 21px;
}
.course-box .course-meta {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px dashed #d0d7e3;
}
.course-box:hover:hover {
  box-shadow: 0px 3px 0px #dfeaf4;
}
.course-box:hover .course-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.course-box.style4 {
    box-shadow: none;
    max-height: 428px;
    height: 100%;
    border: none;
    border: 1px solid #ecf1f9;
    box-shadow: 0px 9px 14px #fbfbfb;
}
@media (max-width: 1199px) {
  .course-author .author-name {
    font-size: 16px;
  }
  .course-box {
    --box-space-x: 20px;
  }
  .course-box .course-meta {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .course-box .course-title {
    margin-bottom: 15px;
  }
  .course-box.style2 .course-author {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .course-box {
    --box-space-x: 30px;
  }
  .course-box .course-meta {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 375px) {
  .course-box {
    --box-space-x: 20px;
  }
  .course-box .course-meta {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.course-box.style2 {
  box-shadow: 0px 3px 0px #dfeaf4;
}
.course-box.style2 .course-meta a,
.course-box.style2 .course-meta span {
  font-size: 16px;
}
.course-box.style2 .course-author {
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .course-box.style2 .course-author {
    margin-bottom: 25px;
  }
}
@media (max-width: 991px) {
  .course-box.style2 .course-meta a,
  .course-box.style2 .course-meta span {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .course-box.style2 .course-title {
    font-size: 20px;
  }
  .course-box.style2 .author-info img {
    max-width: 35px;
  }
  .course-box.style2 .course-meta a,
  .course-box.style2 .course-meta span {
    font-size: 12px;
  }
}
.course-box.style3 {
  background-color: var(--smoke-color);
  border-radius: 10px;
  padding: 0;
}
.course-box.style3 .course-meta a,
.course-box.style3 .course-meta span {
  font-size: 16px;
}
.course-box.style3 .course-title {
  font-weight: 600;
}
.course-box.style3 .course-content {
  padding: 12px 40px 0 40px;
}
.course-box.style3 .course-author {
  margin-bottom: 30px;
}
.course-box.style3 .course-img {
  border-radius: 0;
}
.course-box.style3 .tag {
  top: 30px;
  right: 30px;
}
.course-box.style3:hover {
  box-shadow: none;
}
@media (max-width: 1399px) {
  .course-box.style3 .course-content {
    padding: 2px 30px 0 30px;
  }
  .course-box.style3 .course-author {
    margin-bottom: 25px;
  }
}
@media (max-width: 1199px) {
  .course-box.style3 .course-meta {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
@media (max-width: 991px) {
  .course-box.style3 .course-meta a,
  .course-box.style3 .course-meta span {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .course-box.style3 .course-content {
    padding: 0px 20px 0 20px;
  }
  .course-box.style3 .course-title {
    font-size: 20px;
  }
  .course-box.style3 .author-info img {
    max-width: 35px;
  }
  .course-box.style3 .course-meta {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .course-box.style3 .course-meta a,
  .course-box.style3 .course-meta span {
    font-size: 12px;
  }
}
.course-sec {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.course-sec .bg-shape {
  position: absolute;
  top: 180px;
  left: 0;
  border-radius: 10px;
  height: calc(100% - 180px);
  width: 100%;
}
.meta-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}
.meta-box svg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: 0px 4px 18px rgba(13, 94, 244, 0.24);
  border-radius: 5px;
}
.meta-box .text {
  font-size: 16px;
  color: var(--title-color);
  font-weight: 500;
  display: block;
  line-height: 1;
  margin-bottom: 6px;
}
.meta-box .number {
  display: block;
  width: 100%;
  line-height: 1;
  font-size: 16px;
}
.course-img {
    position: relative;
    text-align: center;
    background: #003768;
}
.course-img .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.course-img .play-btn:after,
.course-img .play-btn:before {
  background-color: transparent;
  border: 1px solid var(--white-color);
}
.course-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}
.course-grid:last-of-type {
  margin-bottom: 0;
}
.course-grid .course-img {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 60%;
  position: relative;
  height: 392px;
}
.course-grid .course-img img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  object-fit: cover;
}
.course-grid .course-img::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0d2139;
  opacity: 0.2;
}
.course-grid .course-content {
  background-color: var(--white-color);
  min-width: 50%;
  box-shadow: 0px 4px 29px rgba(152, 170, 204, 0.15);
  border-radius: 10px;
  padding: 70px;
  position: absolute;
  bottom: 50%;
  right: 0;
  z-index: 3;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.course-grid .course-title {
  font-size: 30px;
  font-weight: 600;
  margin-top: -0.3em;
  margin-bottom: 2px;
}
.course-grid .course-author {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #d0d7e3;
}
.course-grid:nth-of-type(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.course-grid:nth-of-type(even) .course-content {
  right: unset;
  left: 0;
}
@media (max-width: 1399px) {
  .course-grid .course-img {
    min-width: auto;
  }
  .course-grid .course-img img {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .course-grid .course-img {
    width: 45%;
  }
}
@media (max-width: 991px) {
  .course-grid .course-img {
    width: 80%;
  }
  .course-grid .course-content {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    bottom: 0;
    padding: 30px;
  }
  .course-img .play-btn {
    -webkit-transform: translate(-50%, -120%);
    -ms-transform: translate(-50%, -120%);
    transform: translate(-50%, -120%);
  }
}
@media (max-width: 767px) {
  .course-grid {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .course-grid .course-img {
    width: 100%;
    height: 280px;
  }
  .course-grid .course-content {
    bottom: unset;
    top: -20px;
    padding: 30px 15px;
    position: relative;
  }
  .course-img .play-btn {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 575px) {
  .course-meta {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
  }
  .meta-box svg {
    display: none;
  }
}
@media (max-width: 375px) {
  .course-grid .course-title {
    font-size: 24px;
    margin-bottom: 9px;
    line-height: 1.3;
  }
}
.video-course {
  --box-space: 30px;
  text-align: center;
}
.video-course .course-img {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding-bottom: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px ;
}
.video-course .course-img:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #11213d 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #11213d 100%);
  z-index: 1;
}
.video-course .play-btn {
  --icon-size: 70px;
  font-size: 1em;
  color: var(--white-color);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-course .play-btn > svg {
  border: 2px solid;
  background-color: transparent;
  color: var(--white-color);
}
.video-course .play-btn:after,
.video-course .play-btn:before {
  background-color: transparent;
  border: 2px solid;
}
.video-course .play-btn:hover > svg {
  color: var(--theme-color);
}
.video-course .play-btn:hover:after,
.video-course .play-btn:hover:before {
  border-color: var(--theme-color);
}
.video-course .tag {
  top: 30px;
  right: 30px;
}
.video-course .course-content {
  max-width: calc(100% - var(--box-space) * 2);
  margin-left: auto;
  margin-right: auto;
  background: var(--white-color);
  border: 1px solid #ecf1f9;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  margin-top: -60px;
  padding: 30px 15px;
  position: relative;
  z-index: 3;
}
.video-course .course-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: -0.28em;
}
.video-course .author-name {
  display: block;
  margin-bottom: -0.45em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.video-course:hover .course-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.course-result-count {
  font-size: 18px;
  font-weight: 500;
  color: var(--title-color);
  display: inline-block;
}
@media (max-width: 575px) {
  .course-result-count {
    font-size: 16px;
  }
}
.course-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
  position: relative;
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
  margin-bottom: 30px;
}
.course-list .course-content {
  max-width: 730px;
}
.course-list .course-img {
  min-width: 530px;
}
.course-list .course-img .tag {
  right: unset;
  left: 20px;
}
.course-list .course-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.333;
}
.course-list .course-meta {
  max-width: 560px;
}
.course-list .course-author {
  max-width: 360px;
  margin-bottom: 17px;
}
.course-list .course-text {
    margin-bottom: 20px;
    height: 200px;
    overflow: hidden;
}
@media (max-width: 1199px) {
  .course-list .course-img {
    display: none;
  }
}
@media (max-width: 991px) {
  .course-list .course-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .course-list .course-author {
    max-width: 360px;
    margin-bottom: 10px;
  }
  .course-list .course-text {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .course-list {
    --blog-space-x: 20px;
  }
  .course-list .course-author {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 3px 10px;
  }
}
.course-tags a {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 999px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.course-tags a:hover {
  background-color: var(--theme-color2);
}
.course-single-meta > span,
.course-single-meta > a,
.course-single-meta > div {
  display: inline-block;
  margin-right: 15px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  position: relative;
}
.course-single-meta > span:after,
.course-single-meta > a:after,
.course-single-meta > div:after {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #d3dbeb;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.course-single-meta > span:last-child,
.course-single-meta > a:last-child,
.course-single-meta > div:last-child {
  margin-right: 0;
  padding-right: 0;
}
.course-single-meta > span:last-child:after,
.course-single-meta > a:last-child:after,
.course-single-meta > div:last-child:after {
  display: none;
}
.course-single-meta > span i,
.course-single-meta > a i,
.course-single-meta > div svg {
  margin-right: 10px;
  color: var(--theme-color);
}
.course-single-meta > span img,
.course-single-meta > a img,
.course-single-meta > div img {
  margin-right: 10px;
  border-radius: 99px;
}
.course-single-meta a:hover {
  color: var(--theme-color);
}
.course-single-meta .course-rating {
  top: 3px;
}
.course-single-meta .course-rating .star-rating {
  width: 100px;
  font-size: 15px !important;
}
.course-tab {
  gap: 1px;
  position: relative;
}
.course-tab .nav-link {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: var(--smoke-color);
  padding: 20px 20px;
  color: var(--body-color);
  width: 100%;
  text-align: center;
}
.course-tab .nav-link:hover,
.course-tab .nav-link.active {
  color: var(--theme-color);
}
.course-tab .nav-link svg {
  margin-right: 8px;
}
.course-tab .nav-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.course-tab .indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px !important;
  background-color: var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.author-meta {
  margin-bottom: 18px;
}
.author-meta a,
.author-meta span {
  color: var(--title-color);
  margin-right: 20px;
  font-weight: 500;
}
.author-meta a i,
.author-meta span svg {
  margin-right: 8px;
  color: var(--theme-color);
}
.author-meta a:hover,
.author-meta span:hover {
  color: var(--theme-color);
}
.author-meta a:last-child,
.author-meta span:last-child {
  margin-right: 0;
}
.course-description {
  padding: var(--blog-space-y, 35px) var(--blog-space-x, 40px);
}
.course-description > p:last-of-type {
  margin-bottom: 0;
}
.course-curriculam {
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
}
.course-instructor {
  padding: 0 var(--blog-space-x, 40px) 0 var(--blog-space-x, 40px);
}
.course-Reviews .as-comments-wrap {
  margin: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
}
.course-Reviews .as-comment-form {
  margin-top: var(--blog-space-y, 40px);
  margin-bottom: 0;
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  margin-left: -1px;
  margin-right: -1px;
}
.course-single-top {
  position: relative;
  margin-bottom: var(--blog-space-y, 40px);
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
}
.course-single .course-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.278;
  margin-bottom: 28px;
}
.course-single .course-img {
  margin-bottom: var(--blog-space-y, 40px);
  border-radius: 10px;
  padding-bottom: 5px;
}
.course-single .course-tags {
  margin-bottom: 15px;
}
.course-single-bottom {
  position: relative;
  margin-bottom: var(--blog-space-y, 40px);
  border: 1px solid var(--border-color);
  box-shadow: 0px 9px 14px #fbfbfb;
  border-radius: 10px;
  padding-bottom: 0;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .course-single {
    --blog-space-x: 20px;
  }
  .course-single .course-title {
    font-size: 30px;
  }
  .course-single-meta .text-theme {
    display: none;
  }
  .course-single-meta > a,
  .course-single-meta > span,
  .course-single-meta > div {
    font-size: 14px;
    margin-right: 5px;
    padding-right: 10px;
  }
  .course-author-box .auhtor-img {
    min-height: 100%;
    max-width: 200px;
  }
}
@media (max-width: 991px) {
  .course-single {
    --blog-space-x: 40px;
  }
}
@media (max-width: 767px) {
  .course-single {
    --blog-space-x: 20px;
  }
  .course-single .course-title {
    font-size: 24px;
    margin-bottom: 18px;
  }
  .course-single .course-img {
    margin-bottom: 25px;
  }
  .course-single .course-img img {
    min-height: 230px;
    object-fit: cover;
  }
  .course-tab .nav-link svg {
    margin-right: 0;
    display: block;
  }
  .course-single-meta > a,
  .course-single-meta > span,
  .course-single-meta > div {
    padding-right: 5px;
  }
  .course-single-meta > a i,
  .course-single-meta > span i,
  .course-single-meta > div svg {
    margin-right: 5px;
  }
  .course-single-meta > a:after,
  .course-single-meta > span:after,
  .course-single-meta > div:after {
    display: none;
  }
  .course-author-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .course-author-box .auhtor-img {
    min-height: 100%;
    max-width: 100%;
  }
  .course-author-box .auhtor-img img {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .course-tab .nav-link {
    padding: 12px;
  }
  .course-author-box .media-body {
    padding: 35px 20px 35px 20px;
  }
}
@media (max-width: 460px) {
  .course-tab .nav-item {
    -webkit-flex-basis: 49%;
    -ms-flex-preferred-size: 49%;
    flex-basis: 49%;
  }
}
@media (max-width: 375px) {
  .course-single .course-title {
    font-size: 20px;
  }
}
.process-card {
  text-align: center;
  max-width: 290px;
  margin: 0 auto 0 auto;
}
.process-card-wrap {
  border-right: 1px dashed #d0d7e3;
  margin-bottom: 40px;
}
.process-card-wrap:last-child {
  border-right: none;
}
.process-card_img {
  margin-bottom: 25px;
}
.process-card_img img {
  border-radius: 999px;
}
.process-card_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
.process-card_text {
  margin-bottom: -0.5em;
}
@media (max-width: 991px) {
  .process-card-wrap:nth-child(2n) {
    border-right: none;
  }
  .process-card_img {
    margin-bottom: 20px;
  }
}
.event-meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px 20px;
}
.event-meta p,
.event-meta span,
.event-meta a {
  margin-bottom: 0;
  font-size: 14px;
}
.event-meta p i,
.event-meta span i,
.event-meta a svg {
  margin-right: 5px;
}
.event-author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
.event-author .avater {
    max-width: 42px;
    border-radius: 0;
    overflow: hidden;
    padding: 0px;
}
.event-author .author-name {
  font-size: 14px;
  font-weight: 500;
  color: var(--title-color);
}
.event-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 24px;
  position: relative;
  padding: 30px 15px 30px 0;
  border-radius: 10px;
  background-color: var(--white-color);
  z-index: 2;
}
.event-card:before {
  content: "";
  height: 100%;
  width: calc(100% - 75px);
  position: absolute;
  top: 0;
  left: 75px;
  border: 1px solid var(--border-color);
  border-radius: inherit;
  z-index: -1;
}
.event-card-index-page:before {
     content: "";
  border: none;
}
.event-card_img {
  border-radius: 10px;
  overflow: hidden;
}

.upcoming-events-main .event-card_img img {
  height: 50px;
}

.event-card_img img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  width: 210px;
  height: 156px;
}
.event-card .event-meta {
  margin-top: -0.3em;
  margin-bottom: 10px;
}
.event-card_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}
.event-card_title a {
  color: inherit;
}
.event-card_title a:hover {
  color: var(--theme-color);
}
.event-card_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px 25px;
}
.event-card .as-btn {
  padding: 15.5px 27px;
}
.event-card:hover .event-card_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .event-card {
    padding: 20px 15px 20px 0;
    gap: 20px;
  }
}
@media (max-width: 575px) {
  .event-card {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
  }
  .event-card::before {
    left: 0;
    top: 35px;
    width: 100%;
    height: calc(100% - 35px);
  }
  .event-card_content {
    padding: 0 15px 15px 15px;
  }
  .event-card_title {
    margin-bottom: 20px;
  }
}
.event-details .event-img {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 40px;
}
.event-details .event-img img {
  min-height: 300px;
  object-fit: cover;
}
.event-details .checklist ul li {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.event-details .checklist ul li:before {
  font-weight: 400;
}
.category-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}
.category-card_icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  min-width: 60px;
  background-color: #dee9fe;
  border-radius: 999px;
  text-align: center;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.category-card_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.category-card_content {
  margin-bottom: -0.45em;
}
.category-card_title {
  font-size: 24px;
  font-weight: 500;
  margin-top: -0.23em;
}
.category-card_title a {
  color: inherit;
}
.category-card_title a:hover {
  color: var(--theme-color);
}
.category-card_text {
  margin-bottom: 12px;
}
.category-card:hover .category-card_icon {
  background-color: var(--theme-color);
}
.category-card:hover .category-card_icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
@media (max-width: 767px) {
  .category-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 22px;
  }
  .category-card_content {
    text-align: center;
  }
  .category-card_title {
    margin-bottom: 10px;
  }
  .category-card_text {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}
.category-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 30px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
  z-index: 2;
}
.category-list:before {
  content: "";
  background-color: var(--theme-color);
  width: 0;
  height: 0;
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
  z-index: -1;
}
.category-list_icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  min-width: 60px;
  background-color: #ebf2ff;
  border-radius: 10px;
  text-align: center;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  margin-right: 15px;
}
.category-list_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.category-list .icon-btn {
  margin-left: auto;
  min-width: 50px;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}
.category-list_title {
  font-size: 24px;
  font-weight: 500;
  margin-top: -0.23em;
  margin-bottom: 2px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.category-list_title a {
  color: inherit;
  -webkit-transition: 0s;
  transition: 0s;
}
.category-list_text {
  display: block;
  margin-bottom: -0.4em;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.category-list:hover:before {
  width: 100%;
  height: 100%;
}
.category-list:hover .category-list_title {
  color: var(--white-color);
}
.category-list:hover .category-list_text {
  color: var(--white-color);
}
.category-list:hover .icon-btn {
  background-color: var(--white-color);
  color: var(--theme-color);
  border-color: var(--white-color);
}
@media (max-width: 1399px) {
  .category-list_title {
    font-size: 22px;
  }
}
@media (max-width: 375px) {
  .category-list {
    padding: 15px;
  }
  .category-list_title {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: -0.1em;
    font-weight: 600;
  }
  .category-list_text {
    font-size: 14px;
  }
  .category-list_icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    min-width: 50px;
    margin-right: 7px;
  }
}
.tab-menu1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}
.tab-menu1 button {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  background-color: var(--white-color);
  padding: 9.5px 30px;
  box-shadow: 0px 4px 10px rgba(107, 131, 174, 0.04);
  border-radius: 999px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tab-menu1 button:hover {
  color: var(--theme-color);
}
.tab-menu1 button.active {
  color: var(--white-color);
  background-color: var(--theme-color);
}
@media (max-width: 1399px) {
  .tab-menu1 {
    gap: 10px;
  }
  .tab-menu1 button {
    padding: 9.5px 25px;
  }
}
@media (max-width: 1199px) {
  .tab-menu1 {
    margin-bottom: 30px;
  }
  .tab-menu1 button {
    font-size: 13px;
    padding: 7px 17px;
  }
}
.tab-menu2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 40px;
  position: relative;
  margin-bottom: -0.4em;
}
.tab-menu2 button {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  line-height: 1.556;
  text-transform: uppercase;
  border: none;
  padding: 0;
  background-color: transparent;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
}
.tab-menu2 button:before {
  content: "New";
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
  padding: 0px 7px;
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tab-menu2 button:after {
  content: "";
  border-style: solid;
  border-width: 9px 9px 0 0;
  border-color: var(--theme-color) transparent transparent transparent;
  position: absolute;
  bottom: 100%;
  right: 20px;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tab-menu2 button:hover {
  color: var(--theme-color);
}
.tab-menu2 button.active {
  color: var(--theme-color);
}
.tab-menu2 button.active:before,
.tab-menu2 button.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  visibility: visible;
}
.tab-menu2 button.active:before {
  bottom: calc(100% + 9px);
}
.tab-menu2 button.active:after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
@media (max-width: 767px) {
  .tab-menu2 {
    gap: 26px 20px;
  }
}
.feature-card {
  padding: 45px 30px;
  background-color: var(--white-color);
  box-shadow: 0px 4px 21px rgba(179, 193, 219, 0.15);
  border-radius: 10px;
  text-align: center;
}
.feature-card-wrap:nth-child(even) {
  -webkit-transform: translateY(24px);
  -ms-transform: translateY(24px);
  transform: translateY(24px);
}
.feature-card_icon {
  margin-bottom: 25px;
}
.feature-card_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.feature-card_title {
  font-size: 22px;
  margin-bottom: 12px;
  margin-top: -0.28em;
  font-weight: 600;
}
.feature-card_text {
  margin-bottom: -0.45em;
}
.feature-card:hover .feature-card_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 767px) {
  .feature-card-wrap:nth-child(even) {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.feature-grid {
  padding: 60px 50px;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #0b2950;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.feature-grid_icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 40px auto;
}
.feature-grid_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.feature-grid_title {
  font-size: 24px;
  margin-bottom: 14px;
  margin-top: -0.28em;
  font-weight: 600;
  color: var(--white-color);
}
.feature-grid_text {
  margin-bottom: -0.48em;
  color: var(--light-color);
}
.feature-grid:hover {
  background-color: #092141;
}
.feature-grid:hover .feature-grid_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 1199px) {
  .feature-grid {
    padding: 45px 15px;
  }
  .feature-grid_icon {
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 767px) {
  .feature-grid_text {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.px-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.px-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.px-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.px-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}
.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}
.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}
.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}
.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}
.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}
.mx-35 {
  margin-right: 35px;
  margin-left: 35px;
}
.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}
.mx-45 {
  margin-right: 45px;
  margin-left: 45px;
}
.mx-50 {
  margin-right: 50px;
  margin-left: 50px;
}
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-n1 {
  margin-top: -0.25rem;
}
.mt-n2 {
  margin-top: -0.6rem;
}
.mt-n3 {
  margin-top: -1rem;
}
.mt-n4 {
  margin-top: -1.5rem;
}
.mt-n5 {
  margin-top: -3rem;
}
.mb-n1 {
  margin-bottom: -0.25rem;
}
.mb-n2 {
  margin-bottom: -0.6rem;
}
.mb-n3 {
  margin-bottom: -1rem;
}
.mb-n4 {
  margin-bottom: -1.5rem;
}
.mb-n5 {
  margin-bottom: -3rem;
}
.space,
.space-top {
  padding-top: var(--section-space);
}
.space,
.space-bottom {
  padding-bottom: var(--section-space);
}
.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}
.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}
.space-extra2,
.space-extra2-top {
  padding-top: calc(var(--section-space) - 40px);
}
.space-extra2,
.space-extra2-bottom {
  padding-bottom: calc(var(--section-space) - 40px);
}
@media (max-width: 991px) {
  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }
  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }
  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }
  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
  .space-top-md-none {
    padding-top: 0;
  }
  .space-extra2,
  .space-extra2-top {
    padding-top: 70px;
  }
  .space-extra2,
  .space-extra2-bottom {
    padding-bottom: 70px;
  }
}
.as-btn.style6 {
  background-color: var(--white-color);
  color: var(--theme-color);
  box-shadow: none;
}
.as-btn.style6:before {
  background-color: var(--smoke-color);
}
.as-btn.style6:hover {
  color: var(--white-color);
}
.as-btn.style6:hover:before {
  background-color: var(--title-color);
}
.video-link .play-btn {
  --icon-size: 56px;
  margin-right: 20px;
}
.video-link .play-btn > svg {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.video-link .play-btn:before,
.video-link .play-btn:after {
  background-color: transparent;
  border: 1px solid var(--white-color);
}
.video-link .btn-text {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--title-font);
  text-transform: uppercase;
  color: var(--white-color);
}
.video-link:hover .btn-text {
  color: var(--theme-color);
}
.btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 40px;
}
.main-menu a .new-label {
  font-size: 13px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 2px 5px;
  border-radius: 4px;
  position: relative;
  top: -1px;
}
.header-link {
  display: inline-block;
  color: var(--white-color);
  margin-right: 40px;
}
.header-link:last-child {
  margin-right: 0;
}
.header-link:hover {
  color: var(--theme-color);
}
.header-link .icon-btn {
  margin-right: 10px;
  font-size: 14px;
  border: none;
}
.icon-btn.style2 {
  background-color: transparent;
  border: 2px solid var(--white-color);
  color: var(--white-color);
  line-height: 42px;
}
.icon-btn.style2:hover {
  background-color: var(--title-color);
  border-color: var(--title-color);
}
.header-layout5 .top-area {
  background-color: var(--title-color);
}
.header-layout5 .header-top {
  padding: 12px 0;
  --body-color: #fff;
  border-bottom: 1px solid #202c3c;
}
.header-layout5 .header-top a,
.header-layout5 .header-top li,
.header-layout5 .header-top p,
.header-layout5 .header-top span,
.header-layout5 .header-top svg {
  color: var(--white-color);
}
.header-layout5 .header-top a:hover {
  color: var(--theme-color);
}
.header-layout5 .menu-top {
  padding: 20px 0;
}
.header-layout5 .menu-top .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.header-layout5 .header-link {
  color: var(--white-color);
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid #202c3c;
}
.header-layout5 .header-link:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}
.header-layout5 .header-link:hover {
  color: var(--theme-color);
}
.header-layout5 .as-social a {
  background-color: #0f2239;
  border: 1px solid #4d5765;
  color: var(--white-color);
}
.header-layout5 .as-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.header-layout5 .simple-icon {
  color: var(--white-color);
}
.header-layout5 .dropdown-menu {
  --body-color: #74787c;
}
.header-layout5 .logo-shape {
  display: inline-block;
  height: 100%;
  width: 34%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--white-color);
  background-size: auto;
  background-repeat: repeat;
  -webkit-clip-path: polygon(0 0, calc(100% - 100px) 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, calc(100% - 100px) 0%, 100% 100%, 0% 100%);
  z-index: -1;
}
.header-layout5 .icon-style2 {
  color: var(--title-color);
}
.header-layout5 .menu-area {
  position: relative;
  background-color: var(--theme-color);
  z-index: 2;
}
.header-layout5 .menu-area .container {
  --main-container: 1562px;
  margin-left: auto;
  margin-right: 66px;
}
@media (min-width: 1922px) {
  .header-layout5 .menu-area .container {
    margin-right: auto;
  }
}
@media (max-width: 1399px) {
  .header-layout5 .menu-area .container {
    margin-right: auto;
  }
}
.header-layout5 .main-menu > ul > li {
  margin: 0 18px;
}
.header-layout5 .main-menu > ul > li > a {
  color: var(--white-color);
  padding: 39px 0;
}
.header-layout5 .as-menu-toggle {
  margin: 20px 0;
  background-color: var(--title-color);
}
.header-layout5 .header-button {
  margin-left: 130px;
}
@media (max-width: 1700px) {
  .header-layout5 .logo-shape {
    width: 22%;
  }
}
@media (max-width: 1500px) {
  .header-layout5 .header-button {
    margin-left: 60px;
  }
}
@media (max-width: 1300px) {
  .header-layout5 .header-button {
    margin-left: 20px;
  }
  .header-layout5 .logo-shape {
    width: 26%;
  }
}
@media (max-width: 1199px) {
  .header-layout5 .header-link:nth-child(2) {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }
  .header-layout5 .logo-shape {
    width: 32%;
  }
}
@media (max-width: 991px) {
  .header-layout5 .logo-shape {
    width: 60%;
  }
  .header-layout5 .header-link {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }
}
@media (max-width: 767px) {
  .header-notice {
    text-align: center;
    font-size: 14px;
  }
  .header-layout5 .menu-top {
    padding: 12px 0;
  }
}
@media (max-width: 575px) {
  .header-layout5 .logo-shape {
    width: 280px;
  }
}
.dropdown-link {
  position: relative;
}
.dropdown-link > a {
  color: var(--white-color);
}
.dropdown-toggle::after {
  content: "\f107";
  border: none;
  font-family: var(--icon-font);
  vertical-align: middle;
  font-weight: 400;
  margin-left: 6px;
}
.dropdown-menu {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: auto;
  top: calc(100% + 10px) !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  -ms-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  padding: 8px 20px !important;
  text-align: center;
  border-color: var(--border-color);
}
.dropdown-menu li {
  padding-right: 0;
  margin-right: 0;
}
.dropdown-menu li:after {
  display: none;
}
.dropdown-menu li a {
  display: block;
}
.dropdown-menu a {
  color: var(--body-color);
}
.dropdown-menu a:hover {
  color: var(--theme-color) !important;
}
.dropdown-menu:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -7px;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  background-color: var(--white-color);
  z-index: -1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}
.simple-icon {
  color: var(--white-color);
  border: none;
  background-color: transparent;
}
.simple-icon.style2 {
  font-size: 28px;
}
.simple-icon:hover {
  color: var(--theme-color);
}
.header-layout6 {
  --main-container: 1440px;
}
.header-layout6 .header-top {
  background-color: var(--theme-color);
  padding: 14px 0;
  --body-color: #fff;
}
.header-layout6 .header-top a,
.header-layout6 .header-top li,
.header-layout6 .header-top p,
.header-layout6 .header-top span,
.header-layout6 .header-top svg {
  color: var(--body-color);
}
.header-layout6 .header-top a:hover {
  color: var(--white-color);
}
.header-layout6 .header-top li:before {
  background-color: #5792ff;
}
.header-layout6 .simple-icon {
  color: var(--white-color);
}
.header-layout6 .dropdown-menu {
  --body-color: #74787c;
}
.header-layout6 .logo-shape {
  display: inline-block;
  height: 100%;
  width: 27.7%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  -webkit-clip-path: polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, calc(100% - 50px) 100%, 0% 100%);
  z-index: -1;
}
.header-layout6 .menu-area {
  position: relative;
  background-color: #050f2d;
  background-size: auto;
  background-repeat: repeat;
  z-index: 2;
}
.header-layout6 .main-menu > ul > li {
  margin: 0 18px;
}
.header-layout6 .main-menu > ul > li > a {
  color: var(--white-color);
  padding: 41.5px 0;
}
.header-layout6 .as-menu-toggle {
  margin: 20px 0;
}
.header-layout6 .header-button {
  margin-left: 100px;
}
.header-layout6 .icon-btn:hover {
  border-color: var(--theme-color);
}
@media (min-width: 1922px) {
  .header-layout6 {
    --main-container: 1440px;
  }
  .header-layout6 .logo-shape {
    width: 34%;
  }
}
@media (max-width: 1700px) {
  .header-layout6 .logo-shape {
    width: 25%;
  }
}
@media (max-width: 1399px) {
  .header-layout6 .container {
    margin-left: auto;
    margin-right: auto;
  }
  .header-layout6 .header-button {
    margin-left: 60px;
  }
  .header-layout6 .logo-shape {
    width: 22%;
  }
}
@media (max-width: 1300px) {
  .header-layout6 .logo-shape {
    width: 26%;
  }
  .header-layout6 .header-button .as-btn {
    display: none;
  }
}
@media (max-width: 1199px) {
  .header-layout6 .logo-shape {
    width: 300px;
  }
}
@media (max-width: 991px) {
  .header-layout6 .logo-shape {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .header-layout6 .header-top {
    padding: 8px 0;
  }
}
@media (max-width: 375px) {
  .header-layout6 .logo-shape {
    width: 75%;
  }
}
.header-layout7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  --main-container: 1920px;
}
.header-layout7 .will-sticky .sticky-active {
  background-color: var(--title-color);
}
.header-layout7 .container {
  padding-left: 0;
  padding-right: 0;
}
.header-layout7 .header-logo {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px 100px 15px 50px;
  border-right: 5px solid var(--theme-color);
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-layout7 .header-top {
  padding: 15px 0;
  --body-color: #fff;
}
.header-layout7 .header-top a,
.header-layout7 .header-top li,
.header-layout7 .header-top p,
.header-layout7 .header-top span,
.header-layout7 .header-top svg {
  color: var(--white-color);
}
.header-layout7 .header-top a:hover {
  color: var(--theme-color);
}
.header-layout7 .header-top li:before {
  background-color: #d9d9d9;
}
.header-layout7 .top-left {
  padding-left: 50px;
}
.header-layout7 .top-right {
  width: 1080px;
  padding-right: 50px;
}
.header-layout7 .simple-icon {
  color: var(--white-color);
}
.header-layout7 .dropdown-menu {
  --body-color: #74787c;
}
.header-layout7 .menu-area {
  position: relative;
  padding: 0 50px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 1080px;
}
.header-layout7 .main-menu > ul > li {
  margin: 0 18px;
}
.header-layout7 .main-menu > ul > li > a {
  color: var(--white-color);
  padding: 36.5px 0;
}
.header-layout7 .as-menu-toggle {
  margin: 15px 0;
}
.header-layout7 .header-button {
  margin-left: 10px;
  gap: 30px;
}
@media (max-width: 1500px) {
  .header-layout7 .header-logo {
    padding: 15px 100px 15px 30px;
  }
  .header-layout7 .top-left {
    padding-left: 30px;
  }
  .header-layout7 .top-right {
    width: 900px;
    padding-right: 30px;
  }
  .header-layout7 .menu-area {
    padding: 0 30px;
    width: 900px;
  }
}
@media (max-width: 1399px) {
  .header-layout7 .container {
    max-width: 100%;
  }
  .header-layout7 .top-right {
    width: 687px;
  }
  .header-layout7 .menu-area {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .header-layout7 .header-logo {
    padding: 15px 100px 15px 15px;
  }
  .header-layout7 .top-left {
    padding-left: 15px;
  }
  .header-layout7 .top-right {
    width: 680px;
    padding-right: 15px;
  }
  .header-layout7 .menu-area {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  .header-layout7 .top-left {
    padding-left: 15px;
  }
  .header-layout7 .top-right {
    width: 100%;
  }
  .header-layout7 .top-right .row {
    --bs-gutter-x: 50px;
  }
}
@media (max-width: 767px) {
  .header-layout7 .header-logo {
    padding: 15px;
  }
}
.header-layout8 {
  --main-container: 1720px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.header-layout8 .main-menu > ul > li > a {
  padding: 46.5px 0;
  color: var(--white-color);
}
.header-layout8:before {
  content: "";
  height: 100%;
  width: 22%;
  background-color: var(--white-color);
  opacity: 0.13;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-clip-path: polygon(100px 0%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(100px 0%, 100% 0, 100% 100%, 0% 100%);
  z-index: -1;
}
.header-layout8 .header-button {
  margin-left: 110px;
}
@media (min-width: 1922px) {
  .header-layout8::before {
    width: 30%;
  }
}
@media (max-width: 1399px) {
  .header-layout8:before {
    width: 26%;
  }
}
@media (max-width: 1300px) {
  .header-layout8:before {
    width: 16%;
  }
  .header-layout8 .header-button .as-btn {
    display: none;
  }
}
@media (max-width: 1199px) {
  .header-layout8 .main-menu > ul > li > a {
    padding: 31.5px 0;
  }
}
@media (max-width: 991px) {
  .header-layout8:before {
    width: 50%;
    -webkit-clip-path: polygon(60px 0%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(60px 0%, 100% 0, 100% 100%, 0% 100%);
  }
}
.hero-5 {
  padding-right: 80px;
}
.hero-5 .slick-dots {
  --dots-bg: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 20%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.hero-5 .slick-dots li {
  display: block;
  margin: 30px 0;
}
.hero-5 .slick-dots button:before {
  border-color: var(--white-color);
}
.hero-5 .slick-dots .slick-active button {
  background-color: var(--white-color);
}
.hero-5 .hero-shape {
  display: inline-block;
  height: 350px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--theme-color);
  -webkit-clip-path: polygon(
    100% 0,
    100% calc(100% - 100px),
    calc(100% - 140px) 100%,
    0 100%,
    0 0
  );
  clip-path: polygon(
    100% 0,
    100% calc(100% - 100px),
    calc(100% - 140px) 100%,
    0 100%,
    0 0
  );
  z-index: -1;
}
.hero-slider-5 .as-hero-slide {
  padding: 230px 0;
}
.hero-style5,
.hero-style6,
.hero-style7,
.hero-style8 {
  padding-left: 40px;
  position: relative;
  z-index: 4;
  max-width: 815px;
}
.hero-style5 .hero-subtitle,
.hero-style6 .hero-subtitle,
.hero-style7 .hero-subtitle,
.hero-style8 .hero-subtitle {
  font-size: 18px;
  font-weight: 600;
  display: block;
  color: var(--white-color);
  margin-top: -0.36em;
  margin-bottom: 35px;
  text-transform: capitalize;
}
.hero-style5 .hero-title,
.hero-style6 .hero-title,
.hero-style7 .hero-title,
.hero-style8 .hero-title {
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 0;
}
.hero-style5 .hero-title:last-of-type,
.hero-style6 .hero-title:last-of-type,
.hero-style7 .hero-title:last-of-type,
.hero-style8 .hero-title:last-of-type {
  margin-bottom: 30px;
}
.hero-style5 .hero-text,
.hero-style6 .hero-text,
.hero-style7 .hero-text,
.hero-style8 .hero-text {
  color: var(--white-color);
  font-size: 18px;
}
@media (max-width: 1399px) {
  .hero-5 {
    padding-right: 40px;
  }
  .hero-5 .slick-dots {
    right: 10%;
  }
  .hero-5 .hero-shape {
    width: 40px;
  }
  .hero-slider-5 .as-hero-slide {
    padding: 190px 0 190px 0;
  }
  .hero-style5 {
    padding-left: 20px;
  }
}
@media (max-width: 1199px) {
  .hero-slider-5 .as-hero-slide {
    padding: 150px 0 150px 0;
  }
  .hero-style5 {
    max-width: 600px;
  }
  .hero-style5 .hero-text {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .hero-slider-5 .as-hero-slide {
    padding: 100px 0 100px 0;
  }
  .hero-style5 .hero-subtitle,
  .hero-style7 .hero-subtitle,
  .hero-style6 .hero-subtitle {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .hero-5 {
    padding-right: 0;
  }
  .hero-5 .hero-shape {
    display: none;
  }
  .hero-style5,
  .hero-style6,
  .hero-style7,
  .hero-style8 {
    text-align: center;
    padding-left: 0;
  }
  .hero-style5 .btn-wrap,
  .hero-style6 .btn-wrap,
  .hero-style7 .btn-wrap,
  .hero-style8 .btn-wrap {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .hero-style5 .hero-title:last-of-type,
  .hero-style6 .hero-title:last-of-type,
  .hero-style7 .hero-title:last-of-type,
  .hero-style8 .hero-title:last-of-type {
    margin-bottom: 25px;
  }
}
@media (max-width: 575px) {
  .hero-style5 .hero-subtitle,
  .hero-style6 .hero-subtitle,
  .hero-style7 .hero-subtitle,
  .hero-style8 .hero-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .hero-style5 .hero-title,
  .hero-style6 .hero-title,
  .hero-style7 .hero-title,
  .hero-style8 .hero-title {
    font-size: 30px;
  }
}
@media (max-width: 400px) {
  .hero-style5 .hero-title,
  .hero-style6 .hero-title {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}
.hero-6 {
  --main-container: 1440px;
}
.hero-6 .icon-box {
  position: absolute;
  top: 50%;
  right: 115px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 60px;
}
.hero-6 .icon-box .slick-arrow {
  --pos-x: 0;
  border-radius: 0;
  margin: 5px 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.15);
  color: var(--white-color);
}
.hero-6 .icon-box .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.hero-6 .icon-box .slick-arrow:first-child {
  margin-right: 0;
}
.hero-slider-6 .as-hero-slide {
  padding: 245px 0;
}
.hero-style6 {
  padding-left: 0;
}
.hero-style6 .hero-title {
  font-weight: bold;
}
.hero-style6 .checklist {
  margin-bottom: 43px;
}
.hero-style6 .checklist li {
  color: var(--white-color);
}
@media (max-width: 1399px) {
  .hero-6 .icon-box {
    right: 40px;
  }
}
@media (max-width: 1199px) {
  .hero-slider-6 .as-hero-slide {
    padding: 150px 0 150px 0;
  }
  .hero-style6 .hero-title {
    font-size: 46px;
  }
}
@media (max-width: 991px) {
  .hero-slider-6 .as-hero-slide {
    padding: 100px 0 100px 0;
  }
  .hero-style6 .checklist {
    margin-bottom: 34px;
  }
}
@media (max-width: 767px) {
  .hero-6 .icon-box {
    display: none;
  }
  .as-hero-wrapper .checklist ul {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .hero-style6 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .hero-slider-6 .as-hero-slide {
    padding: 90px 0;
  }
  .hero-style6 .hero-title {
    font-size: 38px;
  }
}
@media (max-width: 440px) {
  .hero-style6 .hero-title {
    font-size: 32px;
    max-width: 100%;
  }
}
@media (max-width: 375px) {
  .hero-style6 .hero-title {
    font-size: 28px;
  }
}
.hero-slider-7 .slick-dots {
  position: absolute;
  top: 50%;
  right: unset;
  left: 120px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 30px;
}
.hero-slider-7 .slick-dots li {
  display: block;
  margin: 30px 0;
}
.hero-slider-7 .slick-dots button {
  border-radius: 0 !important;
  visibility: visible;
  opacity: 1;
}
.hero-slider-7 .slick-dots button:before {
  border-radius: 0 !important;
}
.hero-style7 {
  max-width: unset;
}
.hero-style7 .hero-text {
  max-width: 700px;
}
.hero-slider-7 .as-hero-slide {
  padding: 365px 0 225px 0;
}
.hero-slider-7 .hero-style7 {
  padding-left: 560px;
}
.hero-slider-7 .hero-text {
  font-weight: 100;
  font-size: 20px;
}
@media (max-width: 1500px) {
  .hero-slider-7 .slick-dots {
    left: 35px;
  }
  .hero-slider-7 .hero-style7 {
    padding-left: 518px;
  }
}
@media (max-width: 1399px) {
  .hero-slider-7 .as-hero-slide {
    padding: 315px 0 155px 0;
  }
  .hero-slider-7 .hero-style7 {
    padding-left: 400px;
  }
}
@media (max-width: 1199px) {
  .hero-slider-7 .hero-style7 {
    padding-left: 160px;
  }
}
@media (max-width: 991px) {
  .hero-slider-7 .as-hero-slide {
    padding: 230px 0 100px 0;
  }
  .hero-slider-7 .hero-style7 {
    padding-left: 0;
  }
  .hero-slider-7 .hero-style7 .hero-text {
    font-size: 18px;
  }
  .hero-style7 .hero-text {
    max-width: 580px;
  }
}
@media (max-width: 767px) {
  .hero-style7 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .hero-slider-7 .hero-style7 .hero-text {
    font-size: 16px;
  }
}
@media (max-width: 330px) {
  .hero-style7 .hero-title {
    font-size: 26px;
  }
}
.as-hero-wrapper .slick-dots {
  position: absolute;
  top: 50%;
  background: #0364a9;
  right: 9%;
  border-radius: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 0;
}
@media (min-width: 1922px) {
  .as-hero-wrapper .slick-dots {
    right: 20%;
  }
}
.as-hero-wrapper .slick-dots li {
  display: block;
  margin: 25px 0 !important;
}
.as-hero-wrapper .slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 8px;
  height: 8px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  background-color: var(--white-color);
}
.as-hero-wrapper .slick-dots button:hover {
  border-color: var(--theme-color);
}
.as-hero-wrapper .slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 26px;
  height: 26px;
  margin: -13px 0 0 -13px;
  border: 2px solid var(--theme-color);
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}
.as-hero-wrapper .slick-dots .slick-active button {
  background-color: #fff;
}
.as-hero-wrapper .slick-dots .slick-active button::before {
  opacity: 1;
  visibility: visible;
}
.hero-style8 {
  padding: 340px 0 225px 0;
}
.hero-style8 .hero-meta {
  margin-bottom: 30px;
}
.hero-style8 .hero-meta span {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  color: var(--white-color);
  text-transform: capitalize;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
}
.hero-style8 .hero-meta span:not(:last-child) {
  padding-right: 12px;
  margin-right: 48px;
}
.hero-style8 .hero-meta span:not(:last-child):after {
  content: "";
  height: 2px;
  width: 40px;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  left: 100%;
}
.hero-style8 .hero-meta span:hover {
  color: var(--theme-color);
}
.hero-style8 .hero-title .bg-theme {
  display: inline-block;
  padding: 0 6px;
}
.hero-style8 .hero-title,
.hero-style8 .hero-text {
  color: var(--white-color);
}
.hero-style8 .hero-text {
  max-width: 720px;
}
@media (max-width: 1199px) {
  .hero-style8 {
    padding: 240px 0 130px 0;
  }
  .hero-style8 .hero-title {
    font-size: 48px;
  }
  .hero-style8 .hero-meta span {
    font-size: 20px;
  }
  .hero-style8 .hero-text {
    max-width: 500px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .hero-style8 {
    padding: 210px 0 120px 0;
  }
  .hero-style8 .hero-text {
    max-width: 540px;
  }
}
@media (max-width: 767px) {
  .hero-style8 .hero-meta span {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .hero-style8 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .hero-style8 {
    padding: 190px 0 100px 0;
  }
  .hero-style8 .hero-meta {
    margin-bottom: 20px;
  }
  .hero-style8 .hero-meta span {
    font-size: 18px;
  }
  .hero-style8 .hero-title {
    font-size: 34px;
  }
}
@media (max-width: 420px) {
  .hero-style8 .hero-title {
    font-size: 30px;
  }
}
@media (max-width: 350px) {
  .hero-style8 .hero-title {
    font-size: 26px;
  }
}
.header-layout8 .will-sticky .sticky-active {
  background-color: var(--title-color);
}


/* my */
.header-logo a img {
  max-height: 102px;
  height: 100%;
}
.footer-layout1 .as-widget-about .about-logo {
  text-align: center;
}
.footer-layout1 .about-logo a img {
    max-width: 120px;
    background: #fff;
    padding: 5px 12px;
    border-radius: 8px;
}
.footer-layout1 .as-widget-about .about-text {
  text-align: center;
}
.footer-layout1 .footer-info-title {
  text-align: center;
}
.footer-layout1 .as-social {
  text-align: center;
}
.category-list_text {
  font-size: 14px;
}
.testi-card-tab .tab-btn img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: top;
}
.as-social a {
  margin: 10px 0;
}
.counter-card img {
  border-radius: 5px;
  padding: 4px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgb(13 94 244 / 24%);
}
.Recruiters {
  margin-bottom: calc(var(--section-title-space) - 40px);
  padding-top: calc(var(--section-title-space) - 40px);
  position: relative;
  z-index: 2;
}
.Recruiters h2 {
  color: #003768;
  font-size: 48px;
  font-weight: 700;
}
section.space.padding-bottom.shape-mockup-wrap {
  padding-bottom: 0;
}
/* popup */
.popup {
  position: fixed;
  z-index: 99;
  display: flex;
  width: 100%;
  background: #0009;
  height: 100vh;
  justify-content: center;
  align-items: center;
  top: 0;
}
#model_close_button {
  position: absolute;
  top: -1px;
  right: 0;
  background: #003768;
  border: none;
  color: #fff;
  font-size: 22px;
  padding: 0px 12px 3px 14px;
}
.form_wrapper {
  background: #fff;
  width: 100%;
  max-width: 420px;
  box-sizing: border-box;
  padding: 25px;
  margin: auto;
  position: relative;
  z-index: 1;
  border-top: 5px solid #003768;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.8s 0.6s ease-out forwards;
  animation: expand 0.8s 0.6s ease-out forwards;
  opacity: 1;
}
.form_wrapper h2 {
font-size: 1.5em;
line-height: 1.5em;
margin: 0;
}
.form_wrapper .title_container {
text-align: center;
padding-bottom: 15px;
}
.form_wrapper h3 {
font-size: 1.1em;
font-weight: normal;
line-height: 1.5em;
margin: 0;
}
.form_wrapper label {
font-size: 12px;
}
.form_wrapper .row {
margin: 10px -15px;
}
.form_wrapper .row > div {
padding: 0 15px;
box-sizing: border-box;
}
.form_wrapper .col_half {
width: 50%;
float: left;
}
.form_wrapper .input_field {
position: relative;
margin-bottom: 20px;
-webkit-animation: bounce 0.6s ease-out;
animation: bounce 0.6s ease-out;
}
.form_wrapper .input_field > span {
  position: absolute;
  left: 0;
  top: 0;
  color: #003768;
  height: 100%;
  border-right: 1px solid #cccccc;
  text-align: center;
  width: 30px;
}
.form_wrapper .input_field > span > svg {
padding-top: 10px;
}
.form_wrapper .input_field > span > svg {
  padding-top: 10px;
  font-size: 22px;
}
input[type="checkbox"] ~ label:before {
  border: 1px solid #003768 !important;
}
.as-btn.Submit-button {
  width: 100%;
  padding: 11.5px 29px;
}
.form_wrapper textarea[type=text] {
  min-height: 80px !important;
  padding-left: 15px !important;
}
.form_wrapper input[type=text], .form_wrapper input[type=email], .form_wrapper input[type=number], .form_wrapper textarea[type=text], .form_wrapper input[type=password] {
width: 100%;
padding: 8px 10px 9px 35px;
height: 35px;
border: 1px solid #cccccc;
box-sizing: border-box;
outline: none;
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
font-size: 14px;
}
.form_wrapper select {
  width: 100%;
  padding: 8px 10px 9px 10px;
  height: 35px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  }
.form_wrapper input[type=text]:hover, .form_wrapper input[type=email]:hover, .form_wrapper input[type=password]:hover {
background: #fafafa;
}
.form_wrapper input[type=text]:focus, .form_wrapper input[type=email]:focus, .form_wrapper input[type=password]:focus {
-webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
-moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
border: 1px solid #003768;
background: #fafafa;
}
.form_wrapper input[type=submit] {
background: #003768;
height: 35px;
line-height: 35px;
width: 100%;
border: none;
outline: none;
cursor: pointer;
color: #fff;
font-size: 1.1em;
margin-bottom: 10px;
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.form_wrapper input[type=submit]:hover {
background: #e1a70a;
}
.form_wrapper input[type=submit]:focus {
background: #e1a70a;
}
.form_wrapper input[type=checkbox], .form_wrapper input[type=radio] {
border: 0;
/* clip: rect(0 0 0 0); */
height: 20px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
width: 20px;
z-index: 1;
left: 0;
top: 3px;
}

.form_container .row .col_half.last {
border-left: 1px solid #cccccc;
}
.slide-left {
  animation: 3s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

.as-hero-bg.background-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
[data-overlay="overlay1"]:before {
  background-color: #0000;
}
.Social-Media {
  background: #003768;
  width: 100%;
  height: 100%;
}
.col-md-10_5 {
  width: 87.333333% !important;
  padding-right: 0;
}
.col-md-2_5 {
  width: 12.666667% !important;
  padding-left: 0;
}
.mobile-view-none h2 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 12px;
  color: #fff;
  padding-top: 18px;
  margin-top: 0px;
}
.Social-Media-icon ul li a svg {
  color: #0364a9;
  font-size: 22px;
  width: 30px;
  display: flex;
  height: 30px;
  background: #fff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.Social-Media-icon ul {
  list-style: none;
  padding-left: 0;
}
.Social-Media-icon ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}
.Social-Media-icon ul li {

}
.Social-Media-icon ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff !important;
  font-family: var(--body-font);
}
.Social-Media-icon ul li a span {
  background: #0079c1;
  padding: 10px;
  border-radius: 50%;
}
.Social-Media-icon {
  height: 90%;
  display: block;
}
.border-normal{
  border: 1px solid #cccccc4a;
}
.event-card-main {
    text-align: center;
    height: 622px;
    position: relative;
    padding: 30px 0;
    overflow: hidden;
}
.event-card-main .sec-title {
  color: #fff;
  font-size: 38px;
  margin: 0;
  padding: 14px 0;
}
.event-card-main-3 {
  background-color: #4195b6;
}
.event-card-main-2 {
  background-color: var(--theme-color);
}
.event-card-main-1 {
  background-color: #d1cdd1;
  padding: 0px 0;
  overflow: hidden;
}
.event-card-main-1 .sec-title {
  color: var(--theme-color);
  font-size: 46px;
}
.event-card-main-1 h1 {
  font-size: 68px;
  color: var(--theme-color);
}
.event-card-main-1 p {
  padding: 0 15px;
  font-size: 18px;
}
.event-card-main-1 img {
  margin: 0px 0;
}
.space-normal {
  margin: 20px 0 50px;
}
.Courses-box {
  background: #cc6b34;
}
.Courses-button a {
  background: #fff;
  color: #003768;
}
.Courses-box-main {
  height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Courses-button {
  position: absolute;
  bottom: 30px;
  width: 100%;
  margin-top: 0 !important;
}
.upcoming-events-main {
    height: 415px;
}
.marquee {
  height: 100%;
  text-align: center;
}
.marquee p {
  font-size: 18px;
  color: #fff;
}
.Courses-box-main p {
  color: #fff;
  padding: 0 15px;
  font-size: 18px;
  margin-top: 20px;
}
.menu-area .row, .header-top .row, .row.padding-normal {
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  /* padding-right: calc(var(--bs-gutter-x) / 10);
  padding-left: calc(var(--bs-gutter-x) / 10); */
}
.bg-img-full {
  background-size: 100% 100% !important;
}
.padding-normal .hero-style1 {
  /* padding: 65px 0; */
}
.find-p {
  margin-bottom: 10px;
}
.find-h4 {
  margin-bottom: 0;
  font-size: 22px;
  margin-left: -20px;
}
.Happy-Students {
  color: #ffffff;
}
.footer-top {
  text-align: center;
  margin: 60px 0;
}
.Recruiters-bottom {
  list-style: none;
  padding-left: 0;
}
.hero-style1 .hero-text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.line-full .hero-text {
        -webkit-line-clamp: unset !important;
}
.Courses-box-main.slick-slide {
    display: flex;
}
.breadcumb-text-center {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.course-content p {
 display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.team-grid p {
  font-size: 16px;
  color: #000;
  margin-top: 10px;
}
.team-content-box .checklist li:last-child {
  margin-bottom: 20px;
}
.team-content-box .checklist li {
  margin-bottom: 0px;
  font-size: 16px;
}
.team-content-box {
  padding: 0 15px;
} 
.upcoming-events-main .event-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--white-color);
  z-index: 2;
  margin: 10px 20px;
}
.upcoming-events-main .event-card_title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.upcoming-events-main .event-meta p {
  color: #000;
  font-size: 12px;
}
.upcoming-events-main .event-card_img img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  width: 50px;
}
.Recruiters .counter-card {
  margin: 10px 0px;
}
.testimonials .testi-box_img img {
    border-radius: 10px;
    height: 150px;
}
.testimonials .testi-box {
  margin: 15px 0;
}
.main-content-abot-page h4 {
  text-align: center;
}
.main-content-abot-page {
  padding: 0 10%;
}
.main-page-blog-content {
    height: 150px;
    overflow: hidden;
}
.Recent-blog a {
    font-size: 18px;
}

.as-hero-wrapper .row > * {
    padding-right: calc(var(--bs-gutter-x) / 10);
    padding-left: calc(var(--bs-gutter-x) / 10);
}

.hero-slider-1 .hero-title {
    text-shadow: #fff9f9 2px 2px 12px;
}
.hero-slider-top .hero-style1 {
    margin: auto;
    text-align: center;
}
.hero-slider-1 .hero-text {
    font-size: 18px;
    font-weight: 600;
    text-shadow: #fff 2px 2px 8px;
}
.hero-slider-2 .hero-title {
        text-shadow: #000000 2px 2px 4px;
    color: #fff;
}
.hero-slider-2 .hero-text {
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    font-weight: 600;
    text-shadow: #000 2px 2px 4px;
}
.width_9 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.width_9 {
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767px) {
  .mobile-view-none {
    display: none;
}
.col-md-10_5 {
    width: 100%;
}
.event-card-main-1 {
    margin-bottom: 10px;
}
.event-card-main-2 {
    margin-bottom: 10px;
}
.event-card-main-3 {
    margin-bottom: 10px;
}
.fpaindia-mobile-1 {
    background-size: cover !important;
    background-position: right !important;
}
.fpaindia-mobile-1-content h1 {
        color: #fff !important;
        text-shadow: #000 2px 2px 4px;
}
.fpaindia-mobile-1-content p {
        color: #fff !important;
        text-shadow: #000 2px 2px 4px;
}
.fpaindia-mobile-1-content ul h4 {
        color: #fff !important;
        text-shadow: #000 2px 2px 4px;
}
.fpaindia-mobile-1-content ul {
        color: #fff;
        text-shadow: #000 2px 2px 4px;
}
.fpaindia-mobile-2{
    /* background-image: url("assets/img/bg/left-bg-img.jpg"); */
    background-size: cover !important;
    background-position: unset !important;
}
.margin-bottom {
    margin: 10px 0;
}
}
.event-card-main.event-card-main-1 img {
    height: 100%;
}
.event-card_content .blog-meta-slider {
    margin-bottom: 0px;
}
.moto-box-img {
    box-shadow: 1px 0px 28px rgb(131 139 154 / 100%);
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 10px;
}
.blog-top-social-share {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
}
.course-description  table {
    width: 100%;
}
.course-img-list img{
    max-width: 97% !important;
}

.course-description  table {
    width: fit-content;
    display: block;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
}
.aboutpage_img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: right;
}
.model_close_button_show {
    display: block !important;
}
/**/
.course-description table {
  border: 1px solid #ddd !important;
}

.course-description table{
	padding: 0;
}
.course-description table tbody tr td p {
    margin: 0;
}
.course-description table tbody tr:first-child td p {
  color:#fff;
}
.course-description table tbody tr:first-child {
        background: #003768;
            border: 2px solid #003768;
}

@media (max-width: 39.9375em) {
  .course-description table tbody tr:not(:last-child) {
    border-bottom: 2px solid #0B0B0D;
        margin-bottom: 20px;
    display: flow-root;
  }
}
.home_page_photo img {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.home_page_photo {
    height: 100%;
}
.padding-left-right {
    padding: 5px;
}
.online-form-bottom p {
    margin: 0;
    font-size: 12px;
}
.online-form-bottom {
    text-align: left;
}
/*table style*/

.rwd-table {
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0
  }
   .rwd-table tr:first-child th{
    color:#fff;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}

/*.higelite {*/
/*  animation: blink 1s linear infinite;*/
/*  border-top-left-radius: 70px !important;*/
/*}*/
/*@keyframes blink {*/
/*  0%, 100% {background-color:#003767;}*/
/*  50% {background-color: #FF0000;}*/
/*}*/
.fontsize-16 {
        font-size: 16px !important;
}
.line_2 {
 display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
}
.carousel-caption {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  right: 8% !important;
  left: auto !important;
  height: 100%;
}
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter:alpha(opacity=70);
  -moz-opacity:0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  z-index: 100;
  display: none;
  }
  .cnt223 a{
  text-decoration: none;
  }
  .popup-onload {
      width: 100%;
      margin: 0 auto;
      display: none;
      position: fixed;
      z-index: 101;
      top: 0;
      height: 100%;
      background: #000000ad;
  }
  .popup-onload-center {
      height: 100%;
      display: flex !important;
      align-items: center;
      align-content: center;
  }
  .cnt223{
  min-width: 00px;
  width: 100%;
  min-height: 150px;
  margin: auto;
  position: relative;
  z-index: 103;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px #000;
  }
  .Affilitions-slider .slick-track {
      padding: 2rem 0;
  }
  .marquee-main {
    --space: 1rem;
  
    display: grid;
    align-content: center;
    overflow: hidden;
    gap: var(--space);
    width: 100%;
    font-family: "Corben", system-ui, sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  
  .marquee {
    --duration: 60s;
    --gap: var(--space);
  
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    transform: skewX(-3deg);
  }
  
  .marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll var(--duration) linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .marquee__group {
      animation-play-state: paused;
    }
  }
  
  .marquee__group img {
    max-width: clamp(10rem, 1rem + 28vmin, 20rem);
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 1rem;
  }
  
  .marquee__group p {
    background-image: linear-gradient(
      75deg,
      hsl(240deg 70% 49%) 0%,
      hsl(253deg 70% 49%) 11%,
      hsl(267deg 70% 49%) 22%,
      hsl(280deg 71% 48%) 33%,
      hsl(293deg 71% 48%) 44%,
      hsl(307deg 71% 48%) 56%,
      hsl(320deg 71% 48%) 67%,
      hsl(333deg 72% 48%) 78%,
      hsl(347deg 72% 48%) 89%,
      hsl(0deg 73% 47%) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .marquee--borders {
    border-block: 3px solid dodgerblue;
    padding-block: 0.75rem;
  }
  
  .marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: calc(var(--duration) / -2);
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  .Affilitions_home_page_style {
    background: linear-gradient(180deg, rgba(15,34,57,1) 50%, rgba(255,255,255,1) 50%);
    padding-top: 60px;
  }
  .slick-slide > div {
    margin: 0 15px;
}
.recruiter_img img {
  width: 100%;
}

.Testimonial_bg_img {
  background-image: url(./assets/img/testi_bg_1.jpg);
}
.Testimonial_bg_img .slick-arrow.slick-prev {
  display: none !important;
}
.Faculty_page_bg {
  background-image: url(./assets/img/55.jpg);
  background-position: bottom;
}
.about_page_bg {
  background-image: url(./assets/img/11.jpg);
  background-position: center;
}
.Testimonialspage_bg {
  background-image: url(./assets/img/58.jpg);
  background-position: center;
}
.Event_page_bg {
  background-image: url(./assets/img/13.jpg);
  background-position: center;
}
.checklist p strong svg {
  font-size: 22px;
  margin-right: 10px;
}
.gallery_page_bg {
  background-image: url(./assets/img/38.jpg);
  background-position: top;
}


.contact-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  border-radius: 10px;
  border: 1px solid #ECF1F9;
  background: var(--white-color);
  box-shadow: 0px 9px 14px 0px #FBFBFB;
  padding: 30px
}

.contact-feature:not(:last-child) {
  margin-bottom: 24px
}

.contact-feature .contact-feature-icon {
  height: 70px;
  width: 70px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  border-radius: 10px;
  line-height: 70px;
  background: rgba(13,94,244,0.07);
  font-size: 30px;
  text-align: center;
  color: var(--theme-color);
  -webkit-transition: 0.4s;
  transition: 0.4s
}

.contact-feature .media-body {
  margin-bottom: -0.4em
}

.contact-feature .contact-feature_label {
  font-size: 20px;
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  margin-top: -0.4em;
  margin-bottom: 5px
}

.contact-feature .contact-feature_link {
  font-family: var(--title-font);
  color: #4D5765;
  line-height: normal;
  display: block
}

.contact-feature .contact-feature_link span {
  color: var(--title-color)
}

.contact-feature:hover .contact-feature-icon {
  background: var(--theme-color);
  color: var(--white-color)
}

@media (max-width: 375px) {
  .contact-feature {
      display:block
  }

  .contact-feature .contact-feature-icon {
      margin-bottom: 20px
  }
}
.mt-60 {
  margin-top: 60px;
}
.title-area h2 {
  font-size: 36px;
  font-weight: 700;
}
.contact-form-wrap.background-image {
  background-image: url(./assets/img/contact_bg_1.png);
  background-position: center;
}
.event-card_img.bg-mask {
  background-image: url(./assets/img/event_img-shape.png);
}
.bg-mask {
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
 .breadcumb-shape {
  background-image: url(./assets/img/breadcumb_shape_1_1.png);
}
.Recruiters_page_bg {
  background-image: url(./assets/img/2.jpg);
}
.course_page_bg {
  background-image: url(./assets/img/Bitmap.jpg);
}
.content_page_bg {
  background-image: url(./assets/img/bg-top-main-1.jpg);
}
.blog_page_bg {
  background-image: url(./assets/img/44.jpg);
}
 .breadcumb-shape2 {
  right: 30px; bottom: 30px;
}
 .breadcumb-shape3 {
  bottom: 80px; left: 50px;
}
.breadcumb-wrapper .breadcumb-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-position: right;
}
.breadcumb-content.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.course-meta.style2 {
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
}
.course-meta.style2 a:not(:last-child), .course-meta.style2 span:not(:last-child) {
  margin-right: 30px;
}
.course-meta.style2 a, .course-meta.style2 span {
  font-size: 16px;
}
.course-meta.style2 a:not(:last-child), .course-meta.style2 span:not(:last-child) {
  margin-right: 30px;
}
.course-single-meta {
  padding: 0;
  margin: 0;
}
.course-single-meta li.course-single-meta-author {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.course-single-meta li:not(:last-child) {
  margin-right: 50px;
  padding-right: 50px;
  position: relative;
  border-right: 1px solid #D0DBE9;
}
.course-single-meta li.course-single-meta-author img {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 40px;
  border-radius: 50%;
}
.course-single-meta li .meta-title {
  display: block;
  font-size: 14px;
  font-family: var(--body-font);
}
.course-single-meta li a {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--title-font);
  color: var(--title-color);
}
.course-single-meta li:not(:last-child) {
  margin-right: 50px;
  padding-right: 50px;
  position: relative;
  border-right: 1px solid #D0DBE9;
}
.course-single-meta li {
  list-style: none;
  display: inline-block;
}
.sidebar-area .tutor-widget-title:before, .sidebar-area .widget_title:before {
  content: '';
  height: 3px;
  width: 50px;
  background-color: var(--theme-color);
  position: absolute;
  bottom: -3px;
  left: 0;
  z-index: 1;
  -webkit-animation: lineMove 10s linear infinite;
  animation: lineMove 10s linear infinite;
}

@-webkit-keyframes lineMove {
  0% {
      left: 0px
  }

  50% {
      left: calc(100% - 50px)
  }

  100% {
      left: 0px
  }
}

@keyframes lineMove {
  0% {
      left: 0px
  }

  50% {
      left: calc(100% - 50px)
  }

  100% {
      left: 0px
  }
}
.boxshow_button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.course-meta.style2 a svg, .course-meta.style2 span svg {
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  background: #DDE8FB;
  margin-right: 8px;
  font-size: 12px;
  color: var(--theme-color);
  padding: 4px;
}
.course-meta.style2 {
  margin-bottom: 20px;
}
.blog-img img {
  width: 100%;
  max-height: 400px;
  min-height: 400px;
  object-fit: contain;
  
}
.event-card_bottom .as-btn.nav-link:hover {
  color: #fff !important;
}
.footer-layout1 ul li a {
  color: #b3c1d3 !important;
}
.Applyonline_bg {
  background-image: url(./assets//img/12.jpg);
  background-position: center;
}
.social_button_share {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 2;
}
.Cookie_page_bg {
  background-image: url(./assets//img/cookie-policy.jpg);
  background-position: center;
}
.Privacypolicy_page_bg {
  background-image: url(./assets//img/privacy-policy.jpg);
  background-position: center;
}
.Termsandconditions_page_bg {
  background-image: url(./assets//img/teram.jpg);
  background-position: center;
}
.course-description th {
  color: #fff !important;
}
.Testimonial_center_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.fa-book:before {
  content: "";
  /* background-image: url(); */
}
.course_page_bg.breadcumb-wrapper.background-image.shape-mockup-wrap {
    height: 600px;
}